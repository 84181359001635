@import url("https://use.typekit.net/wbl3ahj.css");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500;700&display=swap");
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video { margin: 0; padding: 0; box-sizing: border-box; word-break: keep-all; word-wrap: break-word; }

a { box-sizing: border-box; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section { display: block; }

html { font-size: 1.07143vw; }

@media (min-width: 1680px) { html { font-size: 18px; } }

@media (max-width: 1024px) { html { font-size: 1.5625vw; } }

@media (max-width: 768px) { html { font-size: 3.88889vw; } }

body { letter-spacing: -0.01em; line-height: 1.6; color: #000; font-family: "Noto Sans KR", Helvetica, "malgun gothic", "Apple SD Gothic Neo", "Microsoft NeoGothic", "Droid sans", sans-serif; -webkit-text-size-adjust: none; -moz-text-size-adjust: none; -ms-text-size-adjust: none; -webkit-font-smooting: antialiased; -moz-font-smoothing: grayscale; text-size-adjust: none; }

nav ul { list-style: none; }

blockquote, q { quotes: none; }

blockquote:before, blockquote:after, q:before, q:after { content: ''; content: none; }

/* change colours to suit your needs */
ins { background-color: #ff9; color: #000; text-decoration: none; }

/* change colours to suit your needs */
mark { background-color: #ff9; color: #000; font-style: italic; font-weight: bold; }

del { text-decoration: line-through; }

abbr[title], dfn[title] { border-bottom: 1px dotted; cursor: help; }

table { border-collapse: collapse; border: 0 none; }

/* change border colour to suit your needs */
hr { display: block; height: 1px; border: 0; border-top: 1px solid #cccccc; margin: 1em 0; padding: 0; }

input, select, textarea { vertical-align: middle; box-sizing: border-box; border-radius: 0; font-family: "Noto Sans KR", Helvetica, "malgun gothic", "Apple SD Gothic Neo", "Microsoft NeoGothic", "Droid sans", sans-serif; font-size: 1rem; }

input { margin: 0; }

img, fieldset { border: 0; }

ul, ol, li { list-style-type: none; }

li img { vertical-align: top; }

button { box-sizing: border-box; padding: 0; border: 0; background: transparent; cursor: pointer; font-family: "Noto Sans KR", Helvetica, "malgun gothic", "Apple SD Gothic Neo", "Microsoft NeoGothic", "Droid sans", sans-serif; font-size: 1rem; }

a { text-decoration: none; }

img { vertical-align: middle; font-size: 0; }

/* Slider */
.slick-slider { position: relative; display: block; box-sizing: border-box; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; -webkit-touch-callout: none; -khtml-user-select: none; -ms-touch-action: pan-y; touch-action: pan-y; -webkit-tap-highlight-color: transparent; }

.slick-list { position: relative; display: block; overflow: hidden; margin: 0; padding: 0; }

.slick-list:focus { outline: none; }

.slick-list.dragging { cursor: pointer; cursor: hand; }

.slick-slider .slick-track, .slick-slider .slick-list { /*-webkit-transform: translate3d(0, 0, 0);*/ /*-moz-transform: translate3d(0, 0, 0);*/ /*-ms-transform: translate3d(0, 0, 0);*/ /*-o-transform: translate3d(0, 0, 0);*/ /*transform: translate3d(0, 0, 0);*/ }

.slick-track { position: relative; top: 0; left: 0; display: block; margin-left: auto; margin-right: auto; }

.slick-track:before, .slick-track:after { /*display: table;*/ content: ''; }

.slick-track:after { clear: both; }

.slick-loading .slick-track { visibility: hidden; }

.slick-slide { display: none; float: left; height: 100%; min-height: 1px; }

[dir='rtl'] .slick-slide { float: right; }

.slick-slide img { display: block; }

.slick-slide.slick-loading img { display: none; }

.slick-slide.dragging img { pointer-events: none; }

.slick-initialized .slick-slide { display: block; }

.slick-loading .slick-slide { visibility: hidden; }

.slick-vertical .slick-slide { display: block; height: auto; border: 1px solid transparent; }

.slick-arrow.slick-hidden { display: none; }

.blind { overflow: hidden; position: absolute; top: 0; left: 0; width: 1px; height: 1px; font-size: 0; line-height: 0; }

body.layer-opens { overflow: hidden; }

body.layer-opens #wrapper { overflow: scroll; }

body.nav-opens { overflow: hidden; }

.input-select { -moz-appearance: none; -webkit-appearance: none; appearance: none; }

.input-checkbox { position: relative; }

.input-checkbox input { width: 1px; height: 1px; overflow: hidden; opacity: 0; position: absolute; left: -999px; top: 0; }

.js-select { display: none; }

.select-box { position: relative; width: 100%; height: 100%; font-size: 1rem; z-index: 100; }

.select-box .current { position: relative; color: #000; background-color: #fff; border: 1px solid #888; width: 100%; height: 100%; text-align: left; font-weight: bold; border-radius: 0.95238vw; padding: 0vw 2.5vw 0vw 1.19048vw; z-index: 5; }

@media (min-width: 1680px) { .select-box .current { border-radius: 16px; } }

@media (min-width: 1680px) { .select-box .current { padding: 0px 42px 0px 20px; } }

.select-box .current:after { content: ""; width: 1.42857vw; height: 1.42857vw; right: 0.89286vw; position: absolute; top: 50%; transform: translateY(-50%); transition: transform .2s ease; }

@media (min-width: 1680px) { .select-box .current:after { width: 24px; } }

@media (min-width: 1680px) { .select-box .current:after { height: 24px; } }

@media (min-width: 1680px) { .select-box .current:after { right: 15px; } }

.select-box .option-dropdown { position: absolute; left: 0; top: calc(100% - 1px); background-color: #fff; width: 0; overflow: hidden; transform: translateY(-30px); transition: transform .3s ease; border-radius: 0vw 0vw 0.95238vw 0.95238vw; }

@media (min-width: 1680px) { .select-box .option-dropdown { border-radius: 0px 0px 16px 16px; } }

.select-box .option-dropdown .item { display: block; width: 100%; color: #000; font-weight: bold; text-align: left; padding: 0.59524vw 1.19048vw; }

@media (min-width: 1680px) { .select-box .option-dropdown .item { padding: 10px 20px; } }

.select-box .option-dropdown .item.selected { background-color: #f3f3f3; }

.select-box .option-dropdown .item:last-child { border-radius: 0vw 0vw 0.95238vw 0.95238vw; }

@media (min-width: 1680px) { .select-box .option-dropdown .item:last-child { border-radius: 0px 0px 16px 16px; } }

.select-box.is-active .current { border-radius: 0.95238vw 0.95238vw 0vw 0vw; }

@media (min-width: 1680px) { .select-box.is-active .current { border-radius: 16px 16px 0px 0px; } }

.select-box.is-active .current:after { transform: translateY(-50%) scaleY(-1); }

.select-box.is-active .option-dropdown { width: 100%; border: 1px solid #888; overflow: inherit; transform: translateY(0); }

@media (max-width: 768px) { .select-box { font-size: 1.14286rem; }
  .select-box .current { border-radius: 3.33333vw; padding: 0vw 11.66667vw 0vw 8.33333vw; } }

@media (max-width: 768px) and (max-width: 360px) { .select-box .current { padding: 0vw 11.66667vw 0vw 8.33333vw; } }

@media (max-width: 768px) { .select-box .current:after { content: ""; width: 5.55556vw; height: 5.55556vw; right: 3.33333vw; }
  .select-box .option-dropdown { border-radius: 0vw 0vw 3.33333vw 3.33333vw; } }

@media (max-width: 768px) and (max-width: 360px) { .select-box .option-dropdown { border-radius: 0vw 0vw 3.33333vw 3.33333vw; } }

@media (max-width: 768px) { .select-box .option-dropdown .item { padding: 2.77778vw 4.16667vw; } }

@media (max-width: 768px) and (max-width: 360px) { .select-box .option-dropdown .item { padding: 2.77778vw 4.16667vw; } }

@media (max-width: 768px) { .select-box .option-dropdown .item:last-child { border-radius: 0vw 0vw 3.33333vw 3.33333vw; } }

@media (max-width: 768px) and (max-width: 360px) { .select-box .option-dropdown .item:last-child { border-radius: 0vw 0vw 3.33333vw 3.33333vw; } }

@media (max-width: 768px) { .select-box.is-active .current { border-radius: 3.33333vw 3.33333vw 0vw 0vw; } }

@media (max-width: 768px) and (max-width: 360px) { .select-box.is-active .current { border-radius: 3.33333vw 3.33333vw 0vw 0vw; } }

.slider .slick-arrow { width: 3.57143vw; height: 3.57143vw; border: 1px solid #000; border-radius: 50%; background: url(../images/button_arrow_white.svg) no-repeat center/100% auto; font-size: 0; transition: background-color .2s ease; }

@media (min-width: 1680px) { .slider .slick-arrow { width: 60px; } }

@media (min-width: 1680px) { .slider .slick-arrow { height: 60px; } }

.slider .slick-arrow.slick-prev { transform: scaleX(-1); }

.slider .slick-arrow:hover { background-color: #000; background-image: url(../images/button_arrow_black.svg); }

@media (max-width: 1024px) { .slider .slick-arrow { width: 3.90625vw; height: 3.90625vw; } }

@media (max-width: 768px) { .slider .slick-arrow { width: 11.11111vw; height: 11.11111vw; } }

.slider .slick-dots { width: 100%; display: flex; align-items: center; justify-content: center; text-align: center; }

.slider .slick-dots li { margin: 0vw 0.47619vw; display: inline-block; }

@media (min-width: 1680px) { .slider .slick-dots li { margin: 0px 8px; } }

.slider .slick-dots button { width: 0.71429vw; height: 0.71429vw; border: 1px solid #444; border-radius: 50%; font-size: 0; display: block; }

@media (min-width: 1680px) { .slider .slick-dots button { width: 12px; } }

@media (min-width: 1680px) { .slider .slick-dots button { height: 12px; } }

.slider .slick-dots .slick-active button { width: 0.83333vw; height: 0.83333vw; background-color: #444; }

@media (min-width: 1680px) { .slider .slick-dots .slick-active button { width: 14px; } }

@media (min-width: 1680px) { .slider .slick-dots .slick-active button { height: 14px; } }

@media (max-width: 768px) { .slider .slick-dots li { margin: 0vw 1.66667vw; } }

@media (max-width: 768px) and (max-width: 360px) { .slider .slick-dots li { margin: 0vw 1.66667vw; } }

@media (max-width: 768px) { .slider .slick-dots button { width: 2.22222vw; height: 2.22222vw; }
  .slider .slick-dots .slick-active button { width: 2.22222vw; height: 2.22222vw; } }

.main__inner { padding-left: 7.14286vw; padding-right: 7.14286vw; margin: 0 auto; max-width: 114.28571vw; }

@media (min-width: 1680px) { .main__inner { padding-left: 120px; } }

@media (min-width: 1680px) { .main__inner { padding-right: 120px; } }

@media (min-width: 1680px) { .main__inner { max-width: 1920px; } }

@media (max-width: 1024px) { .main__inner { padding-left: 5.85938vw; padding-right: 5.85938vw; max-width: 100%; } }

@media (max-width: 768px) { .main__inner { padding-left: 5.55556vw; padding-right: 5.55556vw; max-width: 100%; } }

.group { display: flex; justify-content: center; }

.button.primary { display: inline-block; padding: 0.89286vw 3.09524vw 0.77381vw 1.19048vw; border-radius: 1.54762vw; line-height: 1; font-size: 1.22222rem; font-family: oscine, Helvetica, "malgun gothic", "Apple SD Gothic Neo", "Microsoft NeoGothic", "Droid sans", arial, sans-serif; font-weight: bold; position: relative; border: 1px solid #000; color: #000; background-color: #fff; transition: background-color .2s ease, color .2s ease; }

@media (min-width: 1680px) { .button.primary { padding: 15px 52px 13px 20px; } }

@media (min-width: 1680px) { .button.primary { border-radius: 26px; } }

.button.primary:after { content: ''; display: block; position: absolute; top: 50%; right: 1.19048vw; transform: translateY(-50%); background: url(../images/arrow_black.svg) no-repeat center/100% auto; width: 1.36905vw; height: 1.30952vw; }

@media (min-width: 1680px) { .button.primary:after { right: 20px; } }

@media (min-width: 1680px) { .button.primary:after { width: 23px; } }

@media (min-width: 1680px) { .button.primary:after { height: 22px; } }

.button.primary:hover { background: #000; color: #fff; }

.button.primary:hover:after { background: url(../images/arrow_white.svg) no-repeat 100%; }

.button.primary.disabled { border: 1px solid #999; color: #999; cursor: default; }

.button.primary.disabled:after { background: url(../images/arrow_gray.svg) no-repeat 100%; }

.button.primary.disabled:hover { background: #fff; color: #999; }

.button.primary.disabled:hover:after { background: url(../images/arrow_gray.svg) no-repeat 100%; }

.button.primary.download:after { background: url(../images/arrow_down_black.svg) no-repeat center/92%; width: 1.30952vw; height: 1.42857vw; }

@media (min-width: 1680px) { .button.primary.download:after { width: 22px; } }

@media (min-width: 1680px) { .button.primary.download:after { height: 24px; } }

@media (max-width: 1024px) { .button.primary.download:after { background-size: contain; width: 1.95313vw; height: 1.66016vw; } }

@media (max-width: 768px) { .button.primary.download:after { width: 4.72222vw; height: 3.88889vw; } }

.button.primary.download:hover:after { background: url(../images/arrow_down_white.svg) no-repeat center/92%; }

@media (max-width: 1024px) { .button.primary.download:hover:after { background-size: contain; width: 1.95313vw; height: 1.66016vw; } }

@media (max-width: 768px) { .button.primary.download:hover:after { width: 4.72222vw; height: 3.88889vw; } }

.button.primary.website { padding: 0.71429vw 2.44048vw 0.71429vw 1.0119vw; font-size: 1rem; }

@media (min-width: 1680px) { .button.primary.website { padding: 12px 41px 12px 17px; } }

@media (max-width: 1024px) { .button.primary.website { padding: 1.17188vw 3.90625vw 1.17188vw 1.75781vw; font-size: 0.875rem; } }

@media (max-width: 768px) { .button.primary.website { padding: 3.33333vw 11.11111vw 3.33333vw 5vw; font-size: 1rem; } }

@media (max-width: 768px) and (max-width: 360px) { .button.primary.website { padding: 3.33333vw 11.11111vw 3.33333vw 5vw; } }

.button.primary.website:after { background: url(../images/arrow_website.svg) no-repeat center/100% auto; width: 1.19048vw; height: 1.19048vw; }

@media (min-width: 1680px) { .button.primary.website:after { width: 20px; } }

@media (min-width: 1680px) { .button.primary.website:after { height: 20px; } }

@media (max-width: 1024px) { .button.primary.website:after { background-size: contain; width: 1.75781vw; height: 1.75781vw; } }

@media (max-width: 768px) { .button.primary.website:after { background-size: contain; width: 5vw; height: 5vw; } }

.button.primary.website:hover:after { background: url(../images/arrow_website_white.svg) no-repeat center/100% auto; }

@media (max-width: 1024px) { .button.primary.website:hover:after { background-size: contain; width: 1.75781vw; height: 1.75781vw; } }

@media (max-width: 768px) { .button.primary.website:hover:after { background-size: contain; width: 5vw; height: 5vw; } }

.button.circle { display: block; background: url(../images/button_arrow_white.svg) no-repeat center/100% auto; width: 3.57143vw; height: 3.57143vw; border: 1px solid #000; border-radius: 50%; font-size: 0; transition: background-color .2s ease; }

@media (min-width: 1680px) { .button.circle { width: 60px; } }

@media (min-width: 1680px) { .button.circle { height: 60px; } }

.button.circle:hover { background-color: #000; background-image: url(../images/button_arrow_black.svg); }

.button.circle.disabled { background-image: url(../images/button_arrow_gray.svg); cursor: default; }

.button.circle.disabled:hover { background-image: url(../images/button_arrow_gray.svg); }

@media (max-width: 1024px) { .button.primary { padding: 1.46484vw 4.49219vw 1.26953vw 1.95313vw; font-size: 1.125rem; border-radius: 2.53906vw; }
  .button.primary:after { right: 1.95313vw; width: 1.5625vw; height: 1.26953vw; }
  .button.circle { width: 3.90625vw; height: 3.90625vw; } }

@media (max-width: 768px) { .button.primary { padding: 3.33333vw 11.11111vw 2.77778vw 5vw; font-size: 1rem; border-radius: 7.22222vw; } }

@media (max-width: 768px) and (max-width: 360px) { .button.primary { padding: 3.33333vw 11.11111vw 2.77778vw 5vw; } }

@media (max-width: 768px) { .button.primary:after { right: 5vw; width: 3.88889vw; height: 3.88889vw; }
  .button.circle { width: 11.11111vw; height: 11.11111vw; } }

.page_move { padding-left: 1.19048vw; position: relative; color: #000; font-size: 1.11111rem; font-family: oscine, Helvetica, "malgun gothic", "Apple SD Gothic Neo", "Microsoft NeoGothic", "Droid sans", arial, sans-serif; font-weight: bold; display: block; }

@media (min-width: 1680px) { .page_move { padding-left: 20px; } }

.page_move:before { content: ''; position: absolute; left: 0; top: 50%; transform: translateY(-50%); background: url("../images/arrow_page_move.svg") no-repeat center/100% auto; width: 0.71429vw; height: 0.95238vw; }

@media (min-width: 1680px) { .page_move:before { width: 12px; } }

@media (min-width: 1680px) { .page_move:before { height: 16px; } }

@media (max-width: 1024px) { .page_move { padding-left: 1.95313vw; font-size: 1.25rem; }
  .page_move:before { width: 1.17188vw; height: 1.5625vw; } }

@media (max-width: 768px) { .page_move { padding-left: 5.55556vw; font-size: 1.14286rem; }
  .page_move:before { width: 3.33333vw; height: 4.44444vw; } }

@media (max-width: 768px) { .js-images { opacity: 0; }
  .js-images.shown { opacity: 1; } }

.btn_talk { position: fixed; right: 1.19048vw; bottom: 1.19048vw; z-index: 10001; cursor: pointer; }

@media (min-width: 1680px) { .btn_talk { right: 20px; } }

@media (min-width: 1680px) { .btn_talk { bottom: 20px; } }

.btn_talk:after { content: ""; position: relative; display: block; width: 3.57143vw; height: 3.57143vw; background: #25ADFA url(../images/viewing-talk.png) no-repeat center/100% auto; border: 1px solid #000; border-radius: 50%; z-index: 10; transition: background-color .3s ease; }

@media (min-width: 1680px) { .btn_talk:after { width: 60px; } }

@media (min-width: 1680px) { .btn_talk:after { height: 60px; } }

.btn_talk .text { position: absolute; top: 0; right: 1.78571vw; height: 100%; background-color: #fff; font-size: 0.77778rem; border-top-left-radius: 1.78571vw; border-bottom-left-radius: 1.78571vw; border: 1px solid #000; font-family: "Noto Sans KR", Helvetica, "malgun gothic", "Apple SD Gothic Neo", "Microsoft NeoGothic", "Droid sans", sans-serif; font-weight: normal; color: #000; width: 0; overflow: hidden; transition: width .3s ease; }

@media (min-width: 1680px) { .btn_talk .text { right: 30px; } }

@media (min-width: 1680px) { .btn_talk .text { border-top-left-radius: 30px; } }

@media (min-width: 1680px) { .btn_talk .text { border-bottom-left-radius: 30px; } }

.btn_talk .text .link { display: flex; align-items: center; height: 100%; color: #000; width: 9.88095vw; padding: 0vw 2.38095vw 0vw 1.19048vw; }

@media (min-width: 1680px) { .btn_talk .text .link { width: 166px; } }

@media (min-width: 1680px) { .btn_talk .text .link { padding: 0px 40px 0px 20px; } }

.btn_talk .text .link:hover { text-decoration: underline; }

.btn_talk:hover:after { background-color: #0088D5; }

.btn_talk.clicked .text { width: 9.88095vw; }

@media (min-width: 1680px) { .btn_talk.clicked .text { width: 166px; } }

@media (max-width: 1024px) { .btn_talk.clicked .text { width: 13.67188vw; } }

@media (max-width: 768px) { .btn_talk.clicked .text { width: 69.44444vw; } }

@media (max-width: 1024px) { .btn_talk { right: 1.95313vw; bottom: 1.95313vw; }
  .btn_talk:after { width: 4.88281vw; height: 4.88281vw; }
  .btn_talk .text { right: 2.44141vw; font-size: 0.75rem; border-top-left-radius: 2.44141vw; border-bottom-left-radius: 2.44141vw; }
  .btn_talk .text .link { width: 13.67188vw; padding: 0vw 2.92969vw 0vw 1.95313vw; } }

@media (max-width: 768px) { .btn_talk { right: 4.16667vw; bottom: 4.16667vw; }
  .btn_talk:after { width: 11.11111vw; height: 11.11111vw; }
  .btn_talk .text { right: 5.55556vw; font-size: 1rem; border-top-left-radius: 5.55556vw; border-bottom-left-radius: 5.55556vw; }
  .btn_talk .text .link { width: 69.44444vw; padding: 0vw 5.55556vw; } }

@media (max-width: 768px) and (max-width: 360px) { .btn_talk .text .link { padding: 0vw 5.55556vw; } }

header { position: fixed; left: 0; top: 0; width: 100%; z-index: 10010; font-family: oscine, Helvetica, "malgun gothic", "Apple SD Gothic Neo", "Microsoft NeoGothic", "Droid sans", arial, sans-serif; font-weight: bold; height: 4.16667vw; background-color: #fff; border-bottom: 1px solid #000; }

@media (min-width: 1680px) { header { height: 70px; } }

header .main__inner { height: 100%; display: flex; align-items: center; }

header .logos { width: 5.35714vw; height: 2.61905vw; background: url(../images/betree_logos.svg) no-repeat center/100% auto; }

@media (min-width: 1680px) { header .logos { width: 90px; } }

@media (min-width: 1680px) { header .logos { height: 44px; } }

header .logos a { display: block; width: 100%; height: 100%; }

header .nav__wrap { display: flex; align-items: center; margin-left: auto; }

@media (min-width: 769px) { header .nav__wrap .nav { order: 1; }
  header .nav__wrap .locale { order: 2; } }

header .nav { padding-right: 2.38095vw; }

@media (min-width: 1680px) { header .nav { padding-right: 40px; } }

header .nav ul { display: flex; }

header .nav li { margin-left: 1.78571vw; }

@media (min-width: 1680px) { header .nav li { margin-left: 30px; } }

header .nav li a { display: block; color: #000; padding: 0.59524vw 1.4881vw 0.2381vw; font-size: 1.22222rem; border: 1px solid transparent; border-radius: 50%; }

@media (min-width: 1680px) { header .nav li a { padding: 10px 25px 4px; } }

header .nav li a:hover { border-color: #000; transition: border-color .3s ease; }

header .nav li .change_room { position: relative; padding-right: 2.61905vw; }

@media (min-width: 1680px) { header .nav li .change_room { padding-right: 44px; } }

header .nav li .change_room:after { position: absolute; content: ''; top: 50%; transform: translateY(-50%); right: 1.19048vw; background: url(../images/arrow_website.svg) no-repeat center/100% auto; width: 1.19048vw; height: 1.19048vw; }

@media (min-width: 1680px) { header .nav li .change_room:after { right: 20px; } }

@media (min-width: 1680px) { header .nav li .change_room:after { width: 20px; } }

@media (min-width: 1680px) { header .nav li .change_room:after { height: 20px; } }

header .nav.stop_transitions li a { transition: none !important; }

header .locale { position: relative; width: 4.52381vw; }

@media (min-width: 1680px) { header .locale { width: 76px; } }

header .locale button { width: 100%; text-align: left; padding-left: 0.83333vw; font-family: oscine, Helvetica, "malgun gothic", "Apple SD Gothic Neo", "Microsoft NeoGothic", "Droid sans", arial, sans-serif; font-weight: bold; font-size: 1.22222rem; }

@media (min-width: 1680px) { header .locale button { padding-left: 14px; } }

header .locale .current { position: relative; padding-right: 1.78571vw; padding-top: 0.29762vw; height: 2.38095vw; }

@media (min-width: 1680px) { header .locale .current { padding-right: 30px; } }

@media (min-width: 1680px) { header .locale .current { padding-top: 5px; } }

@media (min-width: 1680px) { header .locale .current { height: 40px; } }

header .locale .current:after { content: ""; position: absolute; top: 50%; right: 0.95238vw; width: 0.59524vw; height: 0.35714vw; margin-top: -0.17857vw; background: url(../images/select_arrow.svg) no-repeat center/100% auto; }

@media (min-width: 1680px) { header .locale .current:after { right: 16px; } }

@media (min-width: 1680px) { header .locale .current:after { width: 10px; } }

@media (min-width: 1680px) { header .locale .current:after { height: 6px; } }

@media (min-width: 1680px) { header .locale .current:after { margin-top: -3px; } }

header .locale .options { display: none; position: absolute; left: 0; top: 100%; width: 100%; border-radius: 3px; border: 1px solid #000; background-color: #fff; }

header .locale .options .selected { display: none; }

header .locale .options button { padding: 0.95238vw 0.83333vw 0.83333vw; display: flex; align-items: center; }

@media (min-width: 1680px) { header .locale .options button { padding: 16px 14px 14px; } }

header .locale.is-active .current:after { transform: scaleY(-1); }

header .locale.is-active .options { display: block; }

header .copyright, header .menu_btn { display: none; }

header.is-scroll { position: fixed; height: 4.16667vw; }

@media (min-width: 1680px) { header.is-scroll { height: 70px; } }

header.is-scroll .logos { width: 5.35714vw; height: 2.61905vw; }

@media (min-width: 1680px) { header.is-scroll .logos { width: 90px; } }

@media (min-width: 1680px) { header.is-scroll .logos { height: 44px; } }

header.is-scroll .locale .options { background-color: #fff; }

@media (max-width: 1024px) { header { height: 8.78906vw; }
  header .logos { width: 9.17969vw; height: 4.49219vw; }
  header .nav { padding-right: 1.95313vw; }
  header .nav li { margin-left: 0.97656vw; }
  header .nav li a { padding: 0.97656vw 2.44141vw 0.39063vw; font-size: 1.125rem; }
  header .nav li .change_room { padding-right: 3.125vw; }
  header .nav li .change_room:after { width: 1.75781vw; height: 1.75781vw; }
  header .locale { width: 5.07813vw; }
  header .locale button { padding-left: 0.97656vw; font-size: 1.125rem; }
  header .locale .current { padding-right: 1.5625vw; padding-top: 0.48828vw; height: 3.125vw; }
  header .locale .current:after { right: 0.78125vw; width: 0.78125vw; height: 0.39063vw; margin-top: -0.19531vw; }
  header .locale .options button { padding: 1.17188vw 0.78125vw 0.78125vw; }
  header.is-scroll { height: 6.83594vw; }
  header.is-scroll .logos { width: 8.78906vw; height: 4.29688vw; } }

@media (max-width: 768px) { header { height: 22.22222vw; }
  header .logos { width: 22.22222vw; height: 11.11111vw; }
  header .menu_btn { position: relative; display: block; width: 11.11111vw; height: 11.11111vw; margin-left: auto; }
  header .menu_btn:before, header .menu_btn:after { content: ""; width: 8.33333vw; height: 2px; background-color: #000; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); transition: transform .2s ease; }
  header .menu_btn:before { margin-top: -1.11111vw; }
  header .menu_btn:after { margin-top: 1.11111vw; }
  header .fancy_room { position: absolute; left: 0; top: 22.22222vw; width: 100%; height: 0; overflow: hidden; }
  header .fancy_room:before { content: ""; width: 100%; height: 0; background-color: #FAF6F3; position: absolute; left: 0; top: 0; }
  header .fancy_room .locale, header .fancy_room .nav, header .fancy_room .copyright { opacity: 0; transform: translateX(-30px); }
  header .fancy_room .nav { position: relative; padding-right: 0; min-height: calc(100% - 8.33333vw - 8.88889vw); }
  header .fancy_room .nav ul { display: block; }
  header .fancy_room .nav li { padding-top: 16.66667vw; }
  header .fancy_room .nav li:last-child { padding-bottom: 16.66667vw; }
  header .fancy_room .nav li a { font-size: 2.28571rem; padding: 0; border: 0; display: inline-block; }
  header .fancy_room .nav li .change_room { padding-right: 8.88889vw; }
  header .fancy_room .nav li .change_room:after { width: 7.22222vw; height: 7.22222vw; }
  header .fancy_room .locale { width: 16.66667vw; z-index: 10; }
  header .fancy_room .locale button { padding-left: 2.77778vw; font-size: 1.28571rem; }
  header .fancy_room .locale .current { padding-right: 4.44444vw; padding-top: 1.38889vw; height: 8.88889vw; }
  header .fancy_room .locale .current:after { right: 2.22222vw; width: 2.77778vw; height: 1.66667vw; margin-top: -0.83333vw; }
  header .fancy_room .locale .options button { padding: 3.33333vw 2.22222vw 2.22222vw; } }

@media (max-width: 768px) and (max-width: 360px) { header .fancy_room .locale .options button { padding: 3.33333vw 2.22222vw 2.22222vw; } }

@media (max-width: 768px) { header .fancy_room .copyright { position: relative; display: block; font-size: 1rem; }
  header.is-scroll { height: 16.66667vw; }
  header.is-scroll .logos { width: 16.66667vw; height: 8.33333vw; } }

@media (max-width: 768px) { .nav-opens header { background-color: #FAF6F3; border-bottom: none; }
  .nav-opens header .menu_btn:before { transform: translate(-50%, -50%) rotate(-45deg); margin: 0; }
  .nav-opens header .menu_btn:after { transform: translate(-50%, -50%) rotate(45deg); margin: 0; }
  .nav-opens header .nav__wrap { display: block; overflow-y: auto; height: calc(100vh - 16.66667vw); padding: 8.33333vw; background-color: #FAF6F3; transition: background-color 0s ease .2s; }
  .nav-opens header .nav__wrap:before { height: 100%; transition: height .3s ease; }
  .nav-opens header .locale, .nav-opens header .nav, .nav-opens header .copyright { opacity: 1; transform: translateX(0); }
  .nav-opens header .locale { transition: opacity .3s ease .1s, transform .3s ease .1s; }
  .nav-opens header .nav { transition: opacity .3s ease .2s, transform .3s ease .2s; }
  .nav-opens header .copyright { transition: opacity .3s ease .3s, transform .3s ease .3s; }
  .nav-opens header.is-scroll { height: 22.22222vw; background-color: #FAF6F3; border-color: transparent; transition: background-color .2s ease; }
  .nav-opens header.is-scroll .logos { width: 22.22222vw; height: 11.11111vw; } }

footer { border-top: 1px solid #000; height: 6.84524vw; font-family: oscine, Helvetica, "malgun gothic", "Apple SD Gothic Neo", "Microsoft NeoGothic", "Droid sans", arial, sans-serif; font-weight: bold; }

@media (min-width: 1680px) { footer { height: 115px; } }

footer .main__inner { display: flex; align-items: center; height: 100%; }

footer .nav { display: flex; }

footer .nav li { padding-right: 1.78571vw; }

@media (min-width: 1680px) { footer .nav li { padding-right: 30px; } }

footer .nav a { color: #000; padding: 0.59524vw 1.4881vw 0.2381vw; border: 1px solid transparent; border-radius: 50%; transition: border-color .3s ease; }

@media (min-width: 1680px) { footer .nav a { padding: 10px 25px 4px; } }

footer .nav a:hover { border-color: #000; }

footer .info { margin-left: auto; }

footer .share { display: flex; align-items: center; justify-content: flex-end; }

footer .share .sns { display: block; border: 1px solid #000; border-radius: 3px; width: 1.90476vw; height: 1.90476vw; background: no-repeat center/100% auto; margin-left: 1.19048vw; }

@media (min-width: 1680px) { footer .share .sns { width: 32px; } }

@media (min-width: 1680px) { footer .share .sns { height: 32px; } }

@media (min-width: 1680px) { footer .share .sns { margin-left: 20px; } }

footer .share .sns:hover { background-color: #000; }

footer .share .sns.behance { background-image: url(../images/sns_behance.svg); }

footer .share .sns.behance:hover { background-image: url(../images/sns_behance-white.svg); }

footer .copyright { padding-top: 1.19048vw; font-size: 0.88889rem; }

@media (min-width: 1680px) { footer .copyright { padding-top: 20px; } }

@media (max-width: 1024px) { footer { height: 11.23047vw; }
  footer .nav li { padding-right: 2.92969vw; }
  footer .nav a { padding: 0.97656vw 2.44141vw 0.39063vw; }
  footer .share .sns { width: 3.51563vw; height: 3.51563vw; margin-left: 1.95313vw; }
  footer .copyright { padding-top: 1.46484vw; font-size: 1rem; } }

@media (max-width: 768px) { footer { height: 48.33333vw; }
  footer .main__inner { display: block; padding: 5.55556vw; }
  footer .nav li { padding-right: 11.11111vw; }
  footer .nav a { padding: 0; border: 0; }
  footer .info { margin: 0; }
  footer .share { padding: 8.33333vw 0vw 5.55556vw; justify-content: flex-start; } }

@media (max-width: 768px) and (max-width: 360px) { footer .share { padding: 8.33333vw 0vw 5.55556vw; } }

@media (max-width: 768px) { footer .share .sns { width: 10vw; height: 10vw; margin-left: 5.55556vw; }
  footer .copyright { padding-top: 1.46484vw; font-size: 1rem; } }

#wrapper { position: relative; }

#container { padding-top: 4.16667vw; overflow: hidden; min-height: calc(100vh - 115px); }

@media (min-width: 1680px) { #container { padding-top: 70px; } }

@media (max-width: 1680px) { #container { min-height: calc(100vh - 6.84524vw); } }

@media (max-width: 1024px) { #container { padding-top: 8.78906vw; min-height: calc(100vh - 11.23047vw); } }

@media (max-width: 768px) { #container { padding-top: 22.22222vw; min-height: calc(100vh - 48.33333vw); } }

.subpage_title { padding-bottom: 2.14286vw; font-size: 4rem; font-family: oscine, Helvetica, "malgun gothic", "Apple SD Gothic Neo", "Microsoft NeoGothic", "Droid sans", arial, sans-serif; font-weight: bold; }

@media (min-width: 1680px) { .subpage_title { padding-bottom: 36px; } }

@media (max-width: 1024px) { .subpage_title { font-size: 2.625rem; padding-bottom: 2.34375vw; }
  .subpage_title.detail { padding-bottom: 1.75781vw; } }

@media (max-width: 768px) { .subpage_title { font-size: 2.42857rem; padding-bottom: 5.55556vw; }
  .subpage_title.detail { font-size: 1.85714rem; padding-bottom: 6.66667vw; } }

.subpage_desc { font-size: 1.11111rem; padding-bottom: 5.95238vw; }

@media (min-width: 1680px) { .subpage_desc { padding-bottom: 100px; } }

@media (max-width: 1024px) { .subpage_desc { font-size: 0.875rem; padding-bottom: 3.90625vw; } }

@media (max-width: 768px) { .subpage_desc { font-size: 0.85714rem; padding-bottom: 8.33333vw; } }

.checkbox input { position: absolute; left: -1px; top: -1px; width: 1px; height: 1px; visibility: hidden; overflow: hidden; }

.checkbox label { padding-left: 1.90476vw; font-size: 0.88889rem; position: relative; display: block; text-align: initial; }

@media (min-width: 1680px) { .checkbox label { padding-left: 32px; } }

.checkbox label:before { content: ""; position: absolute; left: 0; top: 50%; transform: translateY(-50%); width: 1.19048vw; height: 1.19048vw; background-repeat: no-repeat; background-image: url(../images/checkbox_blank.svg); background-size: contain; }

@media (min-width: 1680px) { .checkbox label:before { width: 20px; } }

@media (min-width: 1680px) { .checkbox label:before { height: 20px; } }

.checkbox input:checked + label { color: #000; }

.checkbox input:checked + label:before { background-image: url(../images/checkbox_checked.svg); }

.checkbox.focus label { color: #ED7878; }

.checkbox.focus label:before { background-image: url(../images/checkbox_blank_focus.svg); }

@media (max-width: 1024px) { .checkbox label { padding-left: 3.125vw; font-size: 0.875rem; }
  .checkbox label:before { width: 1.95313vw; height: 1.95313vw; } }

@media (max-width: 768px) { .checkbox label { padding-left: 8.88889vw; font-size: 1rem; }
  .checkbox label:before { width: 5.55556vw; height: 5.55556vw; } }

.nav-opens .main__page header.is-scroll { background-color: #FAF6F3; border: none; }

.main__page header { position: absolute; border-bottom: 0; background-color: #FAF6F3; height: 5.95238vw; }

@media (min-width: 1680px) { .main__page header { height: 100px; } }

.main__page header .logos { width: 6.90476vw; height: 3.39286vw; }

@media (min-width: 1680px) { .main__page header .logos { width: 116px; } }

@media (min-width: 1680px) { .main__page header .logos { height: 57px; } }

.main__page header .locale .options { background-color: #FAF6F3; }

.main__page header.is-scroll { position: fixed; height: 4.16667vw; background-color: #fff; border-bottom: 1px solid #000; }

@media (min-width: 1680px) { .main__page header.is-scroll { height: 70px; } }

.main__page header.is-scroll .logos { width: 5.35714vw; height: 2.61905vw; }

@media (min-width: 1680px) { .main__page header.is-scroll .logos { width: 90px; } }

@media (min-width: 1680px) { .main__page header.is-scroll .logos { height: 44px; } }

.main__page header.is-scroll .locale .options { background-color: #fff; }

.main__page #container { padding-top: 5.95238vw; }

@media (min-width: 1680px) { .main__page #container { padding-top: 100px; } }

@media (max-width: 1024px) { .main__page header { height: 8.78906vw; }
  .main__page header .logos { width: 9.17969vw; height: 4.39453vw; }
  .main__page header.is-scroll { height: 6.83594vw; }
  .main__page header.is-scroll .logos { width: 8.78906vw; height: 4.39453vw; }
  .main__page #container { padding-top: 8.78906vw; } }

@media (max-width: 768px) { .main__page header { height: 22.22222vw; }
  .main__page header .logos { width: 22.22222vw; height: 11.11111vw; }
  .main__page header.is-scroll { height: 16.66667vw; }
  .main__page header.is-scroll .logos { width: 16.66667vw; height: 8.33333vw; }
  .main__page #container { padding-top: 22.22222vw; }
  .main__page .is-scroll .nav__wrap { top: 16.66667vw; } }

.fancy__wrap { max-width: 90.47619vw; padding-left: 3.57143vw; padding-right: 3.57143vw; margin: 0 auto; }

@media (min-width: 1680px) { .fancy__wrap { max-width: 1520px; } }

@media (min-width: 1680px) { .fancy__wrap { padding-left: 60px; } }

@media (min-width: 1680px) { .fancy__wrap { padding-right: 60px; } }

@media (max-width: 1024px) { .fancy__wrap { max-width: 100%; padding-left: 5.85938vw; padding-right: 5.85938vw; } }

@media (max-width: 768px) { .fancy__wrap { max-width: 100%; padding-left: 5.55556vw; padding-right: 5.55556vw; } }

.sub-inner__wrap { max-width: 73.57143vw; padding-left: 3.57143vw; padding-right: 3.57143vw; margin: 0 auto; }

@media (min-width: 1680px) { .sub-inner__wrap { max-width: 1236px; } }

@media (min-width: 1680px) { .sub-inner__wrap { padding-left: 60px; } }

@media (min-width: 1680px) { .sub-inner__wrap { padding-right: 60px; } }

@media (max-width: 1024px) { .sub-inner__wrap { max-width: 100%; padding-left: 5.85938vw; padding-right: 5.85938vw; } }

@media (max-width: 768px) { .sub-inner__wrap { max-width: 100%; padding-left: 5.55556vw; padding-right: 5.55556vw; } }

#vr_wrapper { width: 100%; height: 100%; overflow-x: hidden; overflow-y: auto; position: relative; }

.vr_header .main__inner { position: relative; }

.vr_header .nav__wrap .locale { order: -1; margin-right: 2.14286vw; }

@media (min-width: 1680px) { .vr_header .nav__wrap .locale { margin-right: 36px; } }

@media (min-width: 1025px) { .vr_header .nav__wrap .locale .current:after { margin-top: -0.05952vw; } }

@media (min-width: 1025px) and (min-width: 1680px) { .vr_header .nav__wrap .locale .current:after { margin-top: -1px; } }

.vr_header .nav { color: #000; padding: 0; position: relative; }

.vr_header .nav .menu_button { border: 1px solid #000; font-family: oscine, Helvetica, "malgun gothic", "Apple SD Gothic Neo", "Microsoft NeoGothic", "Droid sans", arial, sans-serif; font-weight: bold; font-size: 1rem; padding: 0.53571vw 1.19048vw; border-radius: 1.54762vw; line-height: 1; }

@media (min-width: 1680px) { .vr_header .nav .menu_button { padding: 9px 20px; } }

@media (min-width: 1680px) { .vr_header .nav .menu_button { border-radius: 26px; } }

.vr_header .nav .menu_button:hover { background: #000; color: #fff; }

.vr_header .nav ul { position: absolute; background-color: #fff; top: 100%; display: none; border: 1px solid #000; text-align: right; right: 0; margin-top: 0.2381vw; border-radius: 0.29762vw; padding: 0.17857vw 0vw; }

@media (min-width: 1680px) { .vr_header .nav ul { margin-top: 4px; } }

@media (min-width: 1680px) { .vr_header .nav ul { border-radius: 5px; } }

@media (min-width: 1680px) { .vr_header .nav ul { padding: 3px 0px; } }

.vr_header .nav ul li { margin: 0; padding: 0vw 1.19048vw; }

@media (min-width: 1680px) { .vr_header .nav ul li { padding: 0px 20px; } }

.vr_header .nav ul a { display: block; border: none; white-space: nowrap; font-size: 1rem; padding: 0.29762vw 0vw; }

@media (min-width: 1680px) { .vr_header .nav ul a { padding: 5px 0px; } }

.vr_header .nav ul a:hover { border: none; text-decoration: underline; }

.vr_header .nav ul .change_room { padding-right: 1.42857vw; }

@media (min-width: 1680px) { .vr_header .nav ul .change_room { padding-right: 24px; } }

.vr_header .nav ul .change_room:after { right: 0; }

.vr_header .nav.is-active ul { display: block; }

@media (max-width: 1024px) { .vr_header { height: 6.83594vw; }
  .vr_header .logos { width: 8.78906vw; height: 4.39453vw; }
  .vr_header .nav__wrap .locale { margin-right: 2.92969vw; }
  .vr_header .nav .menu_button { font-size: 1rem; padding: 0.97656vw 1.95313vw; border-radius: 2.53906vw; }
  .vr_header .nav ul { margin-top: 0.78125vw; border-radius: 0.48828vw; padding: 0.29297vw 0vw; }
  .vr_header .nav ul li { padding: 0vw 1.95313vw; }
  .vr_header .nav ul a { font-size: 1rem; padding: 0.58594vw 0vw; }
  .vr_header .nav ul .change_room { padding-right: 2.34375vw; } }

@media (max-width: 768px) { .vr_header { height: 16.66667vw; }
  .vr_header .logos { width: 16.66667vw; height: 8.33333vw; }
  .vr_header .nav__wrap .locale { width: 13.88889vw; margin-right: 5.55556vw; }
  .vr_header .nav__wrap .locale button { font-size: 1.28571rem; padding: 2.22222vw 2.77778vw; } }

@media (max-width: 768px) and (max-width: 360px) { .vr_header .nav__wrap .locale button { padding: 2.22222vw 2.77778vw; } }

@media (max-width: 768px) { .vr_header .nav__wrap .locale .current { height: 8.33333vw; }
  .vr_header .nav__wrap .locale .current:after { width: 2.77778vw; height: 2.77778vw; margin-top: -0.55556vw; }
  .vr_header .nav .menu_button { font-size: 1.14286rem; padding: 2.5vw 5.55556vw; border-radius: 7.22222vw; } }

@media (max-width: 768px) and (max-width: 360px) { .vr_header .nav .menu_button { padding: 2.5vw 5.55556vw; } }

@media (max-width: 768px) { .vr_header .nav ul { margin-top: 1.94444vw; border-radius: 1.38889vw; padding: 0.83333vw 0vw; } }

@media (max-width: 768px) and (max-width: 360px) { .vr_header .nav ul { padding: 0.83333vw 0vw; } }

@media (max-width: 768px) { .vr_header .nav ul li { padding: 0vw 5.55556vw; } }

@media (max-width: 768px) and (max-width: 360px) { .vr_header .nav ul li { padding: 0vw 5.55556vw; } }

@media (max-width: 768px) { .vr_header .nav ul a { font-size: 1.14286rem; padding: 1.66667vw 0vw; } }

@media (max-width: 768px) and (max-width: 360px) { .vr_header .nav ul a { padding: 1.66667vw 0vw; } }

@media (max-width: 768px) { .vr_header .nav ul .change_room { padding-right: 6.66667vw; }
  .vr_header .nav ul .change_room:after { width: 5vw; height: 5vw; } }

.vr_content { height: 99.9%; overflow: hidden; }

.vr_content body { width: 100%; height: 100%; overflow: hidden; }

.vr_content #pano { width: 100%; height: 100%; }

.contact-loading { position: fixed; left: 0; top: 0; right: 0; bottom: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.6); display: flex; align-items: center; justify-content: center; z-index: 999999; }

.contact-loading:after { content: ""; width: 5.95238vw; height: 5.95238vw; background: url(../images/spin.svg) no-repeat center/100% auto; }

@media (min-width: 1680px) { .contact-loading:after { width: 100px; } }

@media (min-width: 1680px) { .contact-loading:after { height: 100px; } }

@media (max-width: 768px) { .contact-loading:after { width: 22.22222vw; height: 22.22222vw; } }

.os_win .button.primary { padding: 0.95238vw 3.09524vw 0.65476vw 1.19048vw; }

@media (min-width: 1680px) { .os_win .button.primary { padding: 16px 52px 11px 20px; } }

@media (max-width: 1024px) { .os_win .button.primary { padding: 1.5625vw 4.49219vw 1.07422vw 1.95313vw; } }

@media (max-width: 768px) { .os_win .button.primary { padding: 3.61111vw 11.11111vw 2.5vw 5vw; } }

@media (max-width: 768px) and (max-width: 360px) { .os_win .button.primary { padding: 3.61111vw 11.11111vw 2.5vw 5vw; } }

@media (max-width: 768px) { .os_win .fancy_room .change_room:after { margin-top: -0.83333vw; } }

@media (max-width: 1024px) { .os_win .vr_header .locale .current:after { margin-top: -0.58594vw; } }

@media (max-width: 768px) { .os_win .vr_header .locale .current:after { margin-top: -1.38889vw; } }

.os_win .vr_header .nav .menu_button { padding: 0.65476vw 1.19048vw 0.41667vw; }

@media (min-width: 1680px) { .os_win .vr_header .nav .menu_button { padding: 11px 20px 7px; } }

@media (max-width: 1024px) { .os_win .vr_header .nav .menu_button { padding: 0.97656vw 1.95313vw 0.78125vw; } }

@media (max-width: 768px) { .os_win .vr_header .nav .menu_button { padding: 2.77778vw 5.55556vw 2.22222vw; } }

@media (max-width: 768px) and (max-width: 360px) { .os_win .vr_header .nav .menu_button { padding: 2.77778vw 5.55556vw 2.22222vw; } }

.os_win .vr_header .change_room:after { margin-top: -0.11905vw; }

@media (min-width: 1680px) { .os_win .vr_header .change_room:after { margin-top: -2px; } }

body.stop-transitions * { transition: none !important; }

.layer { position: fixed; left: 0; top: 0; }

.layer_inner { position: relative; overflow-y: auto; max-height: 80vh; background-color: #fff; width: 100%; }

.layer_inner::-webkit-scrollbar { width: 5px; height: 5px; }

.layer_inner::-webkit-scrollbar-track { background-color: transparent; }

.layer_inner::-webkit-scrollbar-thumb { border-radius: 8px; background-color: rgba(0, 0, 0, 0.1); }

.layer .close { position: absolute; right: 0.59524vw; top: 0.59524vw; width: 2.38095vw; height: 2.38095vw; font-size: 0; z-index: 9999; background: url(../images/icon_close-layer.svg) no-repeat center/100% auto; }

@media (min-width: 1680px) { .layer .close { right: 10px; } }

@media (min-width: 1680px) { .layer .close { top: 10px; } }

@media (min-width: 1680px) { .layer .close { width: 40px; } }

@media (min-width: 1680px) { .layer .close { height: 40px; } }

@media (max-width: 1024px) { .layer .close { right: 0.97656vw; top: 0.97656vw; width: 3.90625vw; height: 3.90625vw; } }

@media (max-width: 768px) { .layer .close { right: 2.22222vw; top: 2.22222vw; width: 11.11111vw; height: 11.11111vw; } }

.layer.is-hidden { width: 0; height: 0; overflow: hidden; }

.layer.is-open { width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.5); z-index: 99999; display: flex; align-items: center; justify-content: center; padding: 30px; }

.layer_confirm .layer_inner { width: 32.85714vw; padding: 2.97619vw 1.19048vw; color: #000; text-align: center; border-radius: 5px; }

@media (min-width: 1680px) { .layer_confirm .layer_inner { width: 552px; } }

@media (min-width: 1680px) { .layer_confirm .layer_inner { padding: 50px 20px; } }

.layer_confirm .top_icon { background: url("../images/icon_layer_checked.svg") no-repeat center/100% auto; display: inline-block; width: 2.7381vw; height: 2.7381vw; margin-bottom: 1.19048vw; }

@media (min-width: 1680px) { .layer_confirm .top_icon { width: 46px; } }

@media (min-width: 1680px) { .layer_confirm .top_icon { height: 46px; } }

@media (min-width: 1680px) { .layer_confirm .top_icon { margin-bottom: 20px; } }

.layer_confirm .message { font-size: 1rem; line-height: 1.7; padding-bottom: 2.38095vw; }

@media (min-width: 1680px) { .layer_confirm .message { padding-bottom: 40px; } }

.layer_confirm .button.js-confirm { width: 16.07143vw; height: 2.85714vw; padding: 1.19048vw; display: inline-flex; align-items: center; justify-content: center; }

@media (min-width: 1680px) { .layer_confirm .button.js-confirm { width: 270px; } }

@media (min-width: 1680px) { .layer_confirm .button.js-confirm { height: 48px; } }

@media (min-width: 1680px) { .layer_confirm .button.js-confirm { padding: 20px; } }

.layer_confirm .button.js-confirm:after { display: none; }

@media (max-width: 1024px) { .layer_confirm .layer_inner { width: 43.35938vw; padding: 3.90625vw 1.95313vw; }
  .layer_confirm .top_icon { width: 4.49219vw; height: 4.49219vw; margin-bottom: 1.95313vw; }
  .layer_confirm .message { font-size: 1rem; padding-bottom: 3.90625vw; }
  .layer_confirm .button.js-confirm { width: 20.89844vw; height: 4.29688vw; padding: 1.17188vw; } }

@media (max-width: 768px) { .layer_confirm .layer_inner { width: 123.33333vw; padding: 11.11111vw 5.55556vw; } }

@media (max-width: 768px) and (max-width: 360px) { .layer_confirm .layer_inner { padding: 11.11111vw 5.55556vw; } }

@media (max-width: 768px) { .layer_confirm .top_icon { width: 12.77778vw; height: 12.77778vw; margin-bottom: 5.55556vw; }
  .layer_confirm .message { font-size: 1rem; padding-bottom: 11.11111vw; }
  .layer_confirm .button.js-confirm { width: 59.44444vw; height: 12.22222vw; padding: 3.33333vw; } }

.layer_terms .layer_inner { width: 49.64286vw; border-radius: 0.29762vw; overflow-y: initial; max-height: initial; }

@media (min-width: 1680px) { .layer_terms .layer_inner { width: 834px; } }

@media (min-width: 1680px) { .layer_terms .layer_inner { border-radius: 5px; } }

.layer_terms .top { padding: 1.78571vw 1.78571vw 1.54762vw 1.78571vw; font-size: 1rem; }

@media (min-width: 1680px) { .layer_terms .top { padding: 30px 30px 26px 30px; } }

.layer_terms .content { padding: 0vw 1.78571vw; font-size: 0.88889rem; max-height: 47vh; overflow-y: auto; }

@media (min-width: 1680px) { .layer_terms .content { padding: 0px 30px; } }

.layer_terms .content::-webkit-scrollbar { width: 5px; }

.layer_terms .content::-webkit-scrollbar-track { background-color: transparent; }

.layer_terms .content::-webkit-scrollbar-thumb { border-radius: 8px; background-color: rgba(0, 0, 0, 0.1); }

.layer_terms .button_wrap { text-align: center; padding-bottom: 1.78571vw; margin-top: 2.02381vw; }

@media (min-width: 1680px) { .layer_terms .button_wrap { padding-bottom: 30px; } }

@media (min-width: 1680px) { .layer_terms .button_wrap { margin-top: 34px; } }

.layer_terms .button_wrap .agree_btn { font-weight: bold; width: 18.5119vw; padding: 0.71429vw; text-align: center; font-size: 0.88889rem; }

@media (min-width: 1680px) { .layer_terms .button_wrap .agree_btn { width: 311px; } }

@media (min-width: 1680px) { .layer_terms .button_wrap .agree_btn { padding: 12px; } }

.layer_terms .button_wrap .agree_btn:after { display: none; }

@media (max-width: 1024px) { .layer_terms .layer_inner { width: 65.82031vw; border-radius: 0.48828vw; }
  .layer_terms .top { padding: 2.92969vw 2.92969vw 1.95313vw 2.92969vw; font-size: 1rem; }
  .layer_terms .content { padding: 0vw 2.92969vw; font-size: 0.875rem; max-height: 44vh; max-height: 34.27734vw; }
  .layer_terms .button_wrap { margin-top: 1.95313vw; }
  .layer_terms .button_wrap .agree_btn { width: 24.60938vw; padding: 1.17188vw; font-size: 0.875rem; } }

@media (max-width: 768px) { .layer_terms .layer_inner { width: 100%; border-radius: 1.38889vw; }
  .layer_terms .top { padding: 5.55556vw; font-size: 1rem; }
  .layer_terms .content { padding: 0vw 5.55556vw; max-height: 44vh; font-size: 0.85714rem; } }

@media (max-width: 768px) and (max-width: 360px) { .layer_terms .content { padding: 0vw 5.55556vw; } }

@media (max-width: 768px) { .layer_terms .button_wrap { margin: 5.55556vw 0vw 5.55556vw; } }

@media (max-width: 768px) and (max-width: 360px) { .layer_terms .button_wrap { margin: 5.55556vw 0vw 5.55556vw; } }

@media (max-width: 768px) { .layer_terms .button_wrap .agree_btn { width: 58.88889vw; padding: 3.33333vw; font-size: 1rem; } }

.vr_layer { position: absolute; display: flex; justify-content: center; align-items: center; width: 100%; color: #000; }

.vr_layer .layer_inner { width: 66.36905vw; overflow: hidden; transform: translateY(50px); opacity: 0; }

@media (min-width: 1680px) { .vr_layer .layer_inner { width: 1115px; } }

.vr_layer .top { background-color: #fff; position: absolute; width: 100%; z-index: 999; padding: 0vw 1.78571vw; border-bottom: 1px solid #000; height: 5vw; display: flex; align-items: center; }

@media (min-width: 1680px) { .vr_layer .top { padding: 0px 30px; } }

@media (min-width: 1680px) { .vr_layer .top { height: 84px; } }

.vr_layer .top p { font-size: 1.88889rem; font-family: oscine, Helvetica, "malgun gothic", "Apple SD Gothic Neo", "Microsoft NeoGothic", "Droid sans", arial, sans-serif; font-weight: bold; color: #000; }

.vr_layer .top .close { right: 1.90476vw; top: 1.30952vw; }

@media (min-width: 1680px) { .vr_layer .top .close { right: 32px; } }

@media (min-width: 1680px) { .vr_layer .top .close { top: 22px; } }

.vr_layer .contents { overflow-y: auto; padding-top: 5vw; max-height: 80vh; }

@media (min-width: 1680px) { .vr_layer .contents { padding-top: 84px; } }

.vr_layer .contents::-webkit-scrollbar { width: 5px; }

.vr_layer .contents::-webkit-scrollbar-track { background-color: transparent; }

.vr_layer .contents::-webkit-scrollbar-thumb { border-radius: 8px; background-color: rgba(0, 0, 0, 0.1); }

.vr_layer.is-hidden { opacity: 0; }

.vr_layer.is-hidden .layer_inner .close { opacity: 0; }

.vr_layer.is-open { opacity: 1; }

.vr_layer.is-open .layer_inner { transition: opacity 0.6s ease,  transform 0.6s ease; opacity: 1; transform: translateY(0); }

.vr_layer.is-open .layer_inner .close { opacity: 1; }

.vr_layer .button { padding: 0.89286vw 2.61905vw 0.89286vw 1.19048vw; font-size: 1rem; }

@media (min-width: 1680px) { .vr_layer .button { padding: 15px 44px 15px 20px; } }

.vr_layer .button.primary::after { width: 0.95238vw; height: 1.07143vw; }

@media (min-width: 1680px) { .vr_layer .button.primary::after { width: 16px; } }

@media (min-width: 1680px) { .vr_layer .button.primary::after { height: 18px; } }

.vr_layer .button.website::after { width: 1.19048vw; height: 1.19048vw; }

@media (min-width: 1680px) { .vr_layer .button.website::after { width: 20px; } }

@media (min-width: 1680px) { .vr_layer .button.website::after { height: 20px; } }

@media (max-width: 1024px) { .vr_layer .layer_inner { width: 88.28125vw; }
  .vr_layer .top { padding: 0vw 1.95313vw; height: 7.42188vw; }
  .vr_layer .top p { font-size: 1.75rem; }
  .vr_layer .top .close { right: 1.75781vw; top: 1.95313vw; width: 3.90625vw; height: 3.90625vw; }
  .vr_layer .contents { overflow-y: auto; padding-top: 7.42188vw; max-height: 80vh; }
  .vr_layer .button { padding: 1.17188vw 3.90625vw 1.17188vw 1.75781vw; font-size: 0.875rem; }
  .vr_layer .button.primary::after { width: 1.36719vw; height: 1.07422vw; }
  .vr_layer .button.website::after { width: 1.75781vw; height: 1.75781vw; } }

@media (max-width: 768px) { .vr_layer.is-open { padding: 0; }
  .vr_layer .layer_inner { width: 100%; height: 100%; max-height: initial; }
  .vr_layer .top { padding: 0vw 5.55556vw; height: 18.88889vw; } }

@media (max-width: 768px) and (max-width: 360px) { .vr_layer .top { padding: 0vw 5.55556vw; } }

@media (max-width: 768px) { .vr_layer .top p { font-size: 1.57143rem; }
  .vr_layer .top .close { right: 4.44444vw; top: 2.77778vw; width: 11.11111vw; height: 11.11111vw; }
  .vr_layer .contents { overflow-y: auto; padding-top: 18.33333vw; max-height: 100%; }
  .vr_layer .button { padding: 3.33333vw 11.11111vw 3.33333vw 5vw; font-size: 1rem; } }

@media (max-width: 768px) and (max-width: 360px) { .vr_layer .button { padding: 3.33333vw 11.11111vw 3.33333vw 5vw; } }

@media (max-width: 768px) { .vr_layer .button.primary::after { width: 3.88889vw; height: 3.05556vw; }
  .vr_layer .button.website::after { width: 5vw; height: 5vw; } }

.os_win .vr_layer .top { padding-top: 0.47619vw; }

@media (min-width: 1680px) { .os_win .vr_layer .top { padding-top: 8px; } }

@media (max-width: 1024px) { .os_win .vr_layer .top { padding-top: 0.58594vw; } }

@media (max-width: 768px) { .os_win .vr_layer .top { padding-top: 1.38889vw; } }

@media (max-width: 768px) { .header_visible header { z-index: 100000; }
  .inner_privacy { padding-top: 16.66667vw; } }

.main__page { background-color: #FAF6F3; font-family: oscine, Helvetica, "malgun gothic", "Apple SD Gothic Neo", "Microsoft NeoGothic", "Droid sans", arial, sans-serif; font-weight: bold; }

.main_section-title { font-family: oscine, Helvetica, "malgun gothic", "Apple SD Gothic Neo", "Microsoft NeoGothic", "Droid sans", arial, sans-serif; font-weight: bold; font-size: 4rem; line-height: 1.3; display: flex; }

.main_section-title span { opacity: 0; transition: 0.3s ease-in-out; transform: translateY(50px); }

.main_section-title.on span { opacity: 1; transform: translateY(0px); }

@media (max-width: 1024px) { .main_section-title { font-size: 2.875rem; } }

@media (max-width: 768px) { .main_section-title { font-size: 2.57143rem; } }

.main__visual { padding-top: 1.78571vw; padding-bottom: 2.97619vw; }

@media (min-width: 1680px) { .main__visual { padding-top: 30px; } }

@media (min-width: 1680px) { .main__visual { padding-bottom: 50px; } }

@media (max-width: 1024px) { .main__visual { padding-top: 4.88281vw; padding-bottom: 6.83594vw; } }

@media (max-width: 768px) { .main__visual { padding-top: 5.55556vw; padding-bottom: 8.33333vw; } }

.main__visual .main__inner { display: flex; }

@media (max-width: 768px) { .main__visual .main__inner { display: block; } }

.main__visual .slider { width: 100%; }

.main__visual .slider_wrap { max-width: 57.97619vw; width: 57.9%; }

@media (min-width: 1680px) { .main__visual .slider_wrap { max-width: 974px; } }

.main__visual .slider-item { position: relative; width: 100%; background-color: #fff; display: block !important; }

.main__visual .slider-item a { display: block; padding: 0.83333vw; color: #000; }

@media (min-width: 1680px) { .main__visual .slider-item a { padding: 14px; } }

.main__visual .slider-item .images img { width: 100%; }

.main__visual .slider-item .info { position: absolute; left: 0; top: 0; width: 100%; height: 100%; padding: 2.97619vw; }

@media (min-width: 1680px) { .main__visual .slider-item .info { padding: 50px; } }

.main__visual .slider-item .info .year { font-size: 1.88889rem; }

.main__visual .slider-item .info .title { font-size: 3.22222rem; padding: 1.19048vw 3.57143vw 3.27381vw 0vw; line-height: 1.3; }

@media (min-width: 1680px) { .main__visual .slider-item .info .title { padding: 20px 60px 55px 0px; } }

.main__visual .slider-item .info .cont { width: 42%; font-weight: normal; font-family: "Noto Sans KR", Helvetica, "malgun gothic", "Apple SD Gothic Neo", "Microsoft NeoGothic", "Droid sans", sans-serif; line-height: 1.7; }

@media (max-width: 1780px) { .main__visual .slider-item .info .cont { width: 40%; } }

.main__visual .slider-item[data-index="1"] .images { background-color: #CAD8F2; }

.main__visual .slider-item[data-index="2"] .images { background-color: #C9B5E3; }

.main__visual .slider-item[data-index="3"] .images { background-color: #B9E2DA; }

.main__visual .slider-item[data-index="4"] .images { background-color: #FCCFCF; }

.main__visual .slider-item[data-index="5"] .images { background-color: #FACE87; }

.main__visual .slider-item[data-index="6"] .images { background-color: #E9F29E; }

.main__visual .slider-item[data-index="7"] .images { background-color: #F8E6D9; }

.main__visual .slider-item[data-index="8"] .images { background-color: #D5D5D5; }

.main__visual .slider-progress { width: 100%; height: 4px; border: solid #555; border-width: 0 1px 1px; background-color: #fff; }

.main__visual .slider-progress .bar { display: block; width: 0; height: 100%; background-color: #555; }

.main__visual .slider-progress .bar.on { animation: progress 5s linear; }

@keyframes progress { 100% { width: 100%; } }

.main__visual .slider .slick-list { border: 1px solid #000; }

.main__visual .slider .slick-arrow { position: absolute; bottom: 2.97619vw; z-index: 1001; }

@media (min-width: 1680px) { .main__visual .slider .slick-arrow { bottom: 50px; } }

.main__visual .slider .slick-arrow.slick-prev { left: 2.97619vw; }

@media (min-width: 1680px) { .main__visual .slider .slick-arrow.slick-prev { left: 50px; } }

.main__visual .slider .slick-arrow.slick-next { left: 8.33333vw; }

@media (min-width: 1680px) { .main__visual .slider .slick-arrow.slick-next { left: 140px; } }

.main__visual .slider .slick-dots { position: absolute; right: 2.97619vw; top: 2.97619vw; z-index: 1001; width: auto; display: block; }

@media (min-width: 1680px) { .main__visual .slider .slick-dots { right: 50px; } }

@media (min-width: 1680px) { .main__visual .slider .slick-dots { top: 50px; } }

.main__visual .slider .slick-dots li { margin: 0; position: absolute; right: 0; top: 0; display: none; }

.main__visual .slider .slick-dots li.slick-active { display: block; }

@media (max-width: 1024px) { .main__visual .slider_wrap { width: 54.58984vw; }
  .main__visual .slider-item { padding: 0.87891vw; }
  .main__visual .slider-item .info { padding: 2.73438vw; }
  .main__visual .slider-item .info .year { font-size: 1.625rem; }
  .main__visual .slider-item .info .title { font-size: 2.625rem; padding: 1.46484vw 0vw 2.44141vw; }
  .main__visual .slider-item .info .cont { width: 100%; } }

@media (max-width: 1024px) and (min-width: 769px) { .main__visual .slider-item .images { position: relative; width: 100%; height: 0; padding-top: 100%; overflow: hidden; }
  .main__visual .slider-item .images img { position: absolute; left: 0; bottom: 0; } }

@media (max-width: 1024px) { .main__visual .slider .slick-arrow { bottom: 2.73438vw; }
  .main__visual .slider .slick-arrow.slick-prev { left: 2.73438vw; }
  .main__visual .slider .slick-arrow.slick-next { left: 9.57031vw; }
  .main__visual .slider .slick-dots { right: 2.73438vw; top: 2.73438vw; } }

@media (max-width: 768px) { .main__visual .slider_wrap { width: 100%; max-width: 100%; }
  .main__visual .slider-item { padding: 2.5vw; }
  .main__visual .slider-item .images { min-height: 172.22222vw; }
  .main__visual .slider-item .info { padding: 6.94444vw; }
  .main__visual .slider-item .info .year { font-size: 1.42857rem; }
  .main__visual .slider-item .info .title { font-size: 2.28571rem; padding: 4.16667vw 0vw 6.94444vw; } }

@media (max-width: 768px) and (max-width: 360px) { .main__visual .slider-item .info .title { padding: 4.16667vw 0vw 6.94444vw; } }

@media (max-width: 768px) { .main__visual .slider-item .info .cont { width: 100%; }
  .main__visual .slider .slick-arrow { bottom: 8.33333vw; }
  .main__visual .slider .slick-arrow.slick-prev { left: 29.16667vw; }
  .main__visual .slider .slick-arrow.slick-next { left: 48.61111vw; }
  .main__visual .slider .slick-dots { right: 6.94444vw; top: 6.94444vw; } }

.main__visual .links { position: relative; padding-top: 117.56%; width: 100%; height: 0; }

.main__visual .links_wrap { max-width: 40vw; width: 42%; margin-left: 2.02381vw; }

@media (min-width: 1680px) { .main__visual .links_wrap { max-width: 672px; } }

@media (min-width: 1680px) { .main__visual .links_wrap { margin-left: 34px; } }

.main__visual .links li { position: absolute; }

.main__visual .links .item { position: relative; display: block; width: 100%; height: 0; }

.main__visual .links .item:hover .shadow .box, .main__visual .links .item:hover .shadow:before, .main__visual .links .item:hover .shadow:after { opacity: 1; transition: left .15s ease, top .15s ease, opacity 0s ease 0s; }

.main__visual .links .item:hover .shadow:before { left: 0.41667vw; top: 0.41667vw; }

@media (min-width: 1680px) { .main__visual .links .item:hover .shadow:before { left: 7px; } }

@media (min-width: 1680px) { .main__visual .links .item:hover .shadow:before { top: 7px; } }

.main__visual .links .item:hover .shadow .box { left: 0.83333vw; top: 0.83333vw; }

@media (min-width: 1680px) { .main__visual .links .item:hover .shadow .box { left: 14px; } }

@media (min-width: 1680px) { .main__visual .links .item:hover .shadow .box { top: 14px; } }

.main__visual .links .item:hover .shadow:after { left: 1.25vw; top: 1.25vw; }

@media (min-width: 1680px) { .main__visual .links .item:hover .shadow:after { left: 21px; } }

@media (min-width: 1680px) { .main__visual .links .item:hover .shadow:after { top: 21px; } }

.main__visual .links .text, .main__visual .links .shadow { width: 100%; height: 100%; position: absolute; left: 0; top: 0; }

.main__visual .links .shadow { z-index: 8; }

.main__visual .links .shadow .box, .main__visual .links .shadow:before, .main__visual .links .shadow:after { content: ""; width: 100%; height: 100%; position: absolute; left: 0vw; top: 0vw; transition: left .15s ease, top .15s ease, opacity 0s ease .15s; background: no-repeat center/100% auto; opacity: 0; }

@media (min-width: 1680px) { .main__visual .links .shadow .box, .main__visual .links .shadow:before, .main__visual .links .shadow:after { left: 0px; } }

@media (min-width: 1680px) { .main__visual .links .shadow .box, .main__visual .links .shadow:before, .main__visual .links .shadow:after { top: 0px; } }

.main__visual .links .shadow:before { z-index: 5; }

.main__visual .links .shadow .box { z-index: 4; }

.main__visual .links .shadow:after { z-index: 3; }

.main__visual .links .text { font-size: 2.33333rem; color: #000; text-transform: uppercase; z-index: 10; display: flex; justify-content: center; align-items: center; background: no-repeat center/100% auto; }

.main__visual .links .about { left: 17.8%; top: 3%; width: 56.99%; }

.main__visual .links .about .item { padding-top: 32.114%; }

.main__visual .links .about .text { background-image: url(../images/kv_box1_1.svg); }

.main__visual .links .about .shadow:before { background-image: url(../images/kv_box1_2.svg); }

.main__visual .links .about .shadow .box { background-image: url(../images/kv_box1_3.svg); }

.main__visual .links .about .shadow:after { background-image: url(../images/kv_box1_4.svg); }

.main__visual .links .strength { left: 37.2%; top: 28.86%; width: 59.52%; }

.main__visual .links .strength .item { padding-top: 30%; }

.main__visual .links .strength .text { background-image: url(../images/kv_box2_1.svg); }

.main__visual .links .strength .shadow:before { background-image: url(../images/kv_box2_2.svg); }

.main__visual .links .strength .shadow .box { background-image: url(../images/kv_box2_3.svg); }

.main__visual .links .strength .shadow:after { background-image: url(../images/kv_box2_4.svg); }

.main__visual .links .solutions { left: 17.85%; top: 52.15%; width: 71.13%; }

.main__visual .links .solutions .item { padding-top: 33.69%; }

.main__visual .links .solutions .text { background-image: url(../images/kv_box3_1.svg); }

.main__visual .links .solutions .shadow:before { background-image: url(../images/kv_box3_2.svg); }

.main__visual .links .solutions .shadow .box { background-image: url(../images/kv_box3_3.svg); }

.main__visual .links .solutions .shadow:after { background-image: url(../images/kv_box3_4.svg); }

.main__visual .links .clients { left: 29.2%; top: 79.74%; width: 68.45%; }

.main__visual .links .clients .item { padding-top: 36.08%; }

.main__visual .links .clients .text { background-image: url(../images/kv_box4_1.svg); }

.main__visual .links .clients .shadow:before { background-image: url(../images/kv_box4_2.svg); }

.main__visual .links .clients .shadow .box { background-image: url(../images/kv_box4_3.svg); }

.main__visual .links .clients .shadow:after { background-image: url(../images/kv_box4_4.svg); }

.main__visual .links .deco_item { position: absolute; }

.main__visual .links .deco_item span { content: ""; position: absolute; left: 0; top: 0; width: 100%; height: 0; background: no-repeat center/100% auto; }

.main__visual .links .deco .deco1 { width: 10.4%; left: 83.63%; top: 15.3%; }

.main__visual .links .deco .deco1 span { padding-top: 104.2%; background-image: url(../images/kv_deco1.svg); animation: deco1 5s linear infinite; }

@keyframes deco1 { 0% { transform: rotate(0deg); }
  25% { transform: rotate(65deg); }
  50% { transform: rotate(0deg); }
  75% { transform: rotate(-65deg); } }

.main__visual .links .deco .deco2 { width: 12.64%; left: 21.27%; top: 25.56%; }

.main__visual .links .deco .deco2 .deco2_1 { stroke-dashoffset: 106; stroke-dasharray: 106; animation: deco2-line 0.9s forwards; }

.main__visual .links .deco .deco2 .deco2_2 { stroke-dashoffset: 45; stroke-dasharray: 45; animation: deco2-line 0.5s 0.8s forwards; }

@keyframes deco2-line { 100% { stroke-dashoffset: 0; } }

.main__visual .links .deco .deco3 { width: 9.375%; left: 89.56%; top: 69%; }

.main__visual .links .deco .deco3 span { padding-top: 103.17%; background-image: url(../images/kv_deco3.svg); animation: deco3 2.5s ease-in infinite; }

@keyframes deco3 { 0% { transform: scale(1); }
  50% { transform: scale(0.2); } }

.main__visual .links .deco .deco4 { width: 11.3%; left: 12.79%; top: 77.97%; }

.main__visual .links .deco .deco4 span { padding-top: 100%; background-image: url(../images/kv_deco4.svg); animation: deco4 5s linear infinite; }

@keyframes deco4 { 0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); } }

@media (max-width: 1024px) { .main__visual .links { padding-top: 161.73%; }
  .main__visual .links_wrap { width: 33.69141vw; }
  .main__visual .links .text { font-size: 1.5rem; }
  .main__visual .links .about { left: 15.1%; top: 5%; width: 67.53%; }
  .main__visual .links .strength { left: 29.56%; top: 28.80%; width: 70.43%; }
  .main__visual .links .solutions { left: 10.72%; top: 51.16%; width: 80.57%; }
  .main__visual .links .clients { left: 19.71%; top: 79.24%; width: 79.13%; }
  .main__visual .links .deco .deco1 { width: 10.4%; left: 86.9%; top: 16.45%; }
  .main__visual .links .deco .deco2 { width: 15.07%; left: 8.69%; top: 24.5%; }
  .main__visual .links .deco .deco3 { width: 11.3%; left: 88.69%; top: 68%; }
  .main__visual .links .deco .deco4 { width: 13.33%; left: 9.27%; top: 75.13%; } }

@media (max-width: 768px) { .main__visual .links { padding-top: 156.25%; }
  .main__visual .links_wrap { width: 100%; max-width: 100%; margin-left: 0; }
  .main__visual .links .text { font-size: 1.57143rem; }
  .main__visual .links .about { left: 5.625%; top: 9.2%; width: 65%; }
  .main__visual .links .strength { left: 29.375%; top: 30.2%; width: 67.18%; }
  .main__visual .links .solutions { left: 5.625%; top: 49.6%; width: 77.5%; }
  .main__visual .links .clients { left: 19.375%; top: 73.4%; width: 77.18%; }
  .main__visual .links .deco .deco1 { width: 11.875%; left: 80.625%; top: 18.8%; }
  .main__visual .links .deco .deco2 { width: 14.375%; left: 9.6875%; top: 27.2%; }
  .main__visual .links .deco .deco3 { width: 10.625%; left: 83.125%; top: 64%; }
  .main__visual .links .deco .deco4 { width: 12.81%; left: 0; top: 72.8%; } }

.main__works { border-top: 1px solid #555; border-bottom: 1px solid #555; }

.main__works .top { padding: 2.08333vw 0vw; border-bottom: 1px solid #555; }

@media (min-width: 1680px) { .main__works .top { padding: 35px 0px; } }

.main__works .top .main__inner { display: flex; justify-content: space-between; align-items: center; }

@media (max-width: 1024px) { .main__works .top { padding: 2.92969vw 0vw; } }

@media (max-width: 768px) { .main__works .top { padding: 6.94444vw 0vw; } }

@media (max-width: 768px) and (max-width: 360px) { .main__works .top { padding: 6.94444vw 0vw; } }

.main__works .contents { border-bottom: 1px solid #555; }

.main__works .contents .slick-slide { border-right: 1px solid #555; }

.main__works .contents .item { width: 27.2vw !important; height: 0; padding-top: 123%; position: relative; max-width: 30.95238vw; }

@media (min-width: 1680px) { .main__works .contents .item { max-width: 520px; } }

@media (max-width: 1024px) { .main__works .contents { padding-left: 2.92969vw; }
  .main__works .contents .item { width: 35.1vw !important; max-width: initial; } }

@media (max-width: 768px) { .main__works .contents { padding-left: 0 !important; }
  .main__works .contents .item { width: 80vw !important; max-width: initial; } }

.main__works .contents .project { position: absolute; left: 2.97619vw; right: 2.97619vw; bottom: 2.97619vw; top: 2.97619vw; padding: 1.78571vw; font-family: oscine, Helvetica, "malgun gothic", "Apple SD Gothic Neo", "Microsoft NeoGothic", "Droid sans", arial, sans-serif; font-weight: bold; border: 1px solid #000; display: flex; flex-direction: column; justify-content: space-between; background-color: #fff; cursor: url(../images/cursor_work-list.svg), auto; }

@media (min-width: 1680px) { .main__works .contents .project { left: 50px; } }

@media (min-width: 1680px) { .main__works .contents .project { right: 50px; } }

@media (min-width: 1680px) { .main__works .contents .project { bottom: 50px; } }

@media (min-width: 1680px) { .main__works .contents .project { top: 50px; } }

@media (min-width: 1680px) { .main__works .contents .project { padding: 30px; } }

.main__works .contents .project-year { font-size: 1.11111rem; padding-bottom: 1.25vw; }

@media (min-width: 1680px) { .main__works .contents .project-year { padding-bottom: 21px; } }

.main__works .contents .project-title { font-size: 1.55556rem; line-height: 1.5; display: -webkit-box; overflow: hidden; text-overflow: ellipsis; -webkit-box-orient: vertical; -webkit-line-clamp: 3; max-height: 4.5em; }

.main__works .contents .project-desc { font-size: 0.77778rem; padding-top: 2.55952vw; font-weight: normal; line-height: 1.7; font-family: "Noto Sans KR", Helvetica, "malgun gothic", "Apple SD Gothic Neo", "Microsoft NeoGothic", "Droid sans", sans-serif; display: -webkit-box; overflow: hidden; text-overflow: ellipsis; -webkit-box-orient: vertical; -webkit-line-clamp: 5; max-height: 13em; }

@media (min-width: 1680px) { .main__works .contents .project-desc { padding-top: 43px; } }

.main__works .contents .project-detail { font-size: 0.88889rem; display: flex; line-height: 1.7; justify-content: space-between; }

.main__works .contents .project-detail .technique { width: 76%; display: -webkit-box; overflow: hidden; text-overflow: ellipsis; -webkit-box-orient: vertical; -webkit-line-clamp: 2; max-height: 3.4em; }

.main__works .contents .project:before, .main__works .contents .project:after { content: ''; position: absolute; width: 100%; height: 100%; transition: 0.15s cubic-bezier(0.17, 0.67, 0.83, 0.67); border: 1px solid #000; box-sizing: border-box; }

.main__works .contents .project:before { z-index: -1; left: 0vw; top: 0vw; background-color: #F2B218; }

@media (min-width: 1680px) { .main__works .contents .project:before { left: 0px; } }

@media (min-width: 1680px) { .main__works .contents .project:before { top: 0px; } }

.main__works .contents .project:after { z-index: -2; left: 0vw; top: 0vw; background-color: #E57C01; }

@media (min-width: 1680px) { .main__works .contents .project:after { left: 0px; } }

@media (min-width: 1680px) { .main__works .contents .project:after { top: 0px; } }

.main__works .contents .project:hover:before { left: 0.59524vw; top: 0.59524vw; }

@media (min-width: 1680px) { .main__works .contents .project:hover:before { left: 10px; } }

@media (min-width: 1680px) { .main__works .contents .project:hover:before { top: 10px; } }

.main__works .contents .project:hover:after { left: 1.19048vw; top: 1.19048vw; }

@media (min-width: 1680px) { .main__works .contents .project:hover:after { left: 20px; } }

@media (min-width: 1680px) { .main__works .contents .project:hover:after { top: 20px; } }

@media (min-width: 1025px) { .main__works .contents .project-desc { opacity: 0; transition: 0.3s; }
  .main__works .contents .project:hover .project-desc { opacity: 1; } }

@media (max-width: 1024px) { .main__works .contents .project { left: 2.92969vw; right: 2.92969vw; bottom: 2.92969vw; top: 2.92969vw; padding: 1.95313vw; }
  .main__works .contents .project-year { font-size: 1.125rem; padding-bottom: 1.95313vw; }
  .main__works .contents .project-title { font-size: 1.625rem; display: -webkit-box; overflow: hidden; text-overflow: ellipsis; -webkit-box-orient: vertical; -webkit-line-clamp: 3; max-height: 4.5em; }
  .main__works .contents .project-desc { display: -webkit-box; overflow: hidden; text-overflow: ellipsis; -webkit-box-orient: vertical; -webkit-line-clamp: 3; max-height: 8em; }
  .main__works .contents .project-detail { font-size: 1rem; }
  .main__works .contents .project:hover:before { left: 0.58594vw; top: 0.58594vw; }
  .main__works .contents .project:hover:after { left: 1.17188vw; top: 1.17188vw; } }

@media (max-width: 768px) { .main__works .contents .project { left: 5.55556vw; right: 5.55556vw; bottom: 5.55556vw; top: 5.55556vw; padding: 4.16667vw; }
  .main__works .contents .project-year { font-size: 1.14286rem; padding-bottom: 4.16667vw; }
  .main__works .contents .project-title { font-size: 1.57143rem; }
  .main__works .contents .project-detail { font-size: 1rem; }
  .main__works .contents .project:hover:before { left: 1.11111vw; top: 1.11111vw; }
  .main__works .contents .project:hover:after { left: 2.22222vw; top: 2.22222vw; } }

.main__works .flow-container { padding: 2.97619vw 0vw; }

@media (min-width: 1680px) { .main__works .flow-container { padding: 50px 0px; } }

.main__works .flow-text { font-family: oscine, Helvetica, "malgun gothic", "Apple SD Gothic Neo", "Microsoft NeoGothic", "Droid sans", arial, sans-serif; font-weight: bold; color: #9D9993; font-size: 2.5rem; text-transform: uppercase; display: flex; flex: 0 0 auto; white-space: nowrap; overflow: hidden; transition: 0.3s; }

.main__works .flow-text:hover { color: #000; }

.main__works .flow-text:hover .flow-wrap { animation-play-state: paused; cursor: pointer; }

.main__works .flow-wrap { line-height: 1; animation: textLoop 10s linear infinite; padding-right: 1.4881vw; }

@media (min-width: 1680px) { .main__works .flow-wrap { padding-right: 25px; } }

@media (max-width: 1024px) { .main__works .flow-container { padding: 3.41797vw 0vw; }
  .main__works .flow-text { font-size: 2.1875rem; }
  .main__works .flow-wrap { padding-right: 1.95313vw; } }

@media (max-width: 768px) { .main__works .flow-container { padding: 9.44444vw 0vw; } }

@media (max-width: 768px) and (max-width: 360px) { .main__works .flow-container { padding: 9.44444vw 0vw; } }

@media (max-width: 768px) { .main__works .flow-text { font-size: 2rem; }
  .main__works .flow-wrap { padding-right: 4.16667vw; } }

@keyframes textLoop { 0% { -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  100% { -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.main__contact { position: relative; }

.main__contact .main__inner { position: relative; padding-top: 4.7619vw; padding-bottom: 10.71429vw; }

@media (min-width: 1680px) { .main__contact .main__inner { padding-top: 80px; } }

@media (min-width: 1680px) { .main__contact .main__inner { padding-bottom: 180px; } }

.main__contact .info { margin: 7.14286vw 0vw 2.97619vw; width: 66.36905vw; border-top: 1px solid #555; }

@media (min-width: 1680px) { .main__contact .info { margin: 120px 0px 50px; } }

@media (min-width: 1680px) { .main__contact .info { width: 1115px; } }

.main__contact .info li { line-height: 1; padding: 1.78571vw 0vw; display: flex; border-bottom: 1px solid #555; font-size: 1.88889rem; font-family: oscine, Helvetica, "malgun gothic", "Apple SD Gothic Neo", "Microsoft NeoGothic", "Droid sans", arial, sans-serif; font-weight: bold; }

@media (min-width: 1680px) { .main__contact .info li { padding: 30px 0px; } }

.main__contact .info li span { width: 25%; padding-right: 1.19048vw; }

@media (min-width: 1680px) { .main__contact .info li span { padding-right: 20px; } }

.main__contact .info li p { flex: 1; }

.main__contact .button_wrap { text-transform: uppercase; }

.main__contact .button_wrap.vertical { display: flex; flex-direction: column; align-items: flex-start; }

.main__contact .button_wrap.vertical .download { margin-bottom: 1.78571vw; }

@media (min-width: 1680px) { .main__contact .button_wrap.vertical .download { margin-bottom: 30px; } }

@media (max-width: 1024px) { .main__contact .main__inner { padding-top: 4.88281vw; padding-bottom: 10.74219vw; }
  .main__contact .info { margin: 9.08203vw 0vw 4.88281vw; width: 88.28125vw; }
  .main__contact .info li { padding: 2.92969vw 0vw; font-size: 2.125rem; justify-content: space-between; }
  .main__contact .info li span { width: 31%; }
  .main__contact .button_wrap.vertical .download { margin-bottom: 2.92969vw; } }

@media (max-width: 768px) { .main__contact .main__inner { padding-top: 8.33333vw; padding-bottom: 5.55556vw; }
  .main__contact .info { margin: 19.72222vw 0vw 13.88889vw; width: 100%; } }

@media (max-width: 768px) and (max-width: 360px) { .main__contact .info { margin: 19.72222vw 0vw 13.88889vw; } }

@media (max-width: 768px) { .main__contact .info li { line-height: 1.3; padding: 5.55556vw 0vw; font-size: 1.57143rem; } }

@media (max-width: 768px) and (max-width: 360px) { .main__contact .info li { padding: 5.55556vw 0vw; } }

@media (max-width: 768px) { .main__contact .button_wrap.vertical .download { margin-bottom: 8.33333vw; } }

.main__contact .contact_us { position: absolute; right: 7.20238vw; bottom: 5.11905vw; width: 17.61905vw; height: 17.61905vw; border-radius: 50%; border: 1px solid #000; }

@media (min-width: 1680px) { .main__contact .contact_us { right: 121px; } }

@media (min-width: 1680px) { .main__contact .contact_us { bottom: 86px; } }

@media (min-width: 1680px) { .main__contact .contact_us { width: 296px; } }

@media (min-width: 1680px) { .main__contact .contact_us { height: 296px; } }

.main__contact .contact_us .text { position: absolute; background: url("../images/contact_text.svg") no-repeat center; background-size: contain; width: 100%; height: 100%; left: 0; top: 0; animation: rotate 30s linear infinite; }

.main__contact .contact_us .text.hover-text { background-image: url("../images/contact_text-hover.svg"); opacity: 0; }

.main__contact .contact_us .link { display: block; width: 100%; height: 100%; position: absolute; z-index: 999; border-radius: 50%; }

.main__contact .contact_us .link span { top: 50%; left: 50%; transform: translate(-50%, -50%); position: relative; display: block; width: 11.78571vw; height: 11.78571vw; font-size: 0; border-radius: 50%; border: 1px solid #000; }

@media (min-width: 1680px) { .main__contact .contact_us .link span { width: 198px; } }

@media (min-width: 1680px) { .main__contact .contact_us .link span { height: 198px; } }

.main__contact .contact_us .link span:after { content: ''; display: block; top: 50%; left: 50%; transform: translate(-50%, -50%); position: absolute; background: url(../images/contact_us_arrow.svg) no-repeat; width: 5.29762vw; height: 4.34524vw; background-size: contain; }

@media (min-width: 1680px) { .main__contact .contact_us .link span:after { width: 89px; } }

@media (min-width: 1680px) { .main__contact .contact_us .link span:after { height: 73px; } }

.main__contact .contact_us:hover .text { opacity: 0; }

.main__contact .contact_us:hover .text.hover-text { opacity: 1; }

.main__contact .contact_us:hover .link span { background: linear-gradient(#BDCCFA, #DEC1F9); }

@media (max-width: 1024px) { .main__contact .contact_us { right: 5.85938vw; bottom: 4.88281vw; width: 20.89844vw; height: 20.89844vw; }
  .main__contact .contact_us .link span { width: 14.0625vw; height: 14.0625vw; }
  .main__contact .contact_us .link span:after { width: 6.25vw; height: 5.17578vw; } }

@media (max-width: 768px) { .main__contact .contact_us { right: 5.55556vw; bottom: 13.88889vw; width: 29.44444vw; height: 29.44444vw; }
  .main__contact .contact_us .text { background: url("../images/contact_text_mobile.svg") no-repeat center/100% auto; }
  .main__contact .contact_us .text.hover-text { background-image: url("../images/contact_text-hover_mobile.svg"); }
  .main__contact .contact_us .link span { width: 16.66667vw; height: 16.66667vw; }
  .main__contact .contact_us .link span:after { width: 8.88889vw; height: 7.5vw; } }

@keyframes rotate { from { -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

.os_win .main__visual .links .text { padding-top: 0.29762vw; }

@media (min-width: 1680px) { .os_win .main__visual .links .text { padding-top: 5px; } }

@media (max-width: 1024px) { .os_win .main__visual .links .text { padding-top: 0.48828vw; } }

@media (max-width: 768px) { .os_win .main__visual .links .text { padding-top: 0.83333vw; } }

.os_win .main__works .main_section-title { line-height: 1; padding-top: 0.59524vw; }

@media (min-width: 1680px) { .os_win .main__works .main_section-title { padding-top: 10px; } }

@media (max-width: 1024px) { .os_win .main__works .main_section-title { padding-top: 0.97656vw; } }

@media (max-width: 768px) { .os_win .main__works .main_section-title { padding-top: 2.77778vw; } }

.os_win .main__contact .info li { padding: 2.08333vw 0vw 1.4881vw; }

@media (min-width: 1680px) { .os_win .main__contact .info li { padding: 35px 0px 25px; } }

@media (max-width: 1024px) { .os_win .main__contact .info li { padding: 3.41797vw 0vw 2.44141vw; } }

@media (max-width: 768px) { .os_win .main__contact .info li { padding: 5.55556vw 0vw 4.16667vw; } }

@media (max-width: 768px) and (max-width: 360px) { .os_win .main__contact .info li { padding: 5.55556vw 0vw 4.16667vw; } }

.effect { height: 100%; }

.effect body { background-color: transparent; overflow: hidden; height: 100%; }

.effect_box { width: 100%; height: 100%; position: relative; cursor: pointer; transition: background-color .3s ease; }

.effect_box span { position: absolute; background-color: #fff; transition: .3s ease-out 0s; }

.effect_box .line1, .effect_box .line3 { width: 0; height: 8px; }

.effect_box .line2, .effect_box .line4 { width: 8px; height: 0; }

.effect_box .line1 { left: 0; top: 0; }

.effect_box .line2 { right: 0; bottom: 0; }

.effect_box .line3 { right: 0; bottom: 0; }

.effect_box .line4 { top: 0; left: 0; }

.effect_box.shown { background-color: rgba(255, 255, 255, 0.1); }

.effect_box.shown .line1, .effect_box.shown .line3 { width: 100%; }

.effect_box.shown .line2, .effect_box.shown .line4 { height: 100%; }

.effect .ani { width: 50%; height: 50%; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); }

.works__wrap { font-family: oscine, Helvetica, "malgun gothic", "Apple SD Gothic Neo", "Microsoft NeoGothic", "Droid sans", arial, sans-serif; font-weight: bold; }

.works__title { font-size: 4rem; padding: 6.19048vw 0vw 3.33333vw; text-align: center; }

@media (min-width: 1680px) { .works__title { padding: 104px 0px 56px; } }

@media (max-width: 1024px) { .works__title { font-size: 2.625rem; padding: 7.22656vw 0vw 4.6875vw; } }

@media (max-width: 768px) { .works__title { font-size: 2.42857rem; padding: 8.33333vw 0vw 11.11111vw; } }

@media (max-width: 768px) and (max-width: 360px) { .works__title { padding: 8.33333vw 0vw 11.11111vw; } }

.works__tab { position: relative; margin-bottom: 9.52381vw; }

@media (min-width: 1680px) { .works__tab { margin-bottom: 160px; } }

@media (max-width: 1024px) { .works__tab { margin-bottom: 9.76563vw; } }

@media (max-width: 768px) { .works__tab { margin-bottom: 8.33333vw; } }

.works__tab .tab_selected { display: none; }

.works__tab_menu { position: relative; display: flex; justify-content: space-between; flex-wrap: wrap; border: 1px solid #000; width: 100%; border-radius: 1.78571vw; }

@media (min-width: 1680px) { .works__tab_menu { border-radius: 30px; } }

.works__tab_menu .tab { font-size: 0.88889rem; border-radius: 1.78571vw; font-family: "Noto Sans KR", Helvetica, "malgun gothic", "Apple SD Gothic Neo", "Microsoft NeoGothic", "Droid sans", sans-serif; width: calc(100% / 7); height: 3.57143vw; }

@media (min-width: 1680px) { .works__tab_menu .tab { border-radius: 30px; } }

@media (min-width: 1680px) { .works__tab_menu .tab { height: 60px; } }

.works__tab_menu .tab a { font-weight: 500; color: #000; width: 100%; height: 100%; display: flex; justify-content: center; align-items: center; }

.works__tab_menu .is-active { background-color: #000; }

.works__tab_menu .is-active a { color: #fff; }

@media (max-width: 1024px) { .works__tab_menu { border-radius: 2.44141vw; }
  .works__tab_menu .tab { font-size: 1rem; height: 4.88281vw; border-radius: 2.44141vw; }
  .works__tab_menu .tab a { font-weight: 400; } }

@media (max-width: 768px) { .works__tab .tab_selected { display: block; font-size: 1.14286rem; height: 12.22222vw; padding: 0vw 11.11111vw 0vw 3.33333vw; border-radius: 0.83333vw; border: 1px solid #555; width: 100%; position: relative; text-align: left; z-index: 2; } }

@media (max-width: 768px) and (max-width: 360px) { .works__tab .tab_selected { padding: 0vw 11.11111vw 0vw 3.33333vw; } }

@media (max-width: 768px) { .works__tab .tab_selected:before { content: ''; background: url(../images/works_arrow_open.svg) no-repeat center/100% auto; width: 5.55556vw; height: 5.55556vw; position: absolute; top: 50%; right: 3.33333vw; transform: translateY(-50%); transition: transform .2s ease; }
  .works__tab_menu { width: 0; height: auto; border: 0; position: absolute; top: calc(100% - 1px); left: 0; overflow: hidden; transform: translateY(-30px); transition: transform .3s ease; z-index: 1; }
  .works__tab_menu .tab { font-family: "Noto Sans KR", Helvetica, "malgun gothic", "Apple SD Gothic Neo", "Microsoft NeoGothic", "Droid sans", sans-serif; width: 100%; font-size: 1.14286rem; display: block; height: auto; }
  .works__tab_menu .tab a { display: block; font-weight: 400; color: #000; width: 100%; }
  .works__tab_menu .is-active { background-color: rgba(0, 0, 0, 0); display: flex; justify-content: flex-start; border-radius: 0; }
  .works__tab_menu .is-active a { color: #000; width: 100%; }
  .works__tab.active .tab_selected { border-radius: 0.83333vw 0.83333vw 0vw 0vw; background-color: #fff; } }

@media (max-width: 768px) and (max-width: 360px) { .works__tab.active .tab_selected { border-radius: 0.83333vw 0.83333vw 0vw 0vw; } }

@media (max-width: 768px) { .works__tab.active .tab_selected::before { transform: translateY(-50%) scaleY(-1); }
  .works__tab.active .works__tab_menu { width: 100%; border: 1px solid #555; background-color: #fff; overflow: inherit; display: flex; flex-direction: column; transform: translateY(0); border-radius: 0vw 0vw 0.83333vw 0.83333vw; } }

@media (max-width: 768px) and (max-width: 360px) { .works__tab.active .works__tab_menu { border-radius: 0vw 0vw 0.83333vw 0.83333vw; } }

@media (max-width: 768px) { .works__tab.active .works__tab_menu a { padding: 2.77778vw 3.33333vw; width: 100%; } }

@media (max-width: 768px) and (max-width: 360px) { .works__tab.active .works__tab_menu a { padding: 2.77778vw 3.33333vw; } }

@media (max-width: 768px) { .works__tab.active .works__tab_menu .is-active { display: none; } }

.works__list { display: flex; flex-wrap: wrap; margin-right: -5vw; }

@media (min-width: 1680px) { .works__list { margin-right: -84px; } }

.works__list-item { background-color: #fff; position: relative; width: calc(33.3% - 84px); margin-bottom: 5.95238vw; margin-right: 5vw; display: flex; flex-direction: column; opacity: 0; transform: translateY(50px); transition: .3s ease; transition-property: opacity, transform; }

@media (min-width: 1680px) { .works__list-item { margin-bottom: 100px; } }

@media (min-width: 1680px) { .works__list-item { margin-right: 84px; } }

.works__list-item:nth-of-type(3n-1) { margin-top: -3.57143vw; margin-bottom: 9.52381vw; }

@media (min-width: 1680px) { .works__list-item:nth-of-type(3n-1) { margin-top: -60px; } }

@media (min-width: 1680px) { .works__list-item:nth-of-type(3n-1) { margin-bottom: 160px; } }

.works__list-item:before { content: ""; position: absolute; left: 0; top: 0; width: 100%; height: 100%; background-color: #fff; border: 1px solid #000; z-index: -1; box-sizing: border-box; }

.works__list-item .images { padding: 0.59524vw; }

@media (min-width: 1680px) { .works__list-item .images { padding: 10px; } }

.works__list-item .images img { width: 100%; }

@media (max-width: 1024px) { .works__list-item .images { padding: 0.78125vw; } }

@media (max-width: 768px) { .works__list-item .images { padding: 1.38889vw; } }

.works__list-item .link { color: #000; }

.works__list-item .content { padding: 0.59524vw 1.19048vw 1.19048vw 1.19048vw; height: 16.66667vw; }

@media (min-width: 1680px) { .works__list-item .content { padding: 10px 20px 20px 20px; } }

@media (min-width: 1680px) { .works__list-item .content { height: 280px; } }

.works__list-item .content .year { font-size: 1.11111rem; padding-bottom: 0.95238vw; line-height: 1; }

@media (min-width: 1680px) { .works__list-item .content .year { padding-bottom: 16px; } }

.works__list-item .content .title { font-size: 1.55556rem; display: -webkit-box; overflow: hidden; text-overflow: ellipsis; -webkit-box-orient: vertical; -webkit-line-clamp: 3; min-height: 7.5vw; margin-bottom: 1.78571vw; line-height: 1.5; }

@media (min-width: 1680px) { .works__list-item .content .title { min-height: 126px; } }

@media (min-width: 1680px) { .works__list-item .content .title { margin-bottom: 30px; } }

.works__list-item .content .category { font-size: 0.88889rem; margin-right: 5.35714vw; display: -webkit-box; overflow: hidden; text-overflow: ellipsis; -webkit-box-orient: vertical; -webkit-line-clamp: 2; }

@media (min-width: 1680px) { .works__list-item .content .category { margin-right: 90px; } }

.works__list-item .content .circle { position: absolute; right: 1.19048vw; bottom: 1.19048vw; }

@media (min-width: 1680px) { .works__list-item .content .circle { right: 20px; } }

@media (min-width: 1680px) { .works__list-item .content .circle { bottom: 20px; } }

.works__list-item .shadow::before, .works__list-item .shadow::after { content: ''; position: absolute; width: 100%; height: 100%; transition: 0.15s cubic-bezier(0.17, 0.67, 0.83, 0.67); border: 1px solid #000; box-sizing: border-box; }

.works__list-item .shadow::before { z-index: -2; left: 0; top: 0; background-color: #F2B218; }

.works__list-item .shadow::after { z-index: -3; left: 0; top: 0; background-color: #E57C01; }

.works__list-item:hover .shadow::before { top: 0.59524vw; left: 0.59524vw; }

@media (min-width: 1680px) { .works__list-item:hover .shadow::before { top: 10px; } }

@media (min-width: 1680px) { .works__list-item:hover .shadow::before { left: 10px; } }

.works__list-item:hover .shadow::after { top: 1.19048vw; left: 1.19048vw; }

@media (min-width: 1680px) { .works__list-item:hover .shadow::after { top: 20px; } }

@media (min-width: 1680px) { .works__list-item:hover .shadow::after { left: 20px; } }

.works__list-item:nth-child(1), .works__list-item:nth-child(2), .works__list-item:nth-child(3) { opacity: 1; transform: translateY(0); transition-duration: 0s; }

.works__list-item.on { opacity: 1; transform: translateY(0); }

@media (max-width: 1680px) { .works__list-item { width: calc(33.3% - 5vw); } }

@media (max-width: 1024px) { .works__list { margin-right: -4.58984vw; }
  .works__list-item { width: calc(33.3% - 4.5898vw); margin-bottom: 7.8125vw; margin-right: 4.58984vw; }
  .works__list-item:nth-of-type(3n-1) { margin-top: -3.90625vw; margin-bottom: 11.71875vw; }
  .works__list-item:hover .shadow::before { top: 0.78125vw; left: 0.78125vw; }
  .works__list-item:hover .shadow::after { top: 1.5625vw; left: 1.5625vw; }
  .works__list-item .content { padding: 0.78125vw 1.5625vw 1.5625vw 1.5625vw; height: 17.57813vw; }
  .works__list-item .content .year { font-size: 1rem; padding-bottom: 1.17188vw; }
  .works__list-item .content .title { font-size: 1.125rem; min-height: 8.00781vw; margin-bottom: 0.48828vw; }
  .works__list-item .content .category { font-size: 0.875rem; margin-right: 4.88281vw; }
  .works__list-item .content .circle { right: 1.5625vw; bottom: 1.5625vw; } }

@media (max-width: 768px) { .works__list { display: block; width: 100%; }
  .works__list-item { width: 100%; margin-bottom: 8.33333vw; }
  .works__list-item:nth-of-type(3n-1) { margin-top: 0; margin-bottom: 8.33333vw; }
  .works__list-item:hover .shadow::before { top: 2.22222vw; left: 2.22222vw; }
  .works__list-item:hover .shadow::after { top: 4.44444vw; left: 4.44444vw; }
  .works__list-item .content { padding: 1.38889vw 4.44444vw 2.77778vw 4.44444vw; height: auto; } }

@media (max-width: 768px) and (max-width: 360px) { .works__list-item .content { padding: 1.38889vw 4.44444vw 2.77778vw 4.44444vw; } }

@media (max-width: 768px) { .works__list-item .content .year { font-size: 1.14286rem; padding-bottom: 4.44444vw; }
  .works__list-item .content .title { font-size: 1.28571rem; min-height: 22.77778vw; margin-bottom: 5.55556vw; }
  .works__list-item .content .category { font-size: 1rem; margin-right: 27.77778vw; }
  .works__list-item .content .circle { right: 4.44444vw; bottom: 4.44444vw; }
  .works__list-item:nth-child(1) { opacity: 1; transition-duration: 0s; transform: translateY(0); }
  .works__list-item:nth-child(2), .works__list-item:nth-child(3) { opacity: 0; transform: translateY(50px); transition-duration: .3s; }
  .works__list-item.on { opacity: 1; transform: translateY(0); } }

.pagination { display: flex; justify-content: center; align-items: center; margin-bottom: 5.95238vw; }

@media (min-width: 1680px) { .pagination { margin-bottom: 100px; } }

.pagination li { margin-right: 0.95238vw; }

@media (min-width: 1680px) { .pagination li { margin-right: 16px; } }

.pagination li a { width: 1.54762vw; height: 1.54762vw; color: #000; font-size: 0.88889rem; line-height: 1; background: rgba(0, 0, 0, 0); border-radius: 50%; font-weight: 400; display: flex; justify-content: center; align-items: center; font-family: "Noto Sans KR", Helvetica, "malgun gothic", "Apple SD Gothic Neo", "Microsoft NeoGothic", "Droid sans", sans-serif; }

@media (min-width: 1680px) { .pagination li a { width: 26px; } }

@media (min-width: 1680px) { .pagination li a { height: 26px; } }

.pagination li.icon { width: 1.19048vw; height: 1.19048vw; }

@media (min-width: 1680px) { .pagination li.icon { width: 20px; } }

@media (min-width: 1680px) { .pagination li.icon { height: 20px; } }

.pagination li.prev { background: url(../images/works_arrow_left.svg) no-repeat center/100% auto; }

.pagination li.next { background: url(../images/works_arrow_right.svg) no-repeat center/100% auto; }

.pagination li.active { z-index: 1; border-radius: 50%; }

.pagination li.active a { background-color: #000; color: #fff; }

@media (max-width: 1024px) { .pagination { margin-bottom: 7.8125vw; }
  .pagination li { margin-right: 1.5625vw; }
  .pagination li a { width: 2.53906vw; height: 2.53906vw; display: flex; justify-content: center; align-items: center; }
  .pagination li.icon { width: 1.95313vw; height: 1.95313vw; }
  .pagination li.active { z-index: 1; border-radius: 50%; }
  .pagination li.active a { background-color: #000; color: #fff; } }

@media (max-width: 768px) { .pagination { padding: 5.55556vw 0vw 22.22222vw; } }

@media (max-width: 768px) and (max-width: 360px) { .pagination { padding: 5.55556vw 0vw 22.22222vw; } }

@media (max-width: 768px) { .pagination li { margin-right: 4.44444vw; }
  .pagination li:last-child { margin-right: 0; }
  .pagination li a { font-size: 1.14286rem; width: 7.22222vw; height: 7.22222vw; }
  .pagination li.icon { width: 5.55556vw; height: 5.55556vw; }
  .pagination li.active a { font-size: 1.14286rem; } }

.works__detail .page_move { margin: 5.17857vw 0vw 2.08333vw; }

@media (min-width: 1680px) { .works__detail .page_move { margin: 87px 0px 35px; } }

.works__detail .subpage_desc { width: 66.42857vw; }

@media (min-width: 1680px) { .works__detail .subpage_desc { width: 1116px; } }

.works__detail .info { display: flex; justify-content: space-between; align-items: flex-end; }

.works__detail .info_list { font-size: 1.11111rem; padding-right: 1.19048vw; }

@media (min-width: 1680px) { .works__detail .info_list { padding-right: 20px; } }

.works__detail .info_list li { padding-bottom: 1.19048vw; display: flex; }

@media (min-width: 1680px) { .works__detail .info_list li { padding-bottom: 20px; } }

.works__detail .info_list span { width: 8.33333vw; font-weight: bold; display: block; }

@media (min-width: 1680px) { .works__detail .info_list span { width: 140px; } }

.works__detail .info_list p { flex: 1; }

.works__detail .button.website { margin-bottom: 1.19048vw; }

@media (min-width: 1680px) { .works__detail .button.website { margin-bottom: 20px; } }

.works__detail .slider { margin-top: 5.35714vw; display: flex; justify-content: center; }

@media (min-width: 1680px) { .works__detail .slider { margin-top: 90px; } }

.works__detail .slider_wrap { width: 79.82%; height: auto; padding: 0.59524vw; margin-bottom: 8.33333vw; border: 1px solid #000; position: relative; }

@media (min-width: 1680px) { .works__detail .slider_wrap { padding: 10px; } }

@media (min-width: 1680px) { .works__detail .slider_wrap { margin-bottom: 140px; } }

.works__detail .slider .slick-arrow { position: absolute; top: 50%; }

.works__detail .slider .slick-arrow.slick-prev { transform: translate(-150%, -50%) scaleX(-1); right: 100%; }

.works__detail .slider .slick-arrow.slick-next { transform: translate(150%, -50%); left: 100%; }

.works__detail .slider .slick-dots { position: absolute; top: 100%; left: 0; padding-top: 1.78571vw; }

@media (min-width: 1680px) { .works__detail .slider .slick-dots { padding-top: 30px; } }

.works__detail .slider .slick-slide { font-size: 0; }

.works__detail .slider .image { width: 100%; height: auto; }

.works__detail .slider img { width: 100%; height: auto; }

@media (max-width: 1024px) { .works__detail .page_move { margin: 6.54297vw 0vw 3.41797vw; }
  .works__detail .subpage_desc { width: 65.82031vw; }
  .works__detail .info_list { font-size: 0.875rem; padding-right: 1.36719vw; }
  .works__detail .info_list li { padding-bottom: 1.95313vw; }
  .works__detail .info_list span { width: 7.8125vw; }
  .works__detail .button.website { margin-bottom: 1.95313vw; }
  .works__detail .slider { margin-top: 5.35714vw; } }

@media (max-width: 1024px) and (min-width: 1680px) { .works__detail .slider { margin-top: 90px; } }

@media (max-width: 1024px) { .works__detail .slider_wrap { width: 74%; padding: 0.78125vw; margin-bottom: 10.74219vw; }
  .works__detail .slider .slick-arrow.slick-prev { transform: translate(-75%, -50%) scaleX(-1); }
  .works__detail .slider .slick-arrow.slick-next { transform: translate(75%, -50%); }
  .works__detail .slider .slick-dots { padding-top: 1.95313vw; } }

@media (max-width: 768px) { .works__detail .fancy__wrap { max-width: initial; }
  .works__detail .page_move { margin: 10vw 0vw 8.05556vw; } }

@media (max-width: 768px) and (max-width: 360px) { .works__detail .page_move { margin: 10vw 0vw 8.05556vw; } }

@media (max-width: 768px) { .works__detail .subpage_desc { width: 100%; }
  .works__detail .info { display: block; }
  .works__detail .info_list { font-size: 1rem; padding-right: 0vw; }
  .works__detail .info_list li { padding-bottom: 5.55556vw; }
  .works__detail .info_list span { width: 19.44444vw; }
  .works__detail .button.website { margin: 2.77778vw 0vw 0vw; } }

@media (max-width: 768px) and (max-width: 360px) { .works__detail .button.website { margin: 2.77778vw 0vw 0vw; } }

@media (max-width: 768px) { .works__detail .slider { margin-top: 8.33333vw; }
  .works__detail .slider_wrap { width: 100%; padding: 2.22222vw; margin-bottom: 30.55556vw; }
  .works__detail .slider .slick-arrow { top: 100%; left: 50%; right: auto; margin-top: 6.38889vw; }
  .works__detail .slider .slick-arrow.slick-prev { transform: translate(-150%, 0%) scaleX(-1); }
  .works__detail .slider .slick-arrow.slick-next { transform: translate(50%, 0%); left: 50%; }
  .works__detail .slider .slick-dots { padding-top: 0; top: auto; bottom: 0%; padding-bottom: 4.44444vw; } }

.works__detail.vr_layer .contents { padding-left: 1.78571vw; padding-right: 1.78571vw; }

@media (min-width: 1680px) { .works__detail.vr_layer .contents { padding-left: 30px; } }

@media (min-width: 1680px) { .works__detail.vr_layer .contents { padding-right: 30px; } }

.works__detail.vr_layer .contents .subpage_title { padding: 1.78571vw 0vw 1.78571vw; font-size: 2.33333rem; }

@media (min-width: 1680px) { .works__detail.vr_layer .contents .subpage_title { padding: 30px 0px 30px; } }

.works__detail.vr_layer .contents .subpage_desc { width: 100%; font-size: 1rem; padding-bottom: 2.38095vw; }

@media (min-width: 1680px) { .works__detail.vr_layer .contents .subpage_desc { padding-bottom: 40px; } }

.works__detail.vr_layer .contents .info_list { font-size: 0.88889rem; }

.works__detail.vr_layer .contents .info_list li { padding-bottom: 1.07143vw; }

@media (min-width: 1680px) { .works__detail.vr_layer .contents .info_list li { padding-bottom: 18px; } }

.works__detail.vr_layer .contents .info_list span { width: 6.4881vw; }

@media (min-width: 1680px) { .works__detail.vr_layer .contents .info_list span { width: 109px; } }

.works__detail.vr_layer .contents .button.website { margin-bottom: 0.59524vw; }

@media (min-width: 1680px) { .works__detail.vr_layer .contents .button.website { margin-bottom: 10px; } }

.works__detail.vr_layer .contents .slider { margin-top: 2.38095vw; }

@media (min-width: 1680px) { .works__detail.vr_layer .contents .slider { margin-top: 40px; } }

.works__detail.vr_layer .contents .slider_wrap { width: 78%; padding: 0.47619vw; margin-bottom: 4.88095vw; }

@media (min-width: 1680px) { .works__detail.vr_layer .contents .slider_wrap { padding: 8px; } }

@media (min-width: 1680px) { .works__detail.vr_layer .contents .slider_wrap { margin-bottom: 82px; } }

.works__detail.vr_layer .contents .slider .slick-arrow.slick-prev { transform: translate(-75%, -50%) scaleX(-1); }

.works__detail.vr_layer .contents .slider .slick-arrow.slick-next { transform: translate(75%, -50%); }

.works__detail.vr_layer .contents .slider .slick-dots { padding-top: 1.19048vw; }

@media (min-width: 1680px) { .works__detail.vr_layer .contents .slider .slick-dots { padding-top: 20px; } }

.works__detail.vr_layer .contents .button_wrap { text-align: center; padding-bottom: 2.97619vw; }

@media (min-width: 1680px) { .works__detail.vr_layer .contents .button_wrap { padding-bottom: 50px; } }

@media (max-width: 1024px) { .works__detail.vr_layer .contents { padding-left: 1.95313vw; padding-right: 1.95313vw; }
  .works__detail.vr_layer .contents .subpage_title { padding: 2.92969vw 0vw 2.53906vw; font-size: 2.125rem; }
  .works__detail.vr_layer .contents .subpage_desc { font-size: 1rem; padding-bottom: 4.88281vw; }
  .works__detail.vr_layer .contents .info_list { font-size: 0.875rem; }
  .works__detail.vr_layer .contents .info_list li { padding-bottom: 1.95313vw; }
  .works__detail.vr_layer .contents .info_list span { width: 9.27734vw; }
  .works__detail.vr_layer .contents .slider_wrap { margin-bottom: 7.8125vw; } }

@media (max-width: 768px) { .works__detail.vr_layer .contents { padding-left: 5.55556vw; padding-right: 5.55556vw; }
  .works__detail.vr_layer .contents .subpage_title { padding: 8.33333vw 0vw 6.66667vw; font-size: 2rem; } }

@media (max-width: 768px) and (max-width: 360px) { .works__detail.vr_layer .contents .subpage_title { padding: 8.33333vw 0vw 6.66667vw; } }

@media (max-width: 768px) { .works__detail.vr_layer .contents .subpage_desc { font-size: 0.85714rem; padding-bottom: 8.33333vw; }
  .works__detail.vr_layer .contents .info_list { font-size: 0.85714rem; }
  .works__detail.vr_layer .contents .info_list li { padding-bottom: 5.55556vw; }
  .works__detail.vr_layer .contents .info_list span { width: 19.44444vw; }
  .works__detail.vr_layer .contents .slider_wrap { margin-bottom: 13.88889vw; }
  .works__detail.vr_layer .contents .button.website { margin-bottom: 8.33333vw; }
  .works__detail.vr_layer .contents .slider { margin-top: 0; }
  .works__detail.vr_layer .contents .slider_wrap { width: 100%; padding: 2.22222vw; margin-bottom: 27.22222vw; }
  .works__detail.vr_layer .contents .slider .slick-arrow { top: 100%; left: 50%; right: auto; margin-top: 6.38889vw; }
  .works__detail.vr_layer .contents .slider .slick-arrow.slick-prev { transform: translate(-150%, 0%) scaleX(-1); }
  .works__detail.vr_layer .contents .slider .slick-arrow.slick-next { transform: translate(50%, 0%); left: 50%; }
  .works__detail.vr_layer .contents .slider .slick-dots { padding-top: 0; top: auto; bottom: 0%; padding-bottom: 4.44444vw; }
  .works__detail.vr_layer .contents .button_wrap { text-align: center; padding-bottom: 13.88889vw; } }

.about__title { font-family: oscine, Helvetica, "malgun gothic", "Apple SD Gothic Neo", "Microsoft NeoGothic", "Droid sans", arial, sans-serif; font-weight: bold; padding: 2.97619vw 0vw 2.7381vw; font-size: 3.22222rem; line-height: 1; }

@media (min-width: 1680px) { .about__title { padding: 50px 0px 46px; } }

@media (max-width: 1024px) { .about__title { font-size: 2.125rem; padding: 3.90625vw 0vw 2.92969vw; } }

@media (max-width: 768px) { .about__title { font-size: 2.42857rem; padding: 8.33333vw 0vw; } }

@media (max-width: 768px) and (max-width: 360px) { .about__title { padding: 8.33333vw 0vw; } }

.about__intro { padding-top: 4.7619vw; padding-bottom: 5.95238vw; }

@media (min-width: 1680px) { .about__intro { padding-top: 80px; } }

@media (min-width: 1680px) { .about__intro { padding-bottom: 100px; } }

.about__intro .intro { position: relative; }

.about__intro .intro img { width: 100%; }

.about__intro .text { position: absolute; left: 0; top: 0; width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; color: #fff; flex-direction: column; }

.about__intro .text .main > div, .about__intro .text .sub > div { display: inline-block; }

.about__intro .text .main { font-size: 3.22222rem; font-family: oscine, Helvetica, "malgun gothic", "Apple SD Gothic Neo", "Microsoft NeoGothic", "Droid sans", arial, sans-serif; font-weight: bold; }

.about__intro .text .main > div { padding-right: 0.71429vw; }

@media (min-width: 1680px) { .about__intro .text .main > div { padding-right: 12px; } }

.about__intro .text .sub { padding-top: 1.78571vw; font-size: 1.11111rem; }

@media (min-width: 1680px) { .about__intro .text .sub { padding-top: 30px; } }

.about__intro .text .sub > div { padding-right: 0.29762vw; }

@media (min-width: 1680px) { .about__intro .text .sub > div { padding-right: 5px; } }

.about__intro.hidden .intro .text { opacity: 0; }

@media (max-width: 1024px) { .about__intro { padding-top: 4.88281vw; padding-bottom: 4.88281vw; }
  .about__intro .text .main { font-size: 2.125rem; }
  .about__intro .text .main > div { display: inline-block; padding-right: 1.17188vw; }
  .about__intro .text .sub { padding-top: 1.95313vw; font-size: 1rem; }
  .about__intro .text .sub > div { display: inline-block; padding-right: 0.48828vw; } }

@media (max-width: 768px) { .about__intro { padding-top: 0; padding-bottom: 13.88889vw; }
  .about__intro .intro { height: 60vw; }
  .about__intro .intro img { width: auto; height: 100%; position: absolute; left: 50%; top: 0; transform: translateX(-50%); }
  .about__intro .text .main { font-size: 2rem; text-align: center; }
  .about__intro .text .main > div { padding-right: 0; }
  .about__intro .text .sub { padding-top: 5.55556vw; font-size: 0.85714rem; text-align: center; }
  .about__intro .text .sub > div { padding-right: 0; } }

.about__info { border-bottom: 1px solid #555; padding-bottom: 5.95238vw; }

@media (min-width: 1680px) { .about__info { padding-bottom: 100px; } }

@media (max-width: 1024px) { .about__info { padding-bottom: 7.8125vw; } }

@media (max-width: 768px) { .about__info { padding-bottom: 0; } }

.about__info .information { display: flex; }

.about__info .information .images { border: 1px solid #000; width: 32.97619vw; margin-right: 2.97619vw; }

@media (min-width: 1680px) { .about__info .information .images { width: 554px; } }

@media (min-width: 1680px) { .about__info .information .images { margin-right: 50px; } }

.about__info .information .images img { width: 100%; }

.about__info .information .desc { width: 39.58333vw; }

@media (min-width: 1680px) { .about__info .information .desc { width: 665px; } }

.about__info .information .desc .title { font-family: oscine, Helvetica, "malgun gothic", "Apple SD Gothic Neo", "Microsoft NeoGothic", "Droid sans", arial, sans-serif; font-weight: bold; font-size: 2.33333rem; }

.about__info .information .desc .strong { font-size: 1.11111rem; padding: 1.19048vw 0vw 2.38095vw; font-weight: bold; }

@media (min-width: 1680px) { .about__info .information .desc .strong { padding: 20px 0px 40px; } }

.about__info .information .desc .text { font-size: 1rem; }

@media (max-width: 1024px) { .about__info .information .images { width: 32.32422vw; margin-right: 1.95313vw; }
  .about__info .information .desc { width: 41.01563vw; }
  .about__info .information .desc .title { font-size: 1.75rem; }
  .about__info .information .desc .strong { font-size: 1rem; padding: 1.46484vw 0vw 2.92969vw; }
  .about__info .information .desc .text { font-size: 0.875rem; } }

@media (max-width: 768px) { .about__info .information { display: block; }
  .about__info .information .images { width: 67.22222vw; margin-bottom: 5.55556vw; margin-right: 0; }
  .about__info .information .desc { width: 100%; }
  .about__info .information .desc .title { font-size: 1.85714rem; }
  .about__info .information .desc .strong { font-size: 1rem; padding: 4.16667vw 0vw 5.55556vw; } }

@media (max-width: 768px) and (max-width: 360px) { .about__info .information .desc .strong { padding: 4.16667vw 0vw 5.55556vw; } }

@media (max-width: 768px) { .about__info .information .desc .text { font-size: 0.85714rem; } }

.about__info .box { margin-top: 4.7619vw; border: 1px solid #555; }

@media (min-width: 1680px) { .about__info .box { margin-top: 80px; } }

.about__info .box-top { width: 100%; font-size: 1.11111rem; height: 4.16667vw; border-bottom: 1px solid #555; display: flex; align-items: center; }

@media (min-width: 1680px) { .about__info .box-top { height: 70px; } }

.about__info .box-top .icon { width: 4.16667vw; height: 100%; display: block; border-right: 1px solid #555; position: relative; }

@media (min-width: 1680px) { .about__info .box-top .icon { width: 70px; } }

.about__info .box-top .icon:before { content: ""; width: 2.85714vw; height: 2.85714vw; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); background: no-repeat center/100% auto; }

@media (min-width: 1680px) { .about__info .box-top .icon:before { width: 48px; } }

@media (min-width: 1680px) { .about__info .box-top .icon:before { height: 48px; } }

.about__info .box-top .title { padding: 0vw 1.19048vw; }

@media (min-width: 1680px) { .about__info .box-top .title { padding: 0px 20px; } }

@media (max-width: 1024px) { .about__info .box { margin-top: 5.85938vw; }
  .about__info .box-top { font-size: 1rem; height: 6.25vw; }
  .about__info .box-top .icon { width: 6.25vw; }
  .about__info .box-top .icon:before { width: 4.6875vw; height: 4.6875vw; }
  .about__info .box-top .title { padding: 0vw 1.95313vw; } }

@media (max-width: 768px) { .about__info .box { margin-top: 13.88889vw; margin-left: -5.83333vw; margin-right: -5.83333vw; border: 0; }
  .about__info .box-top { font-size: 1rem; height: 17.22222vw; padding-right: 5.55556vw; border-top: 1px solid #555; }
  .about__info .box-top .icon { width: 14.44444vw; flex: 0 0 auto; }
  .about__info .box-top .icon:before { width: 13.33333vw; height: 13.33333vw; }
  .about__info .box-top .title { padding: 0vw 5.55556vw; } }

@media (max-width: 768px) and (max-width: 360px) { .about__info .box-top .title { padding: 0vw 5.55556vw; } }

.about__info .history .box-top .icon:before { background-image: url(../images/about_info_history.svg); }

.about__info .history .graph { position: relative; overflow: hidden; height: 15.47619vw; padding-left: 5.35714vw; padding-top: 4.7619vw; }

@media (min-width: 1680px) { .about__info .history .graph { height: 260px; } }

@media (min-width: 1680px) { .about__info .history .graph { padding-left: 90px; } }

@media (min-width: 1680px) { .about__info .history .graph { padding-top: 80px; } }

.about__info .history .graph ul { display: flex; position: relative; width: 100%; padding-right: 6.66667vw; }

@media (min-width: 1680px) { .about__info .history .graph ul { padding-right: 112px; } }

.about__info .history .graph ul:before { content: ""; width: 0; height: 1px; background-color: #888; position: absolute; left: 0; top: 0; transition: width .8s ease; }

.about__info .history .graph li { position: relative; opacity: 0; transition: opacity .5s ease; }

.about__info .history .graph li:nth-child(1) { width: 11.42857vw; }

@media (min-width: 1680px) { .about__info .history .graph li:nth-child(1) { width: 192px; } }

.about__info .history .graph li:nth-child(1):before { background-color: #EB9248; z-index: 2; }

.about__info .history .graph li:nth-child(1):after { content: ""; position: absolute; left: -0.29762vw; top: -0.53571vw; width: 0.95238vw; height: 0.95238vw; border-radius: 50%; border: 1px solid #EB9248; background-color: #fff; z-index: 1; }

@media (min-width: 1680px) { .about__info .history .graph li:nth-child(1):after { left: -5px; } }

@media (min-width: 1680px) { .about__info .history .graph li:nth-child(1):after { top: -9px; } }

@media (min-width: 1680px) { .about__info .history .graph li:nth-child(1):after { width: 16px; } }

@media (min-width: 1680px) { .about__info .history .graph li:nth-child(1):after { height: 16px; } }

.about__info .history .graph li:nth-child(2) { width: 24.7619vw; transition-delay: 0.25s; }

@media (min-width: 1680px) { .about__info .history .graph li:nth-child(2) { width: 416px; } }

.about__info .history .graph li:nth-child(3) { width: 24.7619vw; transition-delay: 0.5s; }

@media (min-width: 1680px) { .about__info .history .graph li:nth-child(3) { width: 416px; } }

.about__info .history .graph li:nth-child(4) { transition-delay: 0.75s; }

.about__info .history .graph li:before { content: ""; position: absolute; left: 0; top: -0.2381vw; width: 0.47619vw; height: 0.47619vw; background-color: #888; border-radius: 50%; }

@media (min-width: 1680px) { .about__info .history .graph li:before { top: -4px; } }

@media (min-width: 1680px) { .about__info .history .graph li:before { width: 8px; } }

@media (min-width: 1680px) { .about__info .history .graph li:before { height: 8px; } }

.about__info .history .graph li .date { padding: 1.07143vw 0vw 0.29762vw; font-size: 0.77778rem; font-weight: bold; }

@media (min-width: 1680px) { .about__info .history .graph li .date { padding: 18px 0px 5px; } }

.about__info .history .graph li .text { font-size: 0.88889rem; }

.about__info .history .graph.on ul:before { width: 100%; }

.about__info .history .graph.on li { opacity: 1; }

@media (max-width: 1024px) { .about__info .history .graph { height: 18.94531vw; padding-left: 7.8125vw; padding-top: 5.66406vw; }
  .about__info .history .graph ul { padding-right: 5.46875vw; }
  .about__info .history .graph li:nth-child(1) { width: 14.0625vw; }
  .about__info .history .graph li:nth-child(1):after { left: -0.48828vw; top: -0.87891vw; width: 1.5625vw; height: 1.5625vw; }
  .about__info .history .graph li:nth-child(2), .about__info .history .graph li:nth-child(3) { width: 22.46094vw; }
  .about__info .history .graph li:nth-child(4) { width: 14.64844vw; }
  .about__info .history .graph li:before { top: -0.39063vw; width: 0.78125vw; height: 0.78125vw; }
  .about__info .history .graph li .date { padding: 1.75781vw 0vw 0.48828vw; font-size: 0.75rem; }
  .about__info .history .graph li .text { font-size: 0.875rem; } }

@media (max-width: 768px) { .about__info .history .graph { height: auto; padding-left: 11.11111vw; padding-top: 13.88889vw; }
  .about__info .history .graph ul { flex-wrap: wrap; padding-right: 0; }
  .about__info .history .graph ul:before { transition: width .4s ease; }
  .about__info .history .graph li { height: 25.55556vw; }
  .about__info .history .graph li:nth-child(1) { width: 42%; }
  .about__info .history .graph li:nth-child(1):after { left: -1.38889vw; top: -2.5vw; width: 4.44444vw; height: 4.44444vw; }
  .about__info .history .graph li:nth-child(2) { width: 58%; }
  .about__info .history .graph li:nth-child(3) { width: 42%; }
  .about__info .history .graph li:nth-child(3):after { content: ""; width: 0; position: absolute; top: 0; left: -11.11111vw; background-color: #888; height: 1px; transition: width .4s ease .4s; }
  .about__info .history .graph li:nth-child(4) { width: 58%; }
  .about__info .history .graph li:before { top: -1.11111vw; width: 2.22222vw; height: 2.22222vw; }
  .about__info .history .graph li .date { padding: 5vw 0vw 1.38889vw; font-size: 0.85714rem; } }

@media (max-width: 768px) and (max-width: 360px) { .about__info .history .graph li .date { padding: 5vw 0vw 1.38889vw; } }

@media (max-width: 768px) { .about__info .history .graph li .text { font-size: 1rem; }
  .about__info .history .graph.on li:nth-child(3):after { width: 100vw; } }

.about__info .patent .box-top .icon:before { background-image: url(../images/about_info_patent.svg); }

.about__info .patent .content { display: flex; align-items: center; justify-content: center; padding: 4.16667vw 0vw 5.35714vw; }

@media (min-width: 1680px) { .about__info .patent .content { padding: 70px 0px 90px; } }

.about__info .patent .content .item { width: 16.07143vw; height: 11.90476vw; margin: 0vw 1.42857vw; position: relative; transform: translateY(30px); opacity: 0; transition: transform .5s ease, opacity .3s ease; }

@media (min-width: 1680px) { .about__info .patent .content .item { width: 270px; } }

@media (min-width: 1680px) { .about__info .patent .content .item { height: 200px; } }

@media (min-width: 1680px) { .about__info .patent .content .item { margin: 0px 24px; } }

.about__info .patent .content .item:before { content: ""; z-index: -1; background-color: #fff; border: 1px solid #555; width: 100%; height: 100%; position: absolute; left: 0; top: 0; }

.about__info .patent .content .item:after { content: ""; width: 100%; height: 100%; position: absolute; left: 0.35714vw; top: 0.35714vw; z-index: -2; border: 1px solid #555; background-color: #aaa; }

@media (min-width: 1680px) { .about__info .patent .content .item:after { left: 6px; } }

@media (min-width: 1680px) { .about__info .patent .content .item:after { top: 6px; } }

.about__info .patent .content .item .number { padding: 0vw 1.19048vw; height: 2.20238vw; font-size: 0.66667rem; border-bottom: 1px solid #555; display: flex; align-items: center; }

@media (min-width: 1680px) { .about__info .patent .content .item .number { padding: 0px 20px; } }

@media (min-width: 1680px) { .about__info .patent .content .item .number { height: 37px; } }

.about__info .patent .content .item .name { padding: 1.19048vw; min-height: 7.38095vw; }

@media (min-width: 1680px) { .about__info .patent .content .item .name { padding: 20px; } }

@media (min-width: 1680px) { .about__info .patent .content .item .name { min-height: 124px; } }

.about__info .patent .content .item .date { color: #888; font-size: 0.66667rem; padding: 0vw 1.19048vw 1.19048vw; }

@media (min-width: 1680px) { .about__info .patent .content .item .date { padding: 0px 20px 20px; } }

.about__info .patent .content .item:nth-child(2) { transition-delay: .2s; }

.about__info .patent .content .item:nth-child(3) { transition-delay: .4s; }

.about__info .patent .content .item:nth-child(4) { transition-delay: .6s; }

.about__info .patent .content .item.on { transform: translateY(0); opacity: 1; }

@media (max-width: 1024px) { .about__info .patent .content { padding: 4.88281vw 0vw 6.44531vw; }
  .about__info .patent .content .item { width: 17.57813vw; height: 14.16016vw; margin: 0vw 1.5625vw; }
  .about__info .patent .content .item:after { left: 0.58594vw; top: 0.58594vw; }
  .about__info .patent .content .item .number { padding: 0vw 0.97656vw; height: 3.125vw; font-size: 0.75rem; }
  .about__info .patent .content .item .name { padding: 0.97656vw 0.97656vw 0vw; min-height: 8.30078vw; font-size: 0.875rem; }
  .about__info .patent .content .item .date { font-size: 0.75rem; padding: 0vw 0.97656vw 0.97656vw; } }

@media (max-width: 768px) { .about__info .patent .content { display: block; padding: 8.33333vw 8.33333vw 3.88889vw; } }

@media (max-width: 768px) and (max-width: 360px) { .about__info .patent .content { padding: 8.33333vw 8.33333vw 3.88889vw; } }

@media (max-width: 768px) { .about__info .patent .content .item { width: 100%; height: 40.27778vw; margin: 0vw 0vw 10vw; } }

@media (max-width: 768px) and (max-width: 360px) { .about__info .patent .content .item { margin: 0vw 0vw 10vw; } }

@media (max-width: 768px) { .about__info .patent .content .item:after { left: 1.66667vw; top: 1.66667vw; }
  .about__info .patent .content .item .number { padding: 0vw 2.77778vw; height: 8.88889vw; font-size: 0.85714rem; } }

@media (max-width: 768px) and (max-width: 360px) { .about__info .patent .content .item .number { padding: 0vw 2.77778vw; } }

@media (max-width: 768px) { .about__info .patent .content .item .name { padding: 2.77778vw 2.77778vw 0vw; min-height: 22.77778vw; font-size: 1rem; } }

@media (max-width: 768px) and (max-width: 360px) { .about__info .patent .content .item .name { padding: 2.77778vw 2.77778vw 0vw; } }

@media (max-width: 768px) { .about__info .patent .content .item .date { font-size: 0.85714rem; padding: 0vw 2.77778vw 2.77778vw; } }

@media (max-width: 768px) and (max-width: 360px) { .about__info .patent .content .item .date { padding: 0vw 2.77778vw 2.77778vw; } }

@media (max-width: 768px) { .about__info .patent .content .item:nth-child(2), .about__info .patent .content .item:nth-child(3) { transition-delay: 0s; } }

.about__info.hidden .fancy__wrap { opacity: 0; }

.about__strength { border-bottom: 1px solid #555; padding-bottom: 5.95238vw; }

@media (min-width: 1680px) { .about__strength { padding-bottom: 100px; } }

.about__strength .info { padding-bottom: 3.57143vw; }

@media (min-width: 1680px) { .about__strength .info { padding-bottom: 60px; } }

@media (max-width: 1024px) { .about__strength { padding-bottom: 7.8125vw; }
  .about__strength .info { padding-bottom: 4.88281vw; } }

@media (max-width: 768px) { .about__strength { padding-bottom: 0; border-bottom: 0; }
  .about__strength .info { padding-bottom: 13.88889vw; } }

.about__strength-detail .item { border-top: 1px solid #555; }

.about__strength-detail .item .title { position: relative; width: 100%; height: 5.71429vw; display: flex; align-items: center; font-family: oscine, Helvetica, "malgun gothic", "Apple SD Gothic Neo", "Microsoft NeoGothic", "Droid sans", arial, sans-serif; font-weight: bold; font-size: 1.55556rem; color: #000; }

@media (min-width: 1680px) { .about__strength-detail .item .title { height: 96px; } }

.about__strength-detail .item .title:before, .about__strength-detail .item .title:after { content: ""; background-color: #555; width: 1.78571vw; height: 1px; transform-origin: 50% 50%; position: absolute; top: 50%; right: 0; }

@media (min-width: 1680px) { .about__strength-detail .item .title:before, .about__strength-detail .item .title:after { width: 30px; } }

.about__strength-detail .item .title:after { transition: transform .2s ease; transform: rotate(90deg); }

.about__strength-detail .item .point { display: inline-flex; }

.about__strength-detail .item .point > span { position: relative; z-index: 1; min-width: 0.47619vw; }

@media (min-width: 1680px) { .about__strength-detail .item .point > span { min-width: 8px; } }

.about__strength-detail .item .point .line { content: ""; width: 0; height: 0.59524vw; position: absolute; left: 0; top: 55%; z-index: -1; transition: width .03s ease; }

@media (min-width: 1680px) { .about__strength-detail .item .point .line { height: 10px; } }

.about__strength-detail .item.is-hidden .content { width: 0; height: 0; overflow: hidden; }

.about__strength-detail .item.is-active .title:after { transform: rotate(0); }

.about__strength-detail .item.is-active .content { position: relative; border: solid #555; border-width: 1px 1px 0; font-size: 1.11111rem; padding: 1.78571vw; }

@media (min-width: 1680px) { .about__strength-detail .item.is-active .content { padding: 30px; } }

.about__strength-detail .item.is-active .content:before { content: ""; width: 100%; height: 100%; background-color: #f8f8f8; position: absolute; left: 0; top: 0; z-index: -1; }

.about__strength-detail .item.is-active .point .line { width: 100%; transition-duration: 0s; }

@media (max-width: 1024px) { .about__strength-detail .item .title { height: 7.03125vw; font-size: 1.5rem; }
  .about__strength-detail .item .title:before, .about__strength-detail .item .title:after { width: 2.53906vw; }
  .about__strength-detail .item .point > span { min-width: 0.39063vw; }
  .about__strength-detail .item .point .line { height: 0.58594vw; }
  .about__strength-detail .item.is-active .content { font-size: 1rem; padding: 1.95313vw; } }

@media (max-width: 768px) { .about__strength-detail .item { margin-left: -5.55556vw; margin-right: -5.55556vw; }
  .about__strength-detail .item .title { padding: 0vw 5.55556vw; height: 16.11111vw; font-size: 1.42857rem; } }

@media (max-width: 768px) and (max-width: 360px) { .about__strength-detail .item .title { padding: 0vw 5.55556vw; } }

@media (max-width: 768px) { .about__strength-detail .item .title:before, .about__strength-detail .item .title:after { width: 5.55556vw; right: 5.55556vw; }
  .about__strength-detail .item .point > span { min-width: 0.83333vw; }
  .about__strength-detail .item .point .line { height: 1.66667vw; }
  .about__strength-detail .item.is-active .content { font-size: 1rem; padding: 5.55556vw; border-left: 0; border-right: 0; } }

.about__strength-detail .item:nth-child(1) .point .line { background-color: #B9E2DA; }

.about__strength-detail .item:nth-child(2) .point .line { background-color: #E9F29E; }

.about__strength-detail .item:nth-child(3) .point .line { background-color: #FACE87; }

.about__strength-detail .item:nth-child(4) { border-bottom: 1px solid #555; }

.about__strength-detail .item:nth-child(4) .point .line { background-color: #C9B5E3; }

.about__solutions { border-bottom: 1px solid #555; padding-bottom: 5.95238vw; }

@media (min-width: 1680px) { .about__solutions { padding-bottom: 100px; } }

.about__solutions .sub_title { padding-bottom: 0.59524vw; }

@media (min-width: 1680px) { .about__solutions .sub_title { padding-bottom: 10px; } }

@media (max-width: 1024px) { .about__solutions { padding-bottom: 7.8125vw; }
  .about__solutions .sub_title { padding-bottom: 0; } }

@media (max-width: 768px) { .about__solutions { padding-bottom: 13.88889vw; } }

.about__solutions-detail .item { margin-top: 2.97619vw; width: 58.03571vw; border: 1px solid #555; display: flex; opacity: 0; transform: translateX(30%); transition: transform .5s ease, opacity .3s ease; }

@media (min-width: 1680px) { .about__solutions-detail .item { margin-top: 50px; } }

@media (min-width: 1680px) { .about__solutions-detail .item { width: 975px; } }

.about__solutions-detail .item:nth-child(2n) { margin-left: auto; transform: translateX(-30%); }

.about__solutions-detail .item .images { padding: 0.59524vw; width: 19.64286vw; flex: 0 0 auto; }

@media (min-width: 1680px) { .about__solutions-detail .item .images { padding: 10px; } }

@media (min-width: 1680px) { .about__solutions-detail .item .images { width: 330px; } }

.about__solutions-detail .item .images img { width: 100%; }

.about__solutions-detail .item .content { border-left: 1px solid #555; }

.about__solutions-detail .item .content .title { padding: 0vw 1.4881vw; height: 5vw; display: flex; align-items: center; border-bottom: 1px solid #555; font-family: oscine, Helvetica, "malgun gothic", "Apple SD Gothic Neo", "Microsoft NeoGothic", "Droid sans", arial, sans-serif; font-weight: bold; font-size: 1.88889rem; }

@media (min-width: 1680px) { .about__solutions-detail .item .content .title { padding: 0px 25px; } }

@media (min-width: 1680px) { .about__solutions-detail .item .content .title { height: 84px; } }

.about__solutions-detail .item .content .desc { padding: 1.19048vw 1.4881vw; font-size: 0.88889rem; }

@media (min-width: 1680px) { .about__solutions-detail .item .content .desc { padding: 20px 25px; } }

.about__solutions-detail .item.on { transform: translateX(0); opacity: 1; }

.about__solutions-detail .item.on:nth-child(2n) { transform: translateX(0); }

@media (max-width: 1024px) { .about__solutions-detail .item { margin-top: 4.88281vw; width: 65.82031vw; }
  .about__solutions-detail .item:first-child { margin-top: 2.53906vw; }
  .about__solutions-detail .item .images { padding: 0.78125vw; width: 23.04688vw; }
  .about__solutions-detail .item .content .title { padding: 0vw 1.95313vw; height: 5.37109vw; font-size: 1.5rem; }
  .about__solutions-detail .item .content .desc { padding: 1.95313vw 1.95313vw; font-size: 0.875rem; } }

@media (max-width: 768px) { .about__solutions-detail .item { margin-top: 8.33333vw; width: 73.88889vw; display: block; transform: translateY(50px); }
  .about__solutions-detail .item:first-child { margin-top: 5vw; }
  .about__solutions-detail .item:nth-child(2n) { transform: translateY(50px); }
  .about__solutions-detail .item .images { padding: 2.22222vw; width: 100%; }
  .about__solutions-detail .item .content { border-left: 0; border-top: 1px solid #555; }
  .about__solutions-detail .item .content .title { padding: 2.22222vw 4.16667vw; height: auto; font-size: 1.42857rem; } }

@media (max-width: 768px) and (max-width: 360px) { .about__solutions-detail .item .content .title { padding: 2.22222vw 4.16667vw; } }

@media (max-width: 768px) { .about__solutions-detail .item .content .desc { padding: 4.16667vw; font-size: 1rem; }
  .about__solutions-detail .item.on { transform: translateY(0); }
  .about__solutions-detail .item.on:nth-child(2n) { transform: translateY(0); } }

.about__clients { padding-bottom: 4.7619vw; }

@media (min-width: 1680px) { .about__clients { padding-bottom: 80px; } }

.about__clients .sub_title { padding-bottom: 3.57143vw; }

@media (min-width: 1680px) { .about__clients .sub_title { padding-bottom: 60px; } }

@media (max-width: 1024px) { .about__clients { padding-bottom: 4.88281vw; }
  .about__clients .sub_title { padding-bottom: 4.88281vw; } }

@media (max-width: 768px) { .about__clients { padding-bottom: 13.88889vw; }
  .about__clients .sub_title { padding-bottom: 8.33333vw; } }

.about__clients-detail { border: 1px solid #555; padding: 2.97619vw 4.16667vw 0vw; }

@media (min-width: 1680px) { .about__clients-detail { padding: 50px 70px 0px; } }

.about__clients-detail .slider-item { display: flex !important; flex-wrap: wrap; }

.about__clients-detail .item { margin: 0vw 4.16667vw 2.97619vw; width: 9.70238vw; opacity: 0; transition: opacity 0.9s ease-out; }

@media (min-width: 1680px) { .about__clients-detail .item { margin: 0px 70px 50px; } }

@media (min-width: 1680px) { .about__clients-detail .item { width: 163px; } }

.about__clients-detail .item img { width: 100%; }

.about__clients-detail.on .item { opacity: 1; }

@media (max-width: 1024px) { .about__clients-detail { padding: 2.92969vw 5.85938vw 0vw; }
  .about__clients-detail .item { margin: 0vw 2.92969vw 2.92969vw; width: 11.71875vw; } }

@media (max-width: 768px) { .about__clients-detail { padding: 8.33333vw 0vw 0vw; margin-bottom: 8.33333vw; } }

@media (max-width: 768px) and (max-width: 360px) { .about__clients-detail { padding: 8.33333vw 0vw 0vw; } }

@media (max-width: 768px) { .about__clients-detail .item { margin: 0vw 4.16667vw 8.33333vw; width: 33.33333vw; } }

@media (max-width: 768px) and (max-width: 360px) { .about__clients-detail .item { margin: 0vw 4.16667vw 8.33333vw; } }

@media (max-width: 768px) { .about__clients-detail .slick-dots { position: absolute; left: 0; top: calc(100% + 5.5556vw); } }

.about__brief { padding-bottom: 4.7619vw; }

@media (min-width: 1680px) { .about__brief { padding-bottom: 80px; } }

.about__brief .brief { position: relative; }

.about__brief .brief img { width: 100%; display: block; }

.about__brief .brief .mobile { display: none; }

.about__brief .brief .text { position: absolute; left: 0; top: 0; width: 100%; height: 100%; display: flex; flex-direction: column; align-items: center; justify-content: center; color: #fff; }

.about__brief .brief .text .title { font-family: oscine, Helvetica, "malgun gothic", "Apple SD Gothic Neo", "Microsoft NeoGothic", "Droid sans", arial, sans-serif; font-weight: bold; font-size: 2.33333rem; line-height: 1; padding-bottom: 2.97619vw; }

@media (min-width: 1680px) { .about__brief .brief .text .title { padding-bottom: 50px; } }

@media (max-width: 1024px) { .about__brief { padding-bottom: 4.88281vw; }
  .about__brief .brief .text .title { font-size: 1.75rem; padding-bottom: 2.92969vw; } }

@media (max-width: 1024px) and (min-width: 769px) { .about__brief .brief .download { font-size: 0.875rem; padding: 1.07422vw 3.51563vw 1.07422vw 1.75781vw; }
  .about__brief .brief .download:after { right: 1.36719vw; width: 1.36719vw; height: 1.07422vw; } }

@media (max-width: 768px) { .about__brief { padding-bottom: 0; }
  .about__brief .main__inner { padding: 0; }
  .about__brief .brief { width: 100%; }
  .about__brief .brief .pc { display: none; }
  .about__brief .brief .mobile { display: block; }
  .about__brief .brief .text .title { font-size: 1.71429rem; padding-bottom: 8.33333vw; } }

.vr_layer .about__nav { padding: 0.59524vw 1.78571vw 1.78571vw; display: flex; }

@media (min-width: 1680px) { .vr_layer .about__nav { padding: 10px 30px 30px; } }

.vr_layer .about__nav button { position: relative; font-size: 1rem; font-family: oscine, Helvetica, "malgun gothic", "Apple SD Gothic Neo", "Microsoft NeoGothic", "Droid sans", arial, sans-serif; font-weight: bold; display: flex; align-items: center; }

.vr_layer .about__nav button:hover { text-decoration: underline; }

.vr_layer .about__nav button:before, .vr_layer .about__nav button:after { content: ""; width: 0.95238vw; height: 0.71429vw; margin-right: 0.47619vw; background: url(../images/arrow_page_move.svg) no-repeat center/100% auto; display: block; }

@media (min-width: 1680px) { .vr_layer .about__nav button:before, .vr_layer .about__nav button:after { width: 16px; } }

@media (min-width: 1680px) { .vr_layer .about__nav button:before, .vr_layer .about__nav button:after { height: 12px; } }

@media (min-width: 1680px) { .vr_layer .about__nav button:before, .vr_layer .about__nav button:after { margin-right: 8px; } }

.vr_layer .about__nav button:after { transform: scaleX(-1); margin-right: 0; margin-left: 0.47619vw; }

@media (min-width: 1680px) { .vr_layer .about__nav button:after { margin-left: 8px; } }

.vr_layer .about__nav button.prev:after { display: none; }

.vr_layer .about__nav button.next { margin-left: auto; }

.vr_layer .about__nav button.next:before { display: none; }

@media (max-width: 1024px) { .vr_layer .about__nav { padding: 0.97656vw 2.92969vw 2.92969vw; }
  .vr_layer .about__nav button { font-size: 1.125rem; }
  .vr_layer .about__nav button:before, .vr_layer .about__nav button:after { width: 1.5625vw; height: 1.17188vw; margin-right: 0.78125vw; }
  .vr_layer .about__nav button:after { margin-right: 0; margin-left: 0.78125vw; } }

@media (max-width: 768px) { .vr_layer .about__nav { padding: 12.5vw 5.55556vw; } }

@media (max-width: 768px) and (max-width: 360px) { .vr_layer .about__nav { padding: 12.5vw 5.55556vw; } }

@media (max-width: 768px) { .vr_layer .about__nav button { font-size: 1.28571rem; }
  .vr_layer .about__nav button:before, .vr_layer .about__nav button:after { width: 4.44444vw; height: 3.33333vw; margin-right: 2.22222vw; }
  .vr_layer .about__nav button:after { margin-right: 0; margin-left: 2.22222vw; } }

.vr_layer .about__info { padding-bottom: 0; }

.vr_layer .about__info .information { padding: 2.97619vw 1.78571vw; }

@media (min-width: 1680px) { .vr_layer .about__info .information { padding: 50px 30px; } }

.vr_layer .about__info .box { margin-top: 0; border: 0; border-top: 1px solid #555; }

@media (max-width: 1024px) { .vr_layer .about__info .information { padding: 3.90625vw 1.95313vw; } }

@media (max-width: 768px) { .vr_layer .about__info .information { padding: 8.33333vw 5.55556vw; } }

@media (max-width: 768px) and (max-width: 360px) { .vr_layer .about__info .information { padding: 8.33333vw 5.55556vw; } }

@media (max-width: 768px) { .vr_layer .about__info .box { margin: 0; border: 0; }
  .vr_layer .about__info .history { padding-bottom: 16.66667vw; } }

.vr_layer .about__strength { border: 0; padding: 0vw 1.78571vw; }

@media (min-width: 1680px) { .vr_layer .about__strength { padding: 0px 30px; } }

.vr_layer .about__strength .info { padding-bottom: 2.97619vw; }

@media (min-width: 1680px) { .vr_layer .about__strength .info { padding-bottom: 50px; } }

@media (max-width: 1024px) { .vr_layer .about__strength { padding: 0vw 1.95313vw; } }

@media (max-width: 768px) { .vr_layer .about__strength { padding: 0vw 5.55556vw; } }

@media (max-width: 768px) and (max-width: 360px) { .vr_layer .about__strength { padding: 0vw 5.55556vw; } }

@media (max-width: 768px) { .vr_layer .about__strength .info { padding-bottom: 13.88889vw; } }

.vr_layer .about__strength-detail { padding: 0vw 1.19048vw; }

@media (min-width: 1680px) { .vr_layer .about__strength-detail { padding: 0px 20px; } }

.vr_layer .about__strength-detail:after { content: ""; display: block; clear: both; }

.vr_layer .about__strength-detail .item { position: relative; border: 1px solid #000; width: 27.85714vw; padding: 0vw 0.59524vw 0.59524vw; margin-bottom: 4.7619vw; float: left; }

@media (min-width: 1680px) { .vr_layer .about__strength-detail .item { width: 468px; } }

@media (min-width: 1680px) { .vr_layer .about__strength-detail .item { padding: 0px 10px 10px; } }

@media (min-width: 1680px) { .vr_layer .about__strength-detail .item { margin-bottom: 80px; } }

.vr_layer .about__strength-detail .item:nth-child(odd) { margin-right: 3.9881vw; }

@media (min-width: 1680px) { .vr_layer .about__strength-detail .item:nth-child(odd) { margin-right: 67px; } }

.vr_layer .about__strength-detail .item:before { content: ""; position: absolute; left: 0.35714vw; top: 0.35714vw; z-index: -1; width: 100%; height: 100%; border: 1px solid #000; }

@media (min-width: 1680px) { .vr_layer .about__strength-detail .item:before { left: 6px; } }

@media (min-width: 1680px) { .vr_layer .about__strength-detail .item:before { top: 6px; } }

.vr_layer .about__strength-detail .item .title { height: 3.57143vw; font-size: 1.55556rem; display: flex; align-items: center; }

@media (min-width: 1680px) { .vr_layer .about__strength-detail .item .title { height: 60px; } }

.vr_layer .about__strength-detail .item .title:after { display: none; }

.vr_layer .about__strength-detail .item .title:before { width: 0.59524vw; height: 0.59524vw; margin-right: 0.47619vw; position: static; border-radius: 50%; border: 1px solid #555; }

@media (min-width: 1680px) { .vr_layer .about__strength-detail .item .title:before { width: 10px; } }

@media (min-width: 1680px) { .vr_layer .about__strength-detail .item .title:before { height: 10px; } }

@media (min-width: 1680px) { .vr_layer .about__strength-detail .item .title:before { margin-right: 8px; } }

.vr_layer .about__strength-detail .item .content { position: relative; border: 1px solid #000; letter-spacing: -0.02em; padding: 1.19048vw 1.19048vw 2.97619vw; background-color: #fff; }

@media (min-width: 1680px) { .vr_layer .about__strength-detail .item .content { padding: 20px 20px 50px; } }

.vr_layer .about__strength-detail .item .point .line { width: 100%; transition-duration: 0s; opacity: 1; }

.vr_layer .about__strength-detail .item:nth-child(1) { background-color: #B9E2DA; }

.vr_layer .about__strength-detail .item:nth-child(1):before { background-color: #63C6C6; }

.vr_layer .about__strength-detail .item:nth-child(1) .title:before { background-color: #63C6C6; }

.vr_layer .about__strength-detail .item:nth-child(2) { margin-top: 1.78571vw; background-color: #E9F29E; }

@media (min-width: 1680px) { .vr_layer .about__strength-detail .item:nth-child(2) { margin-top: 30px; } }

.vr_layer .about__strength-detail .item:nth-child(2):before { background-color: #93D040; }

.vr_layer .about__strength-detail .item:nth-child(2) .title:before { background-color: #93D040; }

.vr_layer .about__strength-detail .item:nth-child(3) { margin-top: -12.79762vw; background-color: #FACE87; }

@media (min-width: 1680px) { .vr_layer .about__strength-detail .item:nth-child(3) { margin-top: -215px; } }

.vr_layer .about__strength-detail .item:nth-child(3):before { background-color: #EB9248; }

.vr_layer .about__strength-detail .item:nth-child(3) .title:before { background-color: #EB9248; }

.vr_layer .about__strength-detail .item:nth-child(4) { background-color: #C9B5E3; }

.vr_layer .about__strength-detail .item:nth-child(4):before { background-color: #876CDE; }

.vr_layer .about__strength-detail .item:nth-child(4) .title:before { background-color: #876CDE; }

@media (max-width: 1024px) { .vr_layer .about__strength-detail { padding: 0vw 1.95313vw; }
  .vr_layer .about__strength-detail .item { width: 38.08594vw; padding: 0vw 0.97656vw 0.97656vw; margin-bottom: 5.46875vw; }
  .vr_layer .about__strength-detail .item:nth-child(odd) { margin-right: 3.61328vw; }
  .vr_layer .about__strength-detail .item:before { left: 0.58594vw; top: 0.58594vw; }
  .vr_layer .about__strength-detail .item .title { height: 5.37109vw; }
  .vr_layer .about__strength-detail .item .title:before { width: 0.97656vw; height: 0.97656vw; margin-right: 0.78125vw; }
  .vr_layer .about__strength-detail .item .content { padding: 1.95313vw 1.95313vw 4.88281vw; }
  .vr_layer .about__strength-detail .item:nth-child(2) { margin-top: 2.92969vw; }
  .vr_layer .about__strength-detail .item:nth-child(3) { margin-top: -20.50781vw; } }

@media (max-width: 768px) { .vr_layer .about__strength-detail { display: block; padding: 0; }
  .vr_layer .about__strength-detail .item { width: 100%; margin: 0; padding: 0vw 2.77778vw 2.77778vw; margin-bottom: 10vw; } }

@media (max-width: 768px) and (max-width: 360px) { .vr_layer .about__strength-detail .item { padding: 0vw 2.77778vw 2.77778vw; } }

@media (max-width: 768px) { .vr_layer .about__strength-detail .item:before { left: 1.66667vw; top: 1.66667vw; }
  .vr_layer .about__strength-detail .item:last-child { margin-bottom: 0; }
  .vr_layer .about__strength-detail .item .title { height: 13.88889vw; padding: 0; }
  .vr_layer .about__strength-detail .item .title:before { width: 2.22222vw; height: 2.22222vw; margin-right: 2.22222vw; }
  .vr_layer .about__strength-detail .item:nth-child(2), .vr_layer .about__strength-detail .item:nth-child(3) { margin-top: 0; } }

.vr_layer .about__solutions { padding: 0vw 1.78571vw; border-bottom: 0; }

@media (min-width: 1680px) { .vr_layer .about__solutions { padding: 0px 30px; } }

@media (max-width: 768px) { .vr_layer .about__solutions { padding: 0vw 5.55556vw; } }

@media (max-width: 768px) and (max-width: 360px) { .vr_layer .about__solutions { padding: 0vw 5.55556vw; } }

.vr_layer .about__solutions-detail { padding: 0vw 1.19048vw 2.97619vw; }

@media (min-width: 1680px) { .vr_layer .about__solutions-detail { padding: 0px 20px 50px; } }

@media (max-width: 768px) { .vr_layer .about__solutions-detail { padding: 0; } }

.vr_layer .about__solutions-detail .item { width: 46.60714vw; transform: translateX(50px); }

@media (min-width: 1680px) { .vr_layer .about__solutions-detail .item { width: 783px; } }

.vr_layer .about__solutions-detail .item:nth-child(2n) { transform: translateX(-50px); }

.vr_layer .about__solutions-detail .item .images { width: 17.32143vw; }

@media (min-width: 1680px) { .vr_layer .about__solutions-detail .item .images { width: 291px; } }

.vr_layer .about__solutions-detail .item.on { transform: translateX(0); }

.vr_layer .about__solutions-detail .item.on:nth-child(2n) { transform: translateX(0); }

@media (max-width: 1024px) { .vr_layer .about__solutions-detail .item { width: 61.91406vw; }
  .vr_layer .about__solutions-detail .item .images { width: 23.4375vw; } }

@media (max-width: 768px) { .vr_layer .about__solutions-detail .item { transform: translateY(50px); width: 73.88889vw; }
  .vr_layer .about__solutions-detail .item:nth-child(2n) { transform: translateY(50px); }
  .vr_layer .about__solutions-detail .item .images { width: 100%; } }

.vr_layer .about__clients { padding: 0vw 1.78571vw 1.78571vw; }

@media (min-width: 1680px) { .vr_layer .about__clients { padding: 0px 30px 30px; } }

.vr_layer .about__clients .sub_title { padding-bottom: 1.78571vw; }

@media (min-width: 1680px) { .vr_layer .about__clients .sub_title { padding-bottom: 30px; } }

.vr_layer .about__clients-detail .item { margin: 0vw 1.78571vw 2.97619vw; }

@media (min-width: 1680px) { .vr_layer .about__clients-detail .item { margin: 0px 30px 50px; } }

@media (max-width: 1024px) { .vr_layer .about__clients { padding: 0vw 1.95313vw 2.92969vw; }
  .vr_layer .about__clients-detail .item { margin: 0vw 2.92969vw 4.88281vw; } }

@media (max-width: 768px) { .vr_layer .about__clients { padding: 0vw 5.55556vw; } }

@media (max-width: 768px) and (max-width: 360px) { .vr_layer .about__clients { padding: 0vw 5.55556vw; } }

@media (max-width: 768px) { .vr_layer .about__clients-detail .item { margin: 0vw 4.16667vw 8.33333vw; } }

@media (max-width: 768px) and (max-width: 360px) { .vr_layer .about__clients-detail .item { margin: 0vw 4.16667vw 8.33333vw; } }

.os_win .about__solutions .item .content .title { padding-top: 0.29762vw; }

@media (min-width: 1680px) { .os_win .about__solutions .item .content .title { padding-top: 5px; } }

@media (max-width: 768px) { .os_win .about__solutions .item .content .title { padding: 2.22222vw 4.16667vw 1.66667vw; } }

@media (max-width: 768px) and (max-width: 360px) { .os_win .about__solutions .item .content .title { padding: 2.22222vw 4.16667vw 1.66667vw; } }

@media (max-width: 1024px) and (min-width: 769px) { .os_win .about__brief .brief .download { padding: 1.26953vw 3.51563vw 0.97656vw 1.75781vw; } }

.os_win .about__strength-detail .item .text { padding-top: 0.17857vw; }

@media (min-width: 1680px) { .os_win .about__strength-detail .item .text { padding-top: 3px; } }

@media (max-width: 1024px) { .os_win .about__strength-detail .item .text { padding-top: 0.29297vw; } }

@media (max-width: 768px) { .os_win .about__strength-detail .item .text { padding-top: 0.83333vw; } }

html[lang=en] .vr_layer .about__info .patent .content .item { height: 13.69048vw; }

@media (min-width: 1680px) { html[lang=en] .vr_layer .about__info .patent .content .item { height: 230px; } }

@media (max-width: 1024px) { html[lang=en] .vr_layer .about__info .patent .content .item { height: 14.16016vw; } }

@media (max-width: 768px) { html[lang=en] .vr_layer .about__info .patent .content .item { height: 40.27778vw; } }

html[lang=en] .about__info .patent .content .item { height: auto; }

html[lang=en] .about__info .patent .content .item .name { min-height: 10.95238vw; }

@media (min-width: 1680px) { html[lang=en] .about__info .patent .content .item .name { min-height: 184px; } }

@media (max-width: 1024px) { html[lang=en] .about__info .patent .content .item .name { min-height: 11.71875vw; font-size: 0.8125rem; } }

@media (max-width: 768px) { html[lang=en] .about__info .patent .content .item .name { padding: 2.77778vw; font-size: 1rem; } }

.contact__wrap .fancy__wrap { padding-left: 7.79762vw; padding-right: 7.79762vw; }

@media (min-width: 1680px) { .contact__wrap .fancy__wrap { padding-left: 131px; } }

@media (min-width: 1680px) { .contact__wrap .fancy__wrap { padding-right: 131px; } }

.contact__wrap .subpage_title { padding-top: 4.58333vw; text-align: center; }

@media (min-width: 1680px) { .contact__wrap .subpage_title { padding-top: 77px; } }

.contact__wrap .subpage_sub_title { font-size: 1.55556rem; padding-bottom: 1.19048vw; text-align: center; font-weight: bold; }

@media (min-width: 1680px) { .contact__wrap .subpage_sub_title { padding-bottom: 20px; } }

.contact__wrap .subpage_desc { text-align: center; }

.contact__wrap .info { border-top: 1px solid #555; margin-bottom: 2.97619vw; }

@media (min-width: 1680px) { .contact__wrap .info { margin-bottom: 50px; } }

.contact__wrap .info li { border-bottom: 1px solid #555; padding: 1.60714vw 0.59524vw; display: flex; font-size: 1.55556rem; }

@media (min-width: 1680px) { .contact__wrap .info li { padding: 27px 10px; } }

.contact__wrap .info span { width: 11.0119vw; font-weight: bold; }

@media (min-width: 1680px) { .contact__wrap .info span { width: 185px; } }

.contact__wrap .button_wrap { text-align: center; padding-bottom: 5.95238vw; }

@media (min-width: 1680px) { .contact__wrap .button_wrap { padding-bottom: 100px; } }

.contact__wrap .button_wrap .download { margin: 0vw 1.4881vw; }

@media (min-width: 1680px) { .contact__wrap .button_wrap .download { margin: 0px 25px; } }

.contact__wrap .form { border: 1px solid #000; border-radius: 3px; margin-bottom: 5.95238vw; position: relative; }

@media (min-width: 1680px) { .contact__wrap .form { margin-bottom: 100px; } }

.contact__wrap .form .hidden { display: none; }

.contact__wrap .form_title { padding: 1.19048vw 1.78571vw; border-bottom: 1px solid #000; font-weight: bold; }

@media (min-width: 1680px) { .contact__wrap .form_title { padding: 20px 30px; } }

.contact__wrap .form_wrap { padding: 1.78571vw 1.78571vw 0vw 1.78571vw; }

@media (min-width: 1680px) { .contact__wrap .form_wrap { padding: 30px 30px 0px 30px; } }

.contact__wrap .form_wrap li { margin-bottom: 1.78571vw; }

@media (min-width: 1680px) { .contact__wrap .form_wrap li { margin-bottom: 30px; } }

.contact__wrap .form_wrap li .input { width: 100%; border: 1px solid #aaa; border-radius: 3px; }

.contact__wrap .form_wrap li .input::placeholder { color: #AAA; }

.contact__wrap .form_wrap li.fill { position: relative; }

.contact__wrap .form_wrap li.fill .input { border: 1px solid #000; }

.contact__wrap .form_wrap li.fill .input::placeholder { color: #fff; }

.contact__wrap .form_wrap li.fill .label { display: block; position: absolute; top: 0; transform: translateY(-50%); background: #fff; left: 0.53571vw; padding: 0.2381vw; font-size: 0.66667rem; }

@media (min-width: 1680px) { .contact__wrap .form_wrap li.fill .label { left: 9px; } }

@media (min-width: 1680px) { .contact__wrap .form_wrap li.fill .label { padding: 4px; } }

.contact__wrap .form_wrap li .email { padding: 1.30952vw 0.71429vw; font-size: 1rem; }

@media (min-width: 1680px) { .contact__wrap .form_wrap li .email { padding: 22px 12px; } }

.contact__wrap .form_wrap li .message { padding: 0.71429vw; height: 17.85714vw; font-size: 0.88889rem; resize: none; }

@media (min-width: 1680px) { .contact__wrap .form_wrap li .message { padding: 12px; } }

@media (min-width: 1680px) { .contact__wrap .form_wrap li .message { height: 300px; } }

.contact__wrap .form_wrap li .message::-webkit-scrollbar { width: 5px; }

.contact__wrap .form_wrap li .message::-webkit-scrollbar-track { background-color: transparent; }

.contact__wrap .form_wrap li .message::-webkit-scrollbar-thumb { border-radius: 8px; background-color: rgba(0, 0, 0, 0.1); }

.contact__wrap .form_wrap .checkbox label { width: 17.85714vw; font-size: 0.77778rem; }

@media (min-width: 1680px) { .contact__wrap .form_wrap .checkbox label { width: 300px; } }

.contact__wrap .form_wrap .checkbox label .term_open_btn { font-size: 0.77778rem; text-decoration: underline; }

.contact__wrap .form .button.normal { padding: 0.83333vw 2.38095vw; position: absolute; right: 1.78571vw; bottom: 1.78571vw; font-size: 1rem; font-family: "Noto Sans KR", Helvetica, "malgun gothic", "Apple SD Gothic Neo", "Microsoft NeoGothic", "Droid sans", sans-serif; }

@media (min-width: 1680px) { .contact__wrap .form .button.normal { padding: 14px 40px; } }

@media (min-width: 1680px) { .contact__wrap .form .button.normal { right: 30px; } }

@media (min-width: 1680px) { .contact__wrap .form .button.normal { bottom: 30px; } }

.contact__wrap .form .button.normal:after { display: none; }

@media (max-width: 1024px) { .contact__wrap .fancy__wrap { padding-left: 17.08984vw; padding-right: 17.08984vw; }
  .contact__wrap .subpage_title { padding-top: 7.12891vw; }
  .contact__wrap .subpage_sub_title { font-size: 1rem; padding-bottom: 1.36719vw; }
  .contact__wrap .info { margin-bottom: 3.90625vw; }
  .contact__wrap .info li { padding: 1.75781vw 0.97656vw; font-size: 1.125rem; }
  .contact__wrap .info span { width: 10.25391vw; }
  .contact__wrap .button_wrap { padding-bottom: 3.90625vw; }
  .contact__wrap .button_wrap .download { margin: 0vw 1.95313vw; }
  .contact__wrap .form { margin-bottom: 7.8125vw; }
  .contact__wrap .form_title { padding: 1.95313vw 2.92969vw; }
  .contact__wrap .form_wrap { padding: 2.92969vw 2.92969vw 0vw 2.92969vw; }
  .contact__wrap .form_wrap li { margin-bottom: 1.95313vw; }
  .contact__wrap .form_wrap li.fill .label { left: 0.87891vw; padding: 0.39063vw; font-size: 0.6875rem; }
  .contact__wrap .form_wrap li .email { padding: 1.36719vw 1.17188vw; font-size: 1rem; }
  .contact__wrap .form_wrap li .message { padding: 1.17188vw; height: 24.41406vw; font-size: 0.875rem; }
  .contact__wrap .form_wrap .checkbox label { width: 27.05078vw; }
  .contact__wrap .form .button.normal { padding: 1.17188vw 2.92969vw; right: 2.92969vw; bottom: 2.92969vw; font-size: 0.875rem; } }

@media (max-width: 768px) { .contact__wrap .fancy__wrap { padding-left: 5.55556vw; padding-right: 5.55556vw; }
  .contact__wrap .subpage_title { padding-top: 8.33333vw; }
  .contact__wrap .subpage_sub_title { font-size: 1rem; padding-bottom: 5.55556vw; }
  .contact__wrap .subpage_desc .break_mo { display: block; }
  .contact__wrap .info { margin-bottom: 11.11111vw; }
  .contact__wrap .info li { padding: 3.33333vw 1.38889vw; font-size: 0.85714rem; } }

@media (max-width: 768px) and (max-width: 360px) { .contact__wrap .info li { padding: 3.33333vw 1.38889vw; } }

@media (max-width: 768px) { .contact__wrap .info span { width: 16.66667vw; }
  .contact__wrap .info p { font-weight: normal; }
  .contact__wrap .button_wrap { padding-bottom: 6.94444vw; display: flex; flex-direction: column; align-items: flex-start; }
  .contact__wrap .button_wrap .download { margin: 0vw 0vw 6.94444vw 0vw; } }

@media (max-width: 768px) and (max-width: 360px) { .contact__wrap .button_wrap .download { margin: 0vw 0vw 6.94444vw 0vw; } }

@media (max-width: 768px) { .contact__wrap .form { border: none; margin-bottom: 40vw; }
  .contact__wrap .form_title { display: none; }
  .contact__wrap .form_wrap { padding: 0vw; }
  .contact__wrap .form_wrap li { margin-bottom: 5.55556vw; }
  .contact__wrap .form_wrap li.fill .label { left: 2.5vw; padding: 1.11111vw; font-size: 0.78571rem; }
  .contact__wrap .form_wrap li .email { padding: 3.88889vw 3.33333vw; margin-bottom: 2.77778vw; font-size: 1.14286rem; } }

@media (max-width: 768px) and (max-width: 360px) { .contact__wrap .form_wrap li .email { padding: 3.88889vw 3.33333vw; } }

@media (max-width: 768px) { .contact__wrap .form_wrap li .message { padding: 3.33333vw; height: 69.44444vw; font-size: 1.14286rem; }
  .contact__wrap .form_wrap .checkbox label { width: 76.94444vw; }
  .contact__wrap .form .button.normal { width: 100%; padding: 3.33333vw 8.33333vw; left: 0vw; right: 0vw; bottom: -26.11111vw; font-size: 1rem; } }

@media (max-width: 768px) and (max-width: 360px) { .contact__wrap .form .button.normal { padding: 3.33333vw 8.33333vw; } }

.contact__wrap.vr_layer .layer_inner { display: none; }

.contact__wrap.vr_layer .layer_inner.is-active { display: block; }

.contact__wrap.vr_layer .contents { display: flex; padding-left: 1.78571vw; padding-right: 2.97619vw; padding-bottom: 1.4881vw; margin: initial; }

@media (min-width: 1680px) { .contact__wrap.vr_layer .contents { padding-left: 30px; } }

@media (min-width: 1680px) { .contact__wrap.vr_layer .contents { padding-right: 50px; } }

@media (min-width: 1680px) { .contact__wrap.vr_layer .contents { padding-bottom: 25px; } }

.contact__wrap.vr_layer .left_area { width: 36.7%; margin-right: 4.16667vw; height: 100%; }

@media (min-width: 1680px) { .contact__wrap.vr_layer .left_area { margin-right: 70px; } }

.contact__wrap.vr_layer .subpage_title { font-size: 3.22222rem; padding-top: 0.89286vw; padding-bottom: 0.89286vw; text-align: left; }

@media (min-width: 1680px) { .contact__wrap.vr_layer .subpage_title { padding-top: 15px; } }

@media (min-width: 1680px) { .contact__wrap.vr_layer .subpage_title { padding-bottom: 15px; } }

.contact__wrap.vr_layer .subpage_sub_title { font-size: 1rem; padding-bottom: 0.95238vw; text-align: left; }

@media (min-width: 1680px) { .contact__wrap.vr_layer .subpage_sub_title { padding-bottom: 16px; } }

.contact__wrap.vr_layer .subpage_desc { text-align: left; font-size: 0.88889rem; }

.contact__wrap.vr_layer .info { margin-bottom: 1.4881vw; }

@media (min-width: 1680px) { .contact__wrap.vr_layer .info { margin-bottom: 25px; } }

.contact__wrap.vr_layer .info li { padding: 0.71429vw 0.29762vw; font-size: 0.88889rem; }

@media (min-width: 1680px) { .contact__wrap.vr_layer .info li { padding: 12px 5px; } }

.contact__wrap.vr_layer .info span { width: 4.16667vw; }

@media (min-width: 1680px) { .contact__wrap.vr_layer .info span { width: 70px; } }

.contact__wrap.vr_layer .button_wrap { display: flex; flex-direction: column; align-items: flex-start; padding-bottom: 0; }

.contact__wrap.vr_layer .button_wrap .download { margin: 0.89286vw 0vw; }

@media (min-width: 1680px) { .contact__wrap.vr_layer .button_wrap .download { margin: 15px 0px; } }

.contact__wrap.vr_layer .form { flex: 1; border: none; padding-top: 7.55952vw; margin-bottom: 0vw; height: 100%; }

@media (min-width: 1680px) { .contact__wrap.vr_layer .form { padding-top: 127px; } }

@media (min-width: 1680px) { .contact__wrap.vr_layer .form { margin-bottom: 0px; } }

.contact__wrap.vr_layer .form form { position: relative; }

.contact__wrap.vr_layer .form form.invisible { border: 1px solid #aaa; border-radius: 5px; height: 29.10714vw; display: flex; justify-content: center; align-items: center; }

@media (min-width: 1680px) { .contact__wrap.vr_layer .form form.invisible { height: 489px; } }

.contact__wrap.vr_layer .form form.invisible ul { display: none; }

.contact__wrap.vr_layer .form form.invisible #contact_submit { display: none; }

.contact__wrap.vr_layer .form form.invisible .layer_confirm { text-align: center; }

.contact__wrap.vr_layer .form form.invisible .button_wrap { flex-direction: row; justify-content: center; }

.contact__wrap.vr_layer .form_title { display: none; }

.contact__wrap.vr_layer .form_wrap { padding: 0vw; }

@media (min-width: 1680px) { .contact__wrap.vr_layer .form_wrap { padding: 0px; } }

.contact__wrap.vr_layer .form_wrap li { margin-bottom: 1.19048vw; }

@media (min-width: 1680px) { .contact__wrap.vr_layer .form_wrap li { margin-bottom: 20px; } }

.contact__wrap.vr_layer .form_wrap li .email { padding: 1.0119vw 0.71429vw; font-size: 1rem; }

@media (min-width: 1680px) { .contact__wrap.vr_layer .form_wrap li .email { padding: 17px 12px; } }

.contact__wrap.vr_layer .form_wrap li .message { height: 16.66667vw; font-size: 0.88889rem; }

@media (min-width: 1680px) { .contact__wrap.vr_layer .form_wrap li .message { height: 280px; } }

.contact__wrap.vr_layer .form .button.normal { padding: 0.59524vw 1.54762vw; right: 0vw; bottom: 0vw; font-size: 0.88889rem; }

@media (min-width: 1680px) { .contact__wrap.vr_layer .form .button.normal { padding: 10px 26px; } }

@media (min-width: 1680px) { .contact__wrap.vr_layer .form .button.normal { right: 0px; } }

@media (min-width: 1680px) { .contact__wrap.vr_layer .form .button.normal { bottom: 0px; } }

.contact__wrap.vr_layer .inner_privacy { border-radius: 0.29762vw; width: 49.64286vw; }

@media (min-width: 1680px) { .contact__wrap.vr_layer .inner_privacy { border-radius: 5px; } }

@media (min-width: 1680px) { .contact__wrap.vr_layer .inner_privacy { width: 834px; } }

.contact__wrap.vr_layer .inner_privacy .top { padding: 1.72619vw 1.78571vw 1.4881vw 1.78571vw; font-size: 1.11111rem; border-bottom: none; }

@media (min-width: 1680px) { .contact__wrap.vr_layer .inner_privacy .top { padding: 29px 30px 25px 30px; } }

.contact__wrap.vr_layer .inner_privacy .top .title { padding-left: 0.59524vw; }

@media (min-width: 1680px) { .contact__wrap.vr_layer .inner_privacy .top .title { padding-left: 10px; } }

.contact__wrap.vr_layer .inner_privacy .top .prev_btn { font-size: 0; width: 2.38095vw; height: 2.38095vw; background: url("../images/arrow_prev.svg") no-repeat center/100% auto; }

@media (min-width: 1680px) { .contact__wrap.vr_layer .inner_privacy .top .prev_btn { width: 40px; } }

@media (min-width: 1680px) { .contact__wrap.vr_layer .inner_privacy .top .prev_btn { height: 40px; } }

.contact__wrap.vr_layer .inner_privacy .contents { padding-top: 5vw; display: block; font-size: 0.88889rem; }

@media (min-width: 1680px) { .contact__wrap.vr_layer .inner_privacy .contents { padding-top: 84px; } }

.contact__wrap.vr_layer .inner_privacy .button_wrap { display: flex; align-items: center; padding: 2.97619vw 0vw 0.89286vw; }

@media (min-width: 1680px) { .contact__wrap.vr_layer .inner_privacy .button_wrap { padding: 50px 0px 15px; } }

.contact__wrap.vr_layer .inner_privacy .button_wrap .agree_btn { font-weight: bold; width: 18.5119vw; padding: 0.71429vw; text-align: center; font-size: 0.88889rem; }

@media (min-width: 1680px) { .contact__wrap.vr_layer .inner_privacy .button_wrap .agree_btn { width: 311px; } }

@media (min-width: 1680px) { .contact__wrap.vr_layer .inner_privacy .button_wrap .agree_btn { padding: 12px; } }

.contact__wrap.vr_layer .inner_privacy .button_wrap .agree_btn:after { display: none; }

@media (max-width: 1024px) { .contact__wrap.vr_layer .contents { padding: 7.42188vw 1.95313vw 3.61328vw 1.95313vw; }
  .contact__wrap.vr_layer .left_area { margin-right: 3.51563vw; }
  .contact__wrap.vr_layer .subpage_title { font-size: 2.625rem; padding-top: 2.92969vw; padding-bottom: 2.34375vw; }
  .contact__wrap.vr_layer .subpage_sub_title { font-size: 0.875rem; padding-bottom: 1.95313vw; }
  .contact__wrap.vr_layer .subpage_desc { font-size: 0.75rem; }
  .contact__wrap.vr_layer .info { margin-bottom: 2.73438vw; }
  .contact__wrap.vr_layer .info li { padding: 1.17188vw 0.48828vw; font-size: 0.75rem; }
  .contact__wrap.vr_layer .info span { width: 5.85938vw; }
  .contact__wrap.vr_layer .button_wrap .download { margin: 1.17188vw 0vw; }
  .contact__wrap.vr_layer .form { padding-top: 9.17969vw; }
  .contact__wrap.vr_layer .form_wrap li { margin-bottom: 1.95313vw; }
  .contact__wrap.vr_layer .form_wrap li .email { padding: 1.75781vw 1.17188vw; font-size: 1rem; }
  .contact__wrap.vr_layer .form_wrap li .message { height: 17.57813vw; font-size: 1rem; }
  .contact__wrap.vr_layer .form .button.normal { padding: 1.17188vw 2.92969vw; font-size: 0.875rem; bottom: 0; }
  .contact__wrap.vr_layer .form form.invisible { height: 36.42578vw; }
  .contact__wrap.vr_layer .inner_privacy { width: 65.82031vw; border-radius: 0.29297vw; }
  .contact__wrap.vr_layer .inner_privacy .top { padding: 2.34375vw 1.75781vw 1.95313vw 1.75781vw; font-size: 1.25rem; }
  .contact__wrap.vr_layer .inner_privacy .top .title { padding-left: 0.97656vw; }
  .contact__wrap.vr_layer .inner_privacy .top .prev_btn { width: 3.90625vw; height: 3.90625vw; }
  .contact__wrap.vr_layer .inner_privacy .contents { padding-top: 7.12891vw; padding-bottom: 0; font-size: 1rem; }
  .contact__wrap.vr_layer .inner_privacy .button_wrap { padding: 2.92969vw 0vw; }
  .contact__wrap.vr_layer .inner_privacy .button_wrap .agree_btn { width: 24.60938vw; padding: 1.17188vw; font-size: 0.875rem; } }

@media (max-width: 768px) { .contact__wrap.vr_layer .contents { display: block; padding: 18.33333vw 5.55556vw 13.88889vw 5.55556vw; } }

@media (max-width: 768px) and (max-width: 360px) { .contact__wrap.vr_layer .contents { padding: 18.33333vw 5.55556vw 13.88889vw 5.55556vw; } }

@media (max-width: 768px) { .contact__wrap.vr_layer .left_area { margin-right: 0; width: 100%; }
  .contact__wrap.vr_layer .subpage_title { font-size: 2.42857rem; padding-top: 5.55556vw; padding-bottom: 2.77778vw; }
  .contact__wrap.vr_layer .subpage_sub_title { font-size: 1rem; padding-bottom: 5.55556vw; }
  .contact__wrap.vr_layer .subpage_desc { font-size: 0.85714rem; }
  .contact__wrap.vr_layer .info { margin-bottom: 7.77778vw; }
  .contact__wrap.vr_layer .info li { padding: 3.33333vw 1.38889vw; font-size: 0.85714rem; } }

@media (max-width: 768px) and (max-width: 360px) { .contact__wrap.vr_layer .info li { padding: 3.33333vw 1.38889vw; } }

@media (max-width: 768px) { .contact__wrap.vr_layer .info span { width: 16.66667vw; }
  .contact__wrap.vr_layer .button_wrap .download { margin: 3.33333vw 0vw; } }

@media (max-width: 768px) and (max-width: 360px) { .contact__wrap.vr_layer .button_wrap .download { margin: 3.33333vw 0vw; } }

@media (max-width: 768px) { .contact__wrap.vr_layer .form { padding-top: 8.88889vw; }
  .contact__wrap.vr_layer .form form { padding-bottom: 12.22222vw; }
  .contact__wrap.vr_layer .form form.invisible { height: 154.16667vw; }
  .contact__wrap.vr_layer .form_wrap li { margin-bottom: 5.55556vw; }
  .contact__wrap.vr_layer .form_wrap li .email { padding: 3.88889vw 3.33333vw; font-size: 1.14286rem; margin-bottom: 2.77778vw; } }

@media (max-width: 768px) and (max-width: 360px) { .contact__wrap.vr_layer .form_wrap li .email { padding: 3.88889vw 3.33333vw; } }

@media (max-width: 768px) { .contact__wrap.vr_layer .form_wrap li .message { height: 69.44444vw; font-size: 1rem; }
  .contact__wrap.vr_layer .form .button.normal { width: 100%; padding: 3.33333vw; font-size: 1rem; }
  .contact__wrap.vr_layer .inner_privacy { border-radius: none; width: 100%; }
  .contact__wrap.vr_layer .inner_privacy .top { padding: 5.83333vw 2.77778vw 6.94444vw 2.77778vw; font-size: 1.28571rem; } }

@media (max-width: 768px) and (max-width: 360px) { .contact__wrap.vr_layer .inner_privacy .top { padding: 5.83333vw 2.77778vw 6.94444vw 2.77778vw; } }

@media (max-width: 768px) { .contact__wrap.vr_layer .inner_privacy .top .title { padding-left: 2.22222vw; }
  .contact__wrap.vr_layer .inner_privacy .top .prev_btn { width: 10vw; height: 10vw; }
  .contact__wrap.vr_layer .inner_privacy .contents { padding-bottom: 0; font-size: 1rem; }
  .contact__wrap.vr_layer .inner_privacy .button_wrap { padding: 11.11111vw 0vw; } }

@media (max-width: 768px) and (max-width: 360px) { .contact__wrap.vr_layer .inner_privacy .button_wrap { padding: 11.11111vw 0vw; } }

@media (max-width: 768px) { .contact__wrap.vr_layer .inner_privacy .button_wrap .agree_btn { width: 100%; padding: 3.33333vw; font-size: 1rem; } }

#intro { width: 100%; height: 100vh; display: flex; justify-content: center; align-items: center; flex-direction: column; }

#intro .button { opacity: 0; }

#intro .button.on { transition: opacity .3s ease; opacity: 1; }

.intro-player { width: 500px; height: 550px; }

.entrance_content { height: 99.95%; }

.entrance_content body { width: 100%; height: 100%; }

.entrance_content #wrapper { width: 100%; height: 100%; overflow-x: hidden; overflow-y: auto; position: relative; min-height: 54.7619vw; }

@media (min-width: 1680px) { .entrance_content #wrapper { min-height: 920px; } }

@media (min-width: 769px) { .entrance_content body { min-height: 100vh; } }

@media (max-width: 768px) { .entrance_content #wrapper { min-height: 211.11111vw; } }

.entrance__wrap { background-color: #FAF6F3; display: flex; width: 100%; height: 100%; position: absolute; text-align: center; }

.entrance__wrap .enter { width: 50%; position: relative; }

.entrance__wrap .enter_textbox { display: inline-block; position: relative; width: 20.05952vw; height: 5.95238vw; margin-top: 2.44048vw; }

@media (min-width: 1680px) { .entrance__wrap .enter_textbox { width: 337px; } }

@media (min-width: 1680px) { .entrance__wrap .enter_textbox { height: 100px; } }

@media (min-width: 1680px) { .entrance__wrap .enter_textbox { margin-top: 41px; } }

.entrance__wrap .enter_textbox span { font-size: 2rem; font-family: "Noto Sans KR", Helvetica, "malgun gothic", "Apple SD Gothic Neo", "Microsoft NeoGothic", "Droid sans", sans-serif; display: flex; align-items: center; justify-content: center; font-weight: bold; border-radius: 3.57143vw; border: 1px solid #000; position: absolute; color: #000; width: 100%; height: 100%; background: #fff; }

@media (min-width: 1680px) { .entrance__wrap .enter_textbox span { border-radius: 60px; } }

.entrance__wrap .enter_textbox:before { position: absolute; width: 100%; height: 100%; background: #AAA; content: ''; border-radius: 3.57143vw; top: 0.53571vw; left: 0.35714vw; border: 1px solid #000; }

@media (min-width: 1680px) { .entrance__wrap .enter_textbox:before { border-radius: 60px; } }

@media (min-width: 1680px) { .entrance__wrap .enter_textbox:before { top: 9px; } }

@media (min-width: 1680px) { .entrance__wrap .enter_textbox:before { left: 6px; } }

.entrance__wrap .enter__vr .vr_bg { position: absolute; width: 100%; height: 100%; }

.entrance__wrap .enter__vr #pano { width: 100%; height: 100%; position: absolute; }

.entrance__wrap .enter__fancy { position: relative; }

.entrance__wrap .enter__fancy .deco_item { position: absolute; }

.entrance__wrap .enter__fancy .deco_item span { content: ""; position: absolute; left: 0; top: 0; width: 100%; height: 0; background: no-repeat center/100% auto; }

.entrance__wrap .enter__fancy .deco1 { width: 16%; left: 36%; top: 24.1%; }

.entrance__wrap .enter__fancy .deco1 span { padding-top: 104.5%; background-image: url(../images/index_deco1.svg); animation: deco1 5s linear infinite; }

@keyframes deco1 { 0% { transform: rotate(0deg); }
  25% { transform: rotate(65deg); }
  50% { transform: rotate(0deg); }
  75% { transform: rotate(-65deg); } }

.entrance__wrap .enter__fancy .deco2 { width: 8.12%; left: 26.66%; top: 37.42%; }

.entrance__wrap .enter__fancy .deco2 .deco2_1 { stroke-dashoffset: 106; stroke-dasharray: 106; animation: deco2-line 0.9s forwards; }

.entrance__wrap .enter__fancy .deco2 .deco2_2 { stroke-dashoffset: 45; stroke-dasharray: 45; animation: deco2-line 0.5s 0.8s forwards; }

@keyframes deco2-line { 100% { stroke-dashoffset: 0; } }

.entrance__wrap .enter__fancy .deco3 { width: 12%; left: 71%; top: 53%; }

.entrance__wrap .enter__fancy .deco3 span { padding-top: 103.17%; background-image: url(../images/index_deco3.svg); animation: deco3 2.5s ease-in infinite; }

@keyframes deco3 { 0% { transform: scale(1); }
  50% { transform: scale(0.2); } }

.entrance__wrap .enter__fancy .deco4 { width: 17.4%; left: 58.12%; top: 74.79%; height: 18%; z-index: 5; }

.entrance__wrap .enter__fancy .deco4 span { padding-top: 100%; background-image: url(../images/index_deco4.svg); animation: deco4 5s linear infinite; }

@keyframes deco4 { 0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); } }

.entrance__wrap .enter__fancy .deco5 { width: 17.2%; left: 63.75%; top: 27.62%; }

.entrance__wrap .enter__fancy .deco5 span { padding-top: 125.4%; background-image: url(../images/index_deco_1.svg); animation: deco5 1s ease-in-out forwards; opacity: 0; }

@keyframes deco5 { 0% { opacity: 0; }
  100% { opacity: 1; } }

.entrance__wrap .enter__fancy .deco6 { width: 34.4%; left: 27.3%; top: 52.47%; }

.entrance__wrap .enter__fancy .deco6 span { padding-top: 100%; background-image: url(../images/index_deco_2.svg); animation: deco5 1.5s 0.5s ease-in-out forwards; opacity: 0; }

@keyframes deco5 { 0% { opacity: 0; }
  100% { opacity: 1; } }

.entrance__wrap .betree_logo { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); width: 14.40476vw; height: 14.40476vw; }

@media (min-width: 1680px) { .entrance__wrap .betree_logo { width: 242px; } }

@media (min-width: 1680px) { .entrance__wrap .betree_logo { height: 242px; } }

.entrance__wrap .betree_logo img { width: 100%; height: 100%; }

@media (max-width: 1024px) { .entrance__wrap .enter_textbox { display: inline-block; position: relative; width: 24.51172vw; height: 7.22656vw; margin-top: 5.85938vw; }
  .entrance__wrap .enter_textbox span { font-size: 1.5rem; border-radius: 5.85938vw; }
  .entrance__wrap .enter_textbox:before { border-radius: 5.85938vw; top: 0.68359vw; left: 0.48828vw; }
  .entrance__wrap .enter__fancy .deco1 { width: 16%; left: 34.8%; top: 34.59%; }
  .entrance__wrap .enter__fancy .deco2 { width: 8.3%; left: 25%; top: 43.61%; }
  .entrance__wrap .enter__fancy .deco3 { width: 12.1%; left: 69.74%; top: 54.68%; }
  .entrance__wrap .enter__fancy .deco4 { width: 17.57%; left: 56.64%; top: 69%; height: 11%; }
  .entrance__wrap .enter__fancy .deco5 { width: 17.38%; left: 62.42%; top: 37%; }
  .entrance__wrap .enter__fancy .deco6 { width: 34.76%; left: 25.58%; top: 53.9%; }
  .entrance__wrap .betree_logo { width: 17.38281vw; height: 17.38281vw; } }

@media (max-width: 768px) { .entrance__wrap { display: block; }
  .entrance__wrap .enter { width: 100%; height: 50%; }
  .entrance__wrap .enter_textbox { width: 41.94444vw; height: 13.05556vw; margin-top: 22.77778vw; }
  .entrance__wrap .enter_textbox span { font-size: 1.07143rem; border-radius: 16.66667vw; }
  .entrance__wrap .enter_textbox:before { border-radius: 16.66667vw; top: 0.83333vw; left: 0.83333vw; }
  .entrance__wrap .enter_textbox.vr_text { margin-top: 11.66667vw; }
  .entrance__wrap .enter__fancy .deco1 { width: 13.3%; left: 34.72%; top: 40.5%; }
  .entrance__wrap .enter__fancy .deco2 { width: 6.66%; left: 26.38%; top: 51%; }
  .entrance__wrap .enter__fancy .deco3 { width: 10%; left: 63.44%; top: 64.21%; }
  .entrance__wrap .enter__fancy .deco4 { width: 14.44%; left: 52.69%; top: 80.92%; height: 16.66667vw; }
  .entrance__wrap .enter__fancy .deco5 { width: 14.16%; left: 57.22%; top: 43.42%; }
  .entrance__wrap .enter__fancy .deco6 { width: 28.33%; left: 27%; top: 63.15%; }
  .entrance__wrap .betree_logo { width: 33.33333vw; height: 33.33333vw; } }
