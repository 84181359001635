@charset "utf-8";

.works__detail {
    .page_move {
        @include vw-convert-pc(margin, 87 0 35);
    }

    .subpage_desc {
        @include vw-convert-pc(width, 1116);
    }

    .info {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        &_list {
            @include em-font-size-pc(20);
            @include vw-convert-pc(padding-right, 20);

            li {
                @include vw-convert-pc(padding-bottom, 20);
                display: flex;
            }

            span {
                @include vw-convert-pc(width, 140);
                font-weight: bold;
                display: block;
            }

            p {
                flex: 1;
            }
        }
    }

    .button.website {
        @include vw-convert-pc(margin-bottom, 20);
    }

    .slider {
        @include vw-convert-pc(margin-top, 90);
        display: flex;
        justify-content: center;

        &_wrap {
            width: 79.82%;
            height: auto;
            @include vw-convert-pc(padding, 10);
            @include vw-convert-pc(margin-bottom, 140);
            border: 1px solid #000;
            position: relative;
        }

        .slick-arrow {
            position: absolute;
            top: 50%;

            &.slick-prev {
                transform: translate(-150%, -50%) scaleX(-1);
                right: 100%;
            }

            &.slick-next {
                transform: translate(150%, -50%);
                left: 100%;
            }
        }

        .slick-dots {
            position: absolute;
            top: 100%;
            left: 0;
            @include vw-convert-pc(padding-top, 30);
        }

        .slick-slide {
            font-size: 0;
        }

        .image {
            width: 100%;
            height: auto;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    @media (max-width: $screen-md) {
        .page_move {
            @include vw-convert-tb(margin, 67 0 35);
        }
        .subpage_desc {
            @include vw-convert-tb(width, 674);
        }
        .info {
            &_list {
                @include em-font-size-tb(14);
                @include vw-convert-tb(padding-right, 14);

                li {
                    @include vw-convert-tb(padding-bottom, 20);
                }

                span {
                    @include vw-convert-tb(width, 80);
                }
            }
        }
        .button.website {
            @include vw-convert-tb(margin-bottom, 20);
        }
        .slider {
            @include vw-convert-pc(margin-top, 90);

            &_wrap {
                width: 74%;
                @include vw-convert-tb(padding, 8);
                @include vw-convert-tb(margin-bottom, 110);
            }

            .slick-arrow {
                &.slick-prev {
                    transform: translate(-75%, -50%) scaleX(-1);
                }

                &.slick-next {
                    transform: translate(75%, -50%);
                }
            }

            .slick-dots {
                @include vw-convert-tb(padding-top, 20);
            }
        }
    }
    @media (max-width: $screen-sm) {
        .fancy__wrap {
            max-width: initial;
        }
        .page_move {
            @include vw-convert-mo(margin, 36 0 29);
        }
        .subpage_desc {
            width: 100%;
        }
        .info {
            display: block;

            &_list {
                @include em-font-size-mo(14);
                @include vw-convert-mo(padding-right, 0);

                li {
                    @include vw-convert-mo(padding-bottom, 20);
                }

                span {
                    @include vw-convert-mo(width, 70);
                }
            }
        }
        .button.website {
            @include vw-convert-mo(margin, 10 0 0);
        }
        .slider {
            @include vw-convert-mo(margin-top, 30);

            &_wrap {
                width: 100%;
                @include vw-convert-mo(padding, 8);
                // @include vw-convert-mo(padding-bottom, 0);
                @include vw-convert-mo(margin-bottom, 110);
            }

            .slick-arrow {
                top: 100%;
                left: 50%;
                right: auto;
                @include vw-convert-mo(margin-top, 23);

                &.slick-prev {
                    transform: translate(-150%, 0%) scaleX(-1);
                }

                &.slick-next {
                    transform: translate(50%, 0%);
                    left: 50%;
                }
            }

            .slick-dots {
                padding-top: 0;
                top: auto;
                bottom: 0%;
                @include vw-convert-mo(padding-bottom, 16);
            }
        }
    }

    &.vr_layer {
        .contents {
            @include vw-convert-pc(padding-left, 30);
            @include vw-convert-pc(padding-right, 30);

            .subpage_title {
                @include vw-convert-pc(padding, 30 0 30);
                @include em-font-size-pc(42);
            }

            .subpage_desc {
                width: 100%;
                @include em-font-size-pc(18);
                @include vw-convert-pc(padding-bottom, 40);
            }

            .info {
                &_list {
                    @include em-font-size-pc(16);

                    li {
                        @include vw-convert-pc(padding-bottom, 18);
                    }

                    span {
                        @include vw-convert-pc(width, 109);
                    }
                }
            }

            .button.website {
                @include vw-convert-pc(margin-bottom, 10);
            }

            .slider {
                @include vw-convert-pc(margin-top, 40);

                &_wrap {
                    width: 78%;
                    @include vw-convert-pc(padding, 8);
                    @include vw-convert-pc(margin-bottom, 82);
                }

                .slick-arrow {
                    &.slick-prev {
                        transform: translate(-75%, -50%) scaleX(-1);
                    }

                    &.slick-next {
                        transform: translate(75%, -50%);
                    }
                }

                .slick-dots {
                    @include vw-convert-pc(padding-top, 20);
                }
            }

            .button_wrap {
                text-align: center;
                @include vw-convert-pc(padding-bottom, 50);
            }

            @media (max-width: $screen-md) {
                @include vw-convert-tb(padding-left, 20);
                @include vw-convert-tb(padding-right, 20);
                .subpage_title {
                    @include vw-convert-tb(padding, 30 0 26);
                    @include em-font-size-tb(34);
                }
                .subpage_desc {
                    @include em-font-size-tb(16);
                    @include vw-convert-tb(padding-bottom, 50);
                }
                .info {
                    &_list {
                        @include em-font-size-tb(14);

                        li {
                            @include vw-convert-tb(padding-bottom, 20);
                        }

                        span {
                            @include vw-convert-tb(width, 95);
                        }
                    }
                }
                .slider {
                    &_wrap {
                        @include vw-convert-tb(margin-bottom, 80);
                    }
                }
            }
            @media (max-width: $screen-sm) {
                @include vw-convert-mo(padding-left, 20);
                @include vw-convert-mo(padding-right, 20);
                .subpage_title {
                    @include vw-convert-mo(padding, 30 0 24);
                    @include em-font-size-mo(28);
                }
                .subpage_desc {
                    @include em-font-size-mo(12);
                    @include vw-convert-mo(padding-bottom, 30);
                }
                .info {
                    &_list {
                        @include em-font-size-mo(12);

                        li {
                            @include vw-convert-mo(padding-bottom, 20);
                        }

                        span {
                            @include vw-convert-mo(width, 70);
                        }
                    }
                }
                .slider {
                    &_wrap {
                        @include vw-convert-mo(margin-bottom, 50);
                    }
                }
                .button.website {
                    @include vw-convert-mo(margin-bottom, 30);
                }
                .slider {
                    margin-top: 0;

                    &_wrap {
                        width: 100%;
                        @include vw-convert-mo(padding, 8);
                        @include vw-convert-mo(margin-bottom, 98);
                    }

                    .slick-arrow {
                        top: 100%;
                        left: 50%;
                        right: auto;
                        @include vw-convert-mo(margin-top, 23);

                        &.slick-prev {
                            transform: translate(-150%, 0%) scaleX(-1);
                        }

                        &.slick-next {
                            transform: translate(50%, 0%);
                            left: 50%;
                        }
                    }

                    .slick-dots {
                        padding-top: 0;
                        top: auto;
                        bottom: 0%;
                        @include vw-convert-mo(padding-bottom, 16);
                    }
                }
                .button_wrap {
                    text-align: center;
                    @include vw-convert-mo(padding-bottom, 50);
                }
            }
        }
    }
}