@charset "utf-8";

.blind{
    overflow:hidden;position:absolute;top:0;left:0;width:1px;height:1px;font-size:0;line-height:0
}

body{
    &.layer-opens{
        overflow: hidden;
        #wrapper{
            overflow: scroll;
        }
    }
    &.nav-opens{
        overflow: hidden;
    }
    &.search-opens{

    }
}

// form 요소 / input, label, select, checkbox, radio
.input{
    &-text{

    }
    &-label{

    }
    &-select{
        -moz-appearance:none;
        -webkit-appearance:none;
        appearance:none;
        @media (max-width: $screen-sm){

        }
    }
    &-checkbox{
        position: relative;
        input{
            width: 1px;
            height: 1px;
            overflow: hidden;
            opacity: 0;
            position: absolute;
            left: -999px;
            top: 0;
        }
        label{

        }
        input:checked + label{

        }
        @media (max-width: $screen-sm){

        }
    }
    &-radio{

    }
}

.js-select{
    display: none;
}

.select-box{
    position: relative;
    width: 100%;
    height: 100%;
    @include em-font-size-pc(18);
    z-index: 100;
    .current{
        position: relative;
        color: #000;
        background-color: #fff;
        border: 1px solid #888;
        width: 100%;
        height: 100%;
        text-align: left;
        font-weight: bold;
        @include vw-convert-pc(border-radius, 16);
        @include vw-convert-pc(padding, 0 42 0 20);
        z-index: 5;
        &:after{
            content: "";
            @include vw-convert-pc(width, 24);
            @include vw-convert-pc(height, 24);
            @include vw-convert-pc(right, 15);
            position: absolute;
            top: 50%;
            //background: url(assets/images/arrow_down.svg) no-repeat center/100% auto;
            transform: translateY(-50%);
            transition: transform .2s ease;
        }
    }
    .option-dropdown{
        position: absolute;
        left: 0;
        top: calc(100% - 1px);
        background-color: #fff;
        width: 0;
        overflow: hidden;
        transform: translateY(-30px);
        transition: transform .3s ease;
        @include vw-convert-pc(border-radius, 0 0 16 16);
        //@include vw-convert-pc(padding, 10 0);
        .item{
            display: block;
            width: 100%;
            color: #000;
            font-weight: bold;
            text-align: left;
            @include vw-convert-pc(padding, 10 20);
            &.selected{
                background-color: #f3f3f3;
            }
            &:last-child{
                @include vw-convert-pc(border-radius, 0 0 16 16);
            }
        }
    }
    &.is-active{
        .current{
            @include vw-convert-pc(border-radius, 16 16 0 0);
            &:after{
                transform: translateY(-50%) scaleY(-1);
            }
        }
        .option-dropdown{
            width: 100%;
            border: 1px solid #888;
            overflow: inherit;
            transform: translateY(0);
        }
    }
    @media (max-width: $screen-sm) {
        @include em-font-size-mo(16);
        .current{
            @include vw-convert-mo(border-radius, 12);
            @include vw-convert-mo(padding, 0 42 0 30);
            &:after{
                content: "";
                @include vw-convert-mo(width, 20);
                @include vw-convert-mo(height, 20);
                @include vw-convert-mo(right, 12);
            }
        }
        .option-dropdown{
            @include vw-convert-mo(border-radius, 0 0 12 12);
            //@include vw-convert-pc(padding, 10 0);
            .item{
                @include vw-convert-mo(padding, 10 15);
                &:last-child{
                    @include vw-convert-mo(border-radius, 0 0 12 12);
                }
            }
        }
        &.is-active{
            .current{
                @include vw-convert-mo(border-radius, 12 12 0 0);
            }
        }
    }
}


// slick slider
.slider{
    .slick-arrow{
        @include vw-convert-pc(width, 60);
        @include vw-convert-pc(height, 60);
        border: 1px solid #000;
        border-radius: 50%;
        background: url(../images/button_arrow_white.svg) no-repeat center/100% auto;
        font-size: 0;
        transition: background-color .2s ease;
        &.slick-prev{
            transform: scaleX(-1);
        }
        &:hover{
            background-color: #000;
            background-image: url(../images/button_arrow_black.svg);
        }
        @media (max-width:$screen-md){
            @include vw-convert-tb(width, 40);
            @include vw-convert-tb(height, 40);
        }
        @media (max-width: $screen-sm){
            @include vw-convert-mo(width, 40);
            @include vw-convert-mo(height, 40);
        }
    }
    .slick-dots{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        li{
            @include vw-convert-pc(margin, 0 8);
            display: inline-block;
        }
        button{
            @include vw-convert-pc(width, 12);
            @include vw-convert-pc(height, 12);
            border: 1px solid #444;
            border-radius: 50%;
            font-size: 0;
            display: block;
        }
        .slick-active button{
            @include vw-convert-pc(width, 14);
            @include vw-convert-pc(height, 14);
            background-color: #444;
        }
        @media (max-width: $screen-sm){
            li{
                @include vw-convert-mo(margin, 0 6);
            }
            button{
                @include vw-convert-mo(width, 8);
                @include vw-convert-mo(height, 8);
            }
            .slick-active button{
                @include vw-convert-mo(width, 8);
                @include vw-convert-mo(height, 8);
            }
        }
    }
}

// 공통
.main{
    // 전체 여백 설정
    &__inner{
        @include vw-convert-pc(padding-left, 120);
        @include vw-convert-pc(padding-right, 120);
        margin: 0 auto;
        @include vw-convert-pc(max-width, 1920);
        @media (max-width: $screen-md){
            @include vw-convert-tb(padding-left, 60);
            @include vw-convert-tb(padding-right, 60);
            max-width: 100%;
        }
        @media (max-width: $screen-sm){
            @include vw-convert-mo(padding-left, 20);
            @include vw-convert-mo(padding-right, 20);
            max-width: 100%;
        }
    }
}

// button
.group{
    display: flex;
    justify-content: center;
}

.button{
    //Capsule Button
    &.primary{
        display: inline-block;
        @include vw-convert-pc(padding, 15 52 13 20);
        @include vw-convert-pc(border-radius, 26);
        line-height: 1;
        @include em-font-size-pc(22);
        @include font-oscine();
        position:relative;
        border:1px solid #000;
        color:#000;
        background-color: #fff;
        transition: background-color .2s ease, color .2s ease;
        &:after{
            content:'';
            display: block;
            position: absolute;
            top:50%;
            @include vw-convert-pc(right, 20);
            transform: translateY(-50%);
            background: url(../images/arrow_black.svg) no-repeat center/100% auto;
            @include vw-convert-pc(width, 23);
            @include vw-convert-pc(height, 22);
        }
        &:hover{
            background: #000;
            color:#fff;
            &:after{
                background: url(../images/arrow_white.svg) no-repeat 100%;
            }
        }
        &.disabled{
            border:1px solid #999;
            color:#999;
            cursor: default;
            &:after{
                background: url(../images/arrow_gray.svg) no-repeat 100%;
            }
            &:hover{
                background: #fff;
                color:#999;
                &:after{
                    background: url(../images/arrow_gray.svg) no-repeat 100%;
                }
            }
        }
        &.download{
            &:after{
                background: url(../images/arrow_down_black.svg) no-repeat center/92%;
                @include vw-convert-pc(width, 22);
                @include vw-convert-pc(height, 24);
                @media (max-width:$screen-md){
                    background-size: contain;
                    @include vw-convert-tb(width, 20);
                    @include vw-convert-tb(height, 17);
                }
                @media (max-width:$screen-sm){
                    @include vw-convert-mo(width, 17);
                    @include vw-convert-mo(height, 14);
                }
            }
            &:hover{
                &:after{
                    background: url(../images/arrow_down_white.svg) no-repeat center/92%;
                    @media (max-width:$screen-md){
                        background-size: contain;
                        @include vw-convert-tb(width, 20);
                        @include vw-convert-tb(height, 17);
                    }
                    @media (max-width:$screen-sm){
                        @include vw-convert-mo(width, 17);
                        @include vw-convert-mo(height, 14);
                    }
                }
            }

        }
        &.website{
            @include vw-convert-pc(padding, 12 41 12 17);
            @include em-font-size-pc(18);
            @media (max-width:$screen-md){
                @include vw-convert-tb(padding, 12 40 12 18);
                @include em-font-size-tb(14);
            }
            @media (max-width:$screen-sm){
                @include vw-convert-mo(padding, 12 40 12 18);
                @include em-font-size-mo(14);
            }
            &:after{
                background: url(../images/arrow_website.svg) no-repeat center/100% auto;
                @include vw-convert-pc(width, 20);
                @include vw-convert-pc(height, 20);
                @media (max-width:$screen-md){
                    background-size: contain;
                    @include vw-convert-tb(width, 18);
                    @include vw-convert-tb(height, 18);
                }
                @media (max-width:$screen-sm){
                    background-size: contain;
                    @include vw-convert-mo(width, 18);
                    @include vw-convert-mo(height, 18);
                }
            }
            &:hover{
                &:after{
                    background: url(../images/arrow_website_white.svg) no-repeat center/100% auto;
                    @media (max-width:$screen-md){
                        background-size: contain;
                        @include vw-convert-tb(width, 18);
                        @include vw-convert-tb(height, 18);
                    }
                    @media (max-width:$screen-sm){
                        background-size: contain;
                        @include vw-convert-mo(width, 18);
                        @include vw-convert-mo(height, 18);
                    }
                }
            }
        }
    }
    //원형 화살표 버튼
    &.circle{
        display: block;
        background: url(../images/button_arrow_white.svg) no-repeat center/100% auto;
        @include vw-convert-pc(width, 60);
        @include vw-convert-pc(height, 60);
        border: 1px solid #000;
        border-radius: 50%;
        font-size:0;
        transition: background-color .2s ease;
        &:hover{
            background-color: #000;
            background-image: url(../images/button_arrow_black.svg);
        }
        &.disabled{
            background-image: url(../images/button_arrow_gray.svg);
            cursor: default;
            &:hover{
                background-image: url(../images/button_arrow_gray.svg);
            }
        }
    }

    @media (max-width:$screen-md){
        &.primary{
            @include vw-convert-tb(padding, 15 46 13 20);
            @include em-font-size-tb(18);
            @include vw-convert-tb(border-radius, 26);
            &:after{
                @include vw-convert-tb(right, 20);
                @include vw-convert-tb(width, 16);
                @include vw-convert-tb(height, 13);
            }
        }
        &.circle{
            @include vw-convert-tb(width, 40);
            @include vw-convert-tb(height, 40);
        }
    }
    @media (max-width: $screen-sm){
        &.primary{
            @include vw-convert-mo(padding, 12 40 10 18);
            @include em-font-size-mo(14);
            @include vw-convert-mo(border-radius, 26);
            &:after{
                @include vw-convert-mo(right, 18);
                @include vw-convert-mo(width, 14);
                @include vw-convert-mo(height, 14);
            }
        }
        &.circle{
            @include vw-convert-mo(width, 40);
            @include vw-convert-mo(height, 40);
        }
    }
}


.page_move{
    @include vw-convert-pc(padding-left, 20);
    position: relative;
    color:#000;
    @include em-font-size-pc(20);
    @include font-oscine();
    display: block;
    &:before{
        content:'';
        position:absolute;
        left:0; top:50%; transform: translateY(-50%);
        background: url('../images/arrow_page_move.svg') no-repeat center/100% auto;
        @include vw-convert-pc(width, 12);
        @include vw-convert-pc(height, 16);
    }
    @media (max-width: $screen-md){
        @include vw-convert-tb(padding-left, 20);
        @include em-font-size-tb(20);
        &:before{
            @include vw-convert-tb(width, 12);
            @include vw-convert-tb(height, 16);
        }
    }
    @media (max-width: $screen-sm){
        @include vw-convert-mo(padding-left, 20);
        @include em-font-size-mo(16);
        &:before{
            @include vw-convert-mo(width, 12);
            @include vw-convert-mo(height, 16);
        }
    }
}

.js-images{
    @media (max-width: $screen-sm){
        opacity: 0;
        &.shown{
            opacity: 1;
        }
    }
}

.btn_talk{
    position: fixed;
    @include vw-convert-pc(right, 20);
    @include vw-convert-pc(bottom, 20);
    z-index: 10001;
    cursor: pointer;
    &:after{
        content: "";
        position: relative;
        display: block;
        @include vw-convert-pc(width, 60);
        @include vw-convert-pc(height, 60);
        background: #25ADFA url(../images/viewing-talk.png) no-repeat center/100% auto;
        border: 1px solid #000;
        border-radius: 50%;
        z-index: 10;
        transition: background-color .3s ease;
    }
    .text{
        position: absolute;
        top: 0;
        @include vw-convert-pc(right, 30);
        height: 100%;
        background-color: #fff;
        @include em-font-size-pc(14);
        @include vw-convert-pc(border-top-left-radius, 30);
        @include vw-convert-pc(border-bottom-left-radius, 30);
        border: 1px solid #000;
        font-family: $primary-font;
        font-weight: normal;
        color: #000;
        width: 0;
        overflow: hidden;
        transition: width .3s ease;
        .link{
            display: flex;
            align-items: center;
            height: 100%;
            color: #000;
            @include vw-convert-pc(width, 166);
            @include vw-convert-pc(padding, 0 40 0 20);
            &:hover{
                text-decoration: underline;
            }
        }
    }
    &:hover{
        &:after{
            background-color: #0088D5;
        }
    }
    &.clicked{
        .text{
            @include vw-convert-pc(width, 166);
            @media (max-width: $screen-md){
                @include vw-convert-tb(width, 140);
            }
            @media (max-width: $screen-sm){
                @include vw-convert-mo(width, 250);
            }
        }
    }
    @media (max-width: $screen-md){
        @include vw-convert-tb(right, 20);
        @include vw-convert-tb(bottom, 20);
        &:after{
            @include vw-convert-tb(width, 50);
            @include vw-convert-tb(height, 50);
        }
        .text{
            @include vw-convert-tb(right, 25);
            @include em-font-size-tb(12);
            @include vw-convert-tb(border-top-left-radius, 25);
            @include vw-convert-tb(border-bottom-left-radius, 25);
            .link{
                @include vw-convert-tb(width, 140);
                @include vw-convert-tb(padding, 0 30 0 20);
            }
        }
    }
    @media (max-width: $screen-sm){
        @include vw-convert-mo(right, 15);
        @include vw-convert-mo(bottom, 15);
        &:after{
            @include vw-convert-mo(width, 40);
            @include vw-convert-mo(height, 40);
        }
        .text{
            @include vw-convert-mo(right, 20);
            @include em-font-size-mo(14);
            @include vw-convert-mo(border-top-left-radius, 20);
            @include vw-convert-mo(border-bottom-left-radius, 20);
            .link{
                @include vw-convert-mo(width, 250);
                @include vw-convert-mo(padding, 0 20);
            }
        }
    }
}


// common
header{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 10010;
    @include font-oscine();
    @include vw-convert-pc(height, 70);
    background-color: #fff;
    border-bottom: 1px solid #000;
    .main__inner{
        height: 100%;
        display: flex;
        align-items: center;
    }
    .logos{
        //@include vw-convert-pc(width, 116);
        //@include vw-convert-pc(height, 57);
        @include vw-convert-pc(width, 90);
        @include vw-convert-pc(height, 44);
        background: url(../images/betree_logos.svg) no-repeat center/100% auto;
        a{
            display: block;
            width: 100%;
            height: 100%;
        }
    }
    .nav__wrap{
        display: flex;
        align-items: center;
        margin-left: auto;
        @media (min-width: $screen-sm-min){
            .nav{
                order: 1;
            }
            .locale{
                order: 2;
            }
        }
    }
    .nav{
        @include vw-convert-pc(padding-right, 40);
        ul{
            display: flex;
        }
        li{
            @include vw-convert-pc(margin-left, 30);
            a{
                display: block;
                color: #000;
                @include vw-convert-pc(padding, 10 25 4);
                @include em-font-size-pc(22);
                border: 1px solid transparent;
                border-radius: 50%;
                &:hover{
                    border-color:#000;
                    transition: border-color .3s ease;
                }
            }
            .change_room{
                position:relative;
                @include vw-convert-pc(padding-right, 44);
                &:after{
                    position:absolute;
                    content:'';
                    top:50%;
                    transform: translateY(-50%);
                    @include vw-convert-pc(right, 20);
                    background: url(../images/arrow_website.svg) no-repeat center/100% auto;
                    @include vw-convert-pc(width, 20);
                    @include vw-convert-pc(height, 20);
                }
            }
        }
        &.stop_transitions li a{
            transition:  none !important;
        }
    }
    .locale{
        position: relative;
        @include vw-convert-pc(width, 76);
        button{
            width: 100%;
            text-align: left;
            @include vw-convert-pc(padding-left, 14);
            @include font-oscine();
            @include em-font-size-pc(22);
        }
        .current{
            position: relative;
            @include vw-convert-pc(padding-right, 30);
            @include vw-convert-pc(padding-top, 5);
            @include vw-convert-pc(height, 40);
            &:after{
                content: "";
                position: absolute;
                top: 50%;
                @include vw-convert-pc(right, 16);
                @include vw-convert-pc(width, 10);
                @include vw-convert-pc(height, 6);
                @include vw-convert-pc(margin-top, -3);
                background: url(../images/select_arrow.svg) no-repeat center/100% auto;
            }
        }
        .options{
            display: none;
            position: absolute;
            left: 0;
            top: 100%;
            width: 100%;
            border-radius: 3px;
            border: 1px solid #000;
            //background-color: $main_bg;
            background-color: #fff;
            .selected{
                display: none;
            }
            button{
                @include vw-convert-pc(padding, 16 14 14);
                display: flex;
                align-items: center;
            }
        }
        &.is-active{
            .current{
                &:after{
                    transform: scaleY(-1);
                }
            }
            .options{
                display: block;
            }
        }
    }
    .copyright,
    .menu_btn{
        display: none;
    }
    &.is-scroll{
        position: fixed;
        @include vw-convert-pc(height, 70);
        .logos{
            @include vw-convert-pc(width, 90);
            @include vw-convert-pc(height, 44);
        }
        .locale{
            .options{
                background-color: #fff;
            }
        }
    }


    @media (max-width: $screen-md){
        @include vw-convert-tb(height, 90);
        .logos{
            @include vw-convert-tb(width, 94);
            @include vw-convert-tb(height, 46);
        }
        .nav{
            @include vw-convert-tb(padding-right, 20);
            li{
                @include vw-convert-tb(margin-left, 10);
                a{
                    @include vw-convert-tb(padding, 10 25 4);
                    @include em-font-size-tb(18);
                }
                .change_room{
                    @include vw-convert-tb(padding-right, 32);
                    &:after{
                        @include vw-convert-tb(width, 18);
                        @include vw-convert-tb(height, 18);
                    }
                }

            }
        }
        .locale{
            @include vw-convert-tb(width, 52);
            button{
                @include vw-convert-tb(padding-left, 10);
                @include em-font-size-tb(18);
            }
            .current{
                @include vw-convert-tb(padding-right, 16);
                @include vw-convert-tb(padding-top, 5);
                @include vw-convert-tb(height, 32);
                &:after{
                    @include vw-convert-tb(right, 8);
                    @include vw-convert-tb(width, 8);
                    @include vw-convert-tb(height, 4);
                    @include vw-convert-tb(margin-top, -2);
                }
            }
            .options{
                button{
                    @include vw-convert-tb(padding, 12 8 8);
                }
            }
        }
        &.is-scroll{
            @include vw-convert-tb(height, 70);
            .logos{
                @include vw-convert-tb(width, 90);
                @include vw-convert-tb(height, 44);
            }
        }
    }

    @media (max-width: $screen-sm){
        @include vw-convert-mo(height, 80);
        .logos{
            @include vw-convert-mo(width, 80);
            @include vw-convert-mo(height, 40);
        }
        .menu_btn{
            position: relative;
            display: block;
            @include vw-convert-mo(width, 40);
            @include vw-convert-mo(height, 40);
            margin-left: auto;
            &:before,
            &:after{
                content: "";
                @include vw-convert-mo(width, 30);
                height: 2px;
                background-color: #000;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                transition: transform .2s ease;
            }
            &:before{
                @include vw-convert-mo(margin-top, -4);
            }
            &:after{
                @include vw-convert-mo(margin-top, 4);
            }
        }
        .fancy_room{
            position: absolute;
            left: 0;
            @include vw-convert-mo(top, 80);
            width: 100%;
            height: 0;
            overflow: hidden;
            &:before{
                content: "";
                width: 100%;
                height: 0;
                background-color: $main_bg;
                position: absolute;
                left: 0;
                top: 0;
            }
            .locale,
            .nav,
            .copyright{
                opacity: 0;
                transform: translateX(-30px);
            }
            .nav{
                position: relative;
                padding-right: 0;
                min-height: calc(100% - 8.33333vw - 8.88889vw);
                ul{
                    display: block;
                }
                li{
                    @include vw-convert-mo(padding-top, 60);
                    &:last-child{
                        @include vw-convert-mo(padding-bottom, 60);
                    }
                    a{
                        @include em-font-size-mo(32);
                        padding: 0;
                        border: 0;
                        display: inline-block;
                    }
                    .change_room{
                        @include vw-convert-mo(padding-right, 32);
                        &:after{
                            @include vw-convert-mo(width, 26);
                            @include vw-convert-mo(height, 26);
                        }
                    }
                }
            }
            .locale{
                @include vw-convert-mo(width, 60);
                z-index: 10;
                button{
                    @include vw-convert-mo(padding-left, 10);
                    @include em-font-size-mo(18);
                }
                .current{
                    @include vw-convert-mo(padding-right, 16);
                    @include vw-convert-mo(padding-top, 5);
                    @include vw-convert-mo(height, 32);
                    &:after{
                        @include vw-convert-mo(right, 8);
                        @include vw-convert-mo(width, 10);
                        @include vw-convert-mo(height, 6);
                        @include vw-convert-mo(margin-top, -3);
                    }
                }
                .options{
                    button{
                        @include vw-convert-mo(padding, 12 8 8);
                    }
                }
            }
            .copyright{
                position: relative;
                display: block;
                @include em-font-size-mo(14);
            }
        }
        &.is-scroll{
            @include vw-convert-mo(height, 60);
            .logos{
                @include vw-convert-mo(width, 60);
                @include vw-convert-mo(height, 30);
            }
        }
    }
}

.nav-opens header{
    @media (max-width: $screen-sm){
        background-color: $main_bg;
        border-bottom:none;
        .menu_btn{
            &:before{
                transform: translate(-50%, -50%) rotate(-45deg);
                margin: 0;
            }
            &:after{
                transform: translate(-50%, -50%) rotate(45deg);
                margin: 0;
            }
        }
        .nav__wrap{
            display: block;
            overflow-y: auto;
            height: calc(100vh - 16.66667vw);
            @include vw-convert-mo(padding, 30);
            background-color: $main_bg;
            transition: background-color 0s ease .2s;
            &:before{
                height: 100%;
                transition: height .3s ease;
            }
        }
        .locale,
        .nav,
        .copyright{
            opacity: 1;
            transform: translateX(0);
        }
        .locale{
            transition: opacity .3s ease .1s, transform .3s ease .1s;
        }
        .nav{
            transition: opacity .3s ease .2s, transform .3s ease .2s;
        }
        .copyright{
            transition: opacity .3s ease .3s, transform .3s ease .3s;
        }
        &.is-scroll{
            @include vw-convert-mo(height, 80);
            background-color: $main_bg;
            border-color: transparent;
            transition: background-color .2s ease;
            .logos{
                @include vw-convert-mo(width, 80);
                @include vw-convert-mo(height, 40);
            }
        }
    }
}

footer{
    border-top: 1px solid #000;
    //background-color: #FAF6F3;
    @include vw-convert-pc(height, 115);
    @include font-oscine();
    .main__inner{
        display: flex;
        align-items: center;
        height: 100%;
    }
    .nav{
        display: flex;
        li{
            @include vw-convert-pc(padding-right, 30);
        }
        a{
            color: #000;
            @include vw-convert-pc(padding, 10 25 4);
            border: 1px solid transparent;
            border-radius: 50%;
            transition: border-color .3s ease;
            &:hover{
                border-color:#000;
            }
        }
    }
    .info{
        margin-left: auto;
    }
    .share{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .sns{
            display: block;
            border: 1px solid #000;
            border-radius: 3px;
            @include vw-convert-pc(width, 32);
            @include vw-convert-pc(height, 32);
            background: no-repeat center/100% auto;
            @include vw-convert-pc(margin-left, 20);
            &:hover{
                background-color: #000;
            }
            &.behance{
                background-image: url(../images/sns_behance.svg);
                &:hover{
                    background-image: url(../images/sns_behance-white.svg);
                }
            }
        }
    }
    .copyright{
        @include vw-convert-pc(padding-top, 20);
        @include em-font-size-pc(16);
    }

    @media (max-width: $screen-md){
        @include vw-convert-tb(height, 115);
        .nav{
            li{
                @include vw-convert-tb(padding-right, 30);
            }
            a{
                @include vw-convert-tb(padding, 10 25 4);
            }
        }
        .share{
            .sns{
                @include vw-convert-tb(width, 36);
                @include vw-convert-tb(height, 36);
                @include vw-convert-tb(margin-left, 20);
            }
        }
        .copyright{
            @include vw-convert-tb(padding-top, 15);
            @include em-font-size-tb(16);
        }
    }

    @media (max-width: $screen-sm){
        @include vw-convert-mo(height, 174);
        .main__inner{
            display: block;
            @include vw-convert-mo(padding, 20);
        }
        .nav{
            li{
                @include vw-convert-mo(padding-right, 40);
            }
            a{
                padding: 0;
                border: 0;
            }
        }
        .info{
            margin: 0;
        }
        .share{
            @include vw-convert-mo(padding, 30 0 20);
            justify-content: flex-start;
            .sns{
                @include vw-convert-mo(width, 36);
                @include vw-convert-mo(height, 36);
                @include vw-convert-mo(margin-left, 20);
            }
        }
        .copyright{
            @include vw-convert-tb(padding-top, 15);
            @include em-font-size-tb(16);
        }
    }
}

#wrapper{
    position: relative;
}

#container{
    @include vw-convert-pc(padding-top, 70);
    overflow: hidden;
    min-height: calc(100vh - 115px);
    @media (max-width: $screen-lg){
        min-height: calc(100vh - 6.84524vw);
    }
    @media (max-width: $screen-md){
        @include vw-convert-tb(padding-top, 90);
        min-height: calc(100vh - 11.23047vw);
    }
    @media (max-width: $screen-sm){
        @include vw-convert-mo(padding-top, 80);
        min-height: calc(100vh - 48.33333vw);
    }
}

.subpage_title{
    @include vw-convert-pc(padding-bottom, 36);
    @include em-font-size-pc(72);
    @include font-oscine();
    @media (max-width: $screen-md){
        @include em-font-size-tb(42);
        @include vw-convert-tb(padding-bottom, 24);
        &.detail{
            @include vw-convert-tb(padding-bottom, 18);
        }
    }
    @media (max-width: $screen-sm){
        @include em-font-size-mo(34);
        @include vw-convert-mo(padding-bottom, 20);
        &.detail{
            @include em-font-size-mo(26);
            @include vw-convert-mo(padding-bottom, 24);
        }
    }
}
.subpage_desc{
    @include em-font-size-pc(20);
    @include vw-convert-pc(padding-bottom, 100);
    @media (max-width: $screen-md){
        @include em-font-size-tb(14);
        @include vw-convert-tb(padding-bottom, 40);
    }
    @media (max-width: $screen-sm){
        @include em-font-size-mo(12);
        @include vw-convert-mo(padding-bottom, 30);
    }
}

.checkbox{
    input{
        position:absolute;left:-1px;top:-1px;width:1px;height:1px;
        visibility:hidden;overflow:hidden;
    }
    label{
        @include vw-convert-pc(padding-left, 32);
        @include em-font-size-pc(16);
        position:relative;display:block;
        text-align: initial;
        &:before{
            content:"";position:absolute;left:0;top:50%;
            transform: translateY(-50%);
            @include vw-convert-pc(width, 20);
            @include vw-convert-pc(height, 20);
            background-repeat:no-repeat;
            background-image:url(../images/checkbox_blank.svg);
            background-size: contain;
        }
    }
    input:checked + label{
        color:#000;
        &:before{
            background-image:url(../images/checkbox_checked.svg);
        }
    }
    &.focus{
        label{
            color:#ED7878;
            &:before{
                background-image:url(../images/checkbox_blank_focus.svg);
            }
        }
    }
    @media (max-width: $screen-md){
        label{
            @include vw-convert-tb(padding-left, 32);
            @include em-font-size-tb(14);
            &:before{
                @include vw-convert-tb(width, 20);
                @include vw-convert-tb(height, 20);
            }
        }
    }
    @media (max-width: $screen-sm){
        label{
            @include vw-convert-mo(padding-left, 32);
            @include em-font-size-mo(14);
            &:before{
                @include vw-convert-mo(width, 20);
                @include vw-convert-mo(height, 20);
            }
        }
    }
}


.nav-opens .main__page header.is-scroll{
    background-color: $main_bg;
    border:none;
}

// main page: 최상단 header 높이 다름, 배경색 다름
.main__page{
    header{
        position: absolute;
        border-bottom: 0;
        background-color: $main_bg;
        @include vw-convert-pc(height, 100);
        .logos {
            @include vw-convert-pc(width, 116);
            @include vw-convert-pc(height, 57);
        }
        .locale{
            .options{
                background-color: $main_bg;
            }
        }
        &.is-scroll{
            position: fixed;
            @include vw-convert-pc(height, 70);
            background-color: #fff;
            border-bottom: 1px solid #000;
            .logos{
                @include vw-convert-pc(width, 90);
                @include vw-convert-pc(height, 44);
            }
            .locale{
                .options{
                    background-color: #fff;
                }
            }
        }
    }
    #container{
        @include vw-convert-pc(padding-top, 100);
    }
    @media (max-width:$screen-md){
        header{
            @include vw-convert-tb(height, 90);
            .logos {
                @include vw-convert-tb(width, 94);
                @include vw-convert-tb(height, 45);
            }
            &.is-scroll{
                @include vw-convert-tb(height, 70);
                .logos{
                    @include vw-convert-tb(width, 90);
                    @include vw-convert-tb(height, 45);
                }
            }
        }
        #container{
            @include vw-convert-tb(padding-top, 90);
        }
    }
    @media (max-width:$screen-sm){
        header{
            @include vw-convert-mo(height, 80);
            .logos {
                @include vw-convert-mo(width, 80);
                @include vw-convert-mo(height, 40);
            }
            &.is-scroll{
                @include vw-convert-mo(height, 60);
                .logos{
                    @include vw-convert-mo(width, 60);
                    @include vw-convert-mo(height, 30);
                }
            }
        }
        #container{
            @include vw-convert-mo(padding-top, 80);
        }
        .is-scroll{
            .nav__wrap{
                @include vw-convert-mo(top, 60);
            }
        }
    }
}

// 1400, 여백 60
.fancy__wrap{
    @include vw-convert-pc(max-width, 1520);
    @include vw-convert-pc(padding-left, 60);
    @include vw-convert-pc(padding-right, 60);
    margin: 0 auto;
    @media (max-width: $screen-md){
        max-width: 100%;
        @include vw-convert-tb(padding-left, 60);
        @include vw-convert-tb(padding-right, 60);
    }
    @media (max-width: $screen-sm){
        max-width: 100%;
        @include vw-convert-mo(padding-left, 20);
        @include vw-convert-mo(padding-right, 20);
    }
}

//1116, 여백 60
.sub-inner__wrap{
    @include vw-convert-pc(max-width, 1236);
    @include vw-convert-pc(padding-left, 60);
    @include vw-convert-pc(padding-right, 60);
    margin: 0 auto;
    @media (max-width: $screen-md){
        max-width: 100%;
        @include vw-convert-tb(padding-left, 60);
        @include vw-convert-tb(padding-right, 60);
    }
    @media (max-width: $screen-sm){
        max-width: 100%;
        @include vw-convert-mo(padding-left, 20);
        @include vw-convert-mo(padding-right, 20);
    }
}

//vr
#vr_wrapper{
    width:100%;
    height:100%;
    overflow-x:hidden;
    overflow-y:auto;
    position: relative;
}
.vr_header{
    .main__inner{
        position: relative;
    }
    .nav__wrap{
        .locale{
            order:-1;
            @include vw-convert-pc(margin-right, 36);
            @media (min-width: $screen-md-min){
                .current{
                    &:after{
                        @include vw-convert-pc(margin-top, -1);
                    }
                }
            }
        }
    }
    .nav{
        color:#000;
        padding:0;
        position: relative;
        .menu_button{
            border:1px solid #000;
            @include font-oscine();
            @include em-font-size-pc(18);
            @include vw-convert-pc(padding, 9 20);
            @include vw-convert-pc(border-radius, 26);
            line-height: 1;
            &:hover{
                background: #000;
                color:#fff;
            }
        }
        ul{
            position:absolute;
            background-color: #fff;
            top:100%;
            display: none;
            border:1px solid #000;
            text-align: right;
            right:0;
            @include vw-convert-pc(margin-top, 4);
            @include vw-convert-pc(border-radius, 5);
            @include vw-convert-pc(padding, 3 0);
            li{
                margin:0;
                @include vw-convert-pc(padding, 0 20);
            }
            a{
                display: block;
                border:none;
                white-space: nowrap;
                @include em-font-size-pc(18);
                @include vw-convert-pc(padding, 5 0);
                &:hover{
                    border:none;
                    text-decoration: underline;
                }
            }
            .change_room{
                @include vw-convert-pc(padding-right, 24);
                &:after{
                    right:0;
                }
            }

        }
        &.is-active{
            ul{
                display: block;
            }
        }
    }
    @media (min-width: $screen-md-min){

    }
    @media (max-width: $screen-md){
        @include vw-convert-tb(height, 70);
        .logos{
            @include vw-convert-tb(width, 90);
            @include vw-convert-tb(height, 45);
        }
        .nav__wrap{
            .locale{
                @include vw-convert-tb(margin-right, 30);
            }
        }
        .nav{
            .menu_button{
                @include em-font-size-tb(16);
                @include vw-convert-tb(padding, 10 20);
                @include vw-convert-tb(border-radius, 26);
            }
            ul{
                @include vw-convert-tb(margin-top, 8);
                @include vw-convert-tb(border-radius, 5);
                @include vw-convert-tb(padding, 3 0);
                li{
                    @include vw-convert-tb(padding, 0 20);
                }
                a{
                    @include em-font-size-tb(16);
                    @include vw-convert-tb(padding, 6 0);
                }
                .change_room{
                    @include vw-convert-tb(padding-right, 24);
                }

            }
        }
    }
    @media (max-width: $screen-sm){
        @include vw-convert-mo(height, 60);
        .logos{
            @include vw-convert-mo(width, 60);
            @include vw-convert-mo(height, 30);
        }
        .nav__wrap{
            .locale{
                @include vw-convert-mo(width, 50);
                @include vw-convert-mo(margin-right, 20);
                button{
                    @include em-font-size-mo(18);
                    @include vw-convert-mo(padding, 8 10);
                }
                .current{
                    @include vw-convert-mo(height, 30);
                    &:after{
                        @include vw-convert-mo(width, 10);
                        @include vw-convert-mo(height, 10);
                        // 아이폰 위치조절 - 20220323
                        @include vw-convert-mo(margin-top, -2);
                    }
                }
            }
        }
        .nav{
            .menu_button{
                @include em-font-size-mo(16);
                @include vw-convert-mo(padding, 9 20);
                @include vw-convert-mo(border-radius, 26);
            }
            ul{
                @include vw-convert-mo(margin-top, 7);
                @include vw-convert-mo(border-radius, 5);
                @include vw-convert-mo(padding, 3 0);
                li{
                    @include vw-convert-mo(padding, 0 20);
                }
                a{
                    @include em-font-size-mo(16);
                    @include vw-convert-mo(padding, 6 0);
                }
                .change_room{
                    @include vw-convert-mo(padding-right, 24);
                    &:after{
                        @include vw-convert-mo(width, 18);
                        @include vw-convert-mo(height, 18);
                    }
                }
            }
        }

    }
}
.vr_content{
    height: 99.9%;
    overflow:hidden;
    body{
        width: 100%;
        height: 100%;
        overflow:hidden;
    }
    #pano{
        width: 100%;
        height: 100%;
    }
}

.contact-loading{
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(#000, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999;
    &:after{
        content: "";
        @include vw-convert-pc(width, 100);
        @include vw-convert-pc(height, 100);
        background: url(../images/spin.svg) no-repeat center/100% auto;
        @media (max-width: $screen-sm){
            @include vw-convert-mo(width, 80);
            @include vw-convert-mo(height, 80);
        }
    }
}

// mac 아닌 다른 브라우저
.os_win{
    .button{
        &.primary{
            @include vw-convert-pc(padding, 16 52 11 20);
        }
        @media (max-width: $screen-md){
            &.primary{
                @include vw-convert-tb(padding, 16 46 11 20);
            }
        }
        @media (max-width: $screen-sm){
            &.primary{
                @include vw-convert-mo(padding, 13 40 9 18);
            }
        }
    }
    .fancy_room .change_room{
        @media (max-width: $screen-sm){
            &:after{
                @include vw-convert-mo(margin-top, -3);
            }
        }
    }
    .vr_header{
        .locale{
            @media (max-width: $screen-md){
                .current{
                    &:after{
                        @include vw-convert-tb(margin-top, -6);
                    }
                }
            }
            @media (max-width: $screen-sm){
                .current{
                    &:after{
                        @include vw-convert-mo(margin-top, -5);
                    }
                }
            }
        }
        .nav{
            .menu_button{
                @include vw-convert-pc(padding, 11 20 7);
                @media (max-width: $screen-md){
                    @include vw-convert-tb(padding, 10 20 8);
                }
                @media (max-width: $screen-sm){
                    @include vw-convert-mo(padding, 10 20 8);
                }
            }
        }
        .change_room{
            &:after{
                @include vw-convert-pc(margin-top, -2);
            }
        }
    }
}

body.stop-transitions * {
    transition: none !important;
  }