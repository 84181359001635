///********************///
///*     Colors       *///
///********************///

$black: #000;
$white: #fff;

$main_bg: #FAF6F3;

$kv_color_1: #CAD8F2;
$kv_color_2: #C9B5E3;
$kv_color_3: #B9E2DA;
$kv_color_4: #FCCFCF;
$kv_color_5: #FACE87;
$kv_color_6: #E9F29E;
$kv_color_7: #F8E6D9;
$kv_color_8: #D5D5D5;


///********************///
///*    Break Points  *///
///********************///
$screen-xsm: 280px;
$screen-sm: 768px;
$screen-sm-min: 769px;
$screen-md: 1024px;
$screen-md-min: 1025px;
$screen-lg: 1680px;
$screen-xlg: 1920px;

///********************///
///*       Fonts      *///
///********************///

$primary-font: 'Noto Sans KR', Helvetica, 'malgun gothic', 'Apple SD Gothic Neo', 'Microsoft NeoGothic', 'Droid sans', sans-serif;
$oscine: oscine, Helvetica, 'malgun gothic', 'Apple SD Gothic Neo', 'Microsoft NeoGothic', 'Droid sans', arial, sans-serif;
