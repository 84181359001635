@charset "utf-8";

.works{
    &__wrap{
        @include font-oscine();
    }
    &__title{
        @include em-font-size-pc(72);
        @include vw-convert-pc(padding, 104 0 56);
        text-align: center;
        @media(max-width:$screen-md){
            @include em-font-size-tb(42);
            @include vw-convert-tb(padding, 74 0 48);
        }
        @media(max-width:$screen-sm){
            @include em-font-size-mo(34);
            @include vw-convert-mo(padding, 30 0 40);
        }
    }
    &__tab{ 
        position: relative;
        @include vw-convert-pc(margin-bottom, 160);
        @media(max-width:$screen-md){
            @include vw-convert-tb(margin-bottom, 100);
        }
        @media(max-width:$screen-sm){
            @include vw-convert-mo(margin-bottom, 30);
        }
        .tab_selected{
            display: none;
        }
        &_menu{
            position: relative;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            border: 1px solid #000;
            width: 100%;
            //@include vw-convert-pc(height, 60);
            @include vw-convert-pc(border-radius, 30);
            .tab{
                @include em-font-size-pc(16);
                @include vw-convert-pc(border-radius, 30);
                font-family: $primary-font;
                width: calc(100% / 7);
                //height: 100%;
                @include vw-convert-pc(height, 60);
                a{
                    font-weight: 500;
                    color: #000;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            .is-active{
                background-color: #000;
                a{
                    color: #fff;
                }
            }
            @media(max-width:$screen-md){
                //@include vw-convert-tb(height, 50);
                @include vw-convert-tb(border-radius, 25);
                .tab{
                    @include em-font-size-tb(16);
                    @include vw-convert-tb(height, 50);
                    @include vw-convert-tb(border-radius, 25);
                    a{
                        font-weight: 400;
                    }
                }
            }
        }
        @media(max-width:$screen-sm){
            .tab_selected{
                display: block;
                @include em-font-size-mo(16);
                @include vw-convert-mo(height, 44);
                @include vw-convert-mo(padding, 0 40 0 12);
                @include vw-convert-mo(border-radius, 3);
                border: 1px solid #555;
                width: 100%;
                position: relative;
                text-align: left;
                z-index: 2;
                &:before{
                    content: '';
                    background: url(../images/works_arrow_open.svg)no-repeat center/100% auto;
                    @include vw-convert-mo(width, 20);
                    @include vw-convert-mo(height, 20);
                    position: absolute;
                    top: 50%;
                    @include vw-convert-mo(right, 12);
                    transform: translateY(-50%);
                    transition: transform .2s ease;
                }
            }
            &_menu{
                width: 0;
                height: auto;
                border: 0;
                position: absolute;
                top: calc(100% - 1px);
                left: 0;
                overflow: hidden;
                transform: translateY(-30px);
                transition: transform .3s ease;
                z-index: 1;
                .tab{
                    font-family: $primary-font;
                    width: 100%;
                    @include em-font-size-mo(16);
                    display: block;
                    height: auto;
                    a{
                        display: block;
                        font-weight: 400;
                        color: #000;
                        width: 100%;
                    }
                }
                .is-active{
                    background-color: rgba(0, 0, 0, 0);
                    display: flex;
                    justify-content: flex-start;
                    border-radius: 0;
                    a{
                        color: #000;
                        width: 100%;
                    }
                }
            }
            &.active{
                .tab_selected{
                    @include vw-convert-mo(border-radius, 3 3 0 0);
                    background-color: #fff;
                    &::before{
                        transform: translateY(-50%) scaleY(-1);
                    }
                }
                .works__tab_menu{
                    width: 100%;
                    border: 1px solid #555;
                    background-color: #fff;
                    overflow: inherit;
                    display: flex;
                    flex-direction: column;
                    transform: translateY(0);
                    @include vw-convert-mo(border-radius, 0 0 3 3);
                    a{
                        @include vw-convert-mo(padding, 10 12);
                        width: 100%;
                    }
                    .is-active{
                        display: none;
                    }
                }
            }    
        }
    }
    &__list{
        display: flex;
        flex-wrap: wrap;
        @include vw-convert-pc(margin-right, -84);
        &-item{
            background-color: #fff;
            position: relative;
            width: calc(33.3% - 84px);
            @include vw-convert-pc(margin-bottom, 100);
            @include vw-convert-pc(margin-right, 84);
            display: flex;
            flex-direction: column;
            &:nth-of-type(3n-1){
                @include vw-convert-pc(margin-top, -60);
                @include vw-convert-pc(margin-bottom, 160);
            }
            // &:nth-of-type(3n){
            //     @include vw-convert-pc(margin-right, 0);
            // }
            &:before{
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-color: #fff;
                border: 1px solid #000;
                z-index: -1;
                box-sizing: border-box;
            }
            .images{
                @include vw-convert-pc(padding, 10);
                //flex-grow: 1;
                img{
                    width: 100%;
                }
                @media(max-width:$screen-md){
                    @include vw-convert-tb(padding, 8);
                }
                @media(max-width:$screen-sm){
                    @include vw-convert-mo(padding, 5);
                }
            }
            .link{
                color:#000;
            }
            .content{
                //flex-grow: 2;
                @include vw-convert-pc(padding, 10 20 20 20);
                @include vw-convert-pc(height, 280);
                .year{
                    @include em-font-size-pc(20);
                    @include vw-convert-pc(padding-bottom, 16);
                    line-height: 1;
                }
                .title{
                    @include em-font-size-pc(28);
                    @include ellipsis(3);
                    @include vw-convert-pc(min-height, 126);
                    @include vw-convert-pc(margin-bottom, 30);
                    line-height: 1.5;
                }
                .category{
                    @include em-font-size-pc(16);
                    @include vw-convert-pc(margin-right, 90);
                    @include ellipsis(2);
                }
                .circle{
                    position: absolute;
                    @include vw-convert-pc(right, 20);
                    @include vw-convert-pc(bottom, 20);
                }
            }
            .shadow{
                &::before,
                &::after{
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    transition: 0.15s cubic-bezier(0.17, 0.67, 0.83, 0.67);
                    border: 1px solid #000;
                    box-sizing: border-box;
                }
                &::before{
                    z-index: -2;
                    left: 0;
                    top: 0;
                    background-color: #F2B218;
                }
                &::after{
                    z-index: -3;
                    left: 0;
                    top: 0;
                    background-color: #E57C01;
                }
            }
            &:hover .shadow::before{
                @include vw-convert-pc(top, 10);
                @include vw-convert-pc(left, 10);
            }
            &:hover .shadow::after{
                @include vw-convert-pc(top, 20);
                @include vw-convert-pc(left, 20);
            }
            opacity: 0;
            transform: translateY(50px);
            transition: .3s ease;
            transition-property: opacity, transform;
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3){
                opacity: 1;
                transform: translateY(0);
                transition-duration: 0s;
            }
            &.on{
                opacity: 1;
                transform: translateY(0);
            }
        }
        @media(max-width:$screen-lg){
            &-item{
                width: calc(33.3% - 5vw);
            }
        }
        @media(max-width:$screen-md){
            @include vw-convert-tb(margin-right, -47);
            &-item{
                width: calc(33.3% - 4.5898vw);
                // @include vw-convert-tb(width, 270);
                @include vw-convert-tb(margin-bottom, 80);
                @include vw-convert-tb(margin-right, 47);
                &:nth-of-type(3n-1){
                    @include vw-convert-tb(margin-top, -40);
                    @include vw-convert-tb(margin-bottom, 120);
                }
                // &:nth-of-type(3n){
                //     @include vw-convert-tb(margin-right, 0);
                // }
                &:hover .shadow::before{
                    @include vw-convert-tb(top, 8);
                    @include vw-convert-tb(left, 8);
                }
                &:hover .shadow::after{
                    @include vw-convert-tb(top, 16);
                    @include vw-convert-tb(left, 16);
                }
                .content{
                    @include vw-convert-tb(padding, 8 16 16 16);
                    @include vw-convert-tb(height, 180);
                    .year{
                        @include em-font-size-tb(16);
                        @include vw-convert-tb(padding-bottom, 12);
                    }
                    .title{
                        @include em-font-size-tb(18);
                        @include vw-convert-tb(min-height, 82);
                        @include vw-convert-tb(margin-bottom, 5);
                    }
                    .category{
                        @include em-font-size-tb(14);
                        @include vw-convert-tb(margin-right, 50);
                    }
                    .circle{
                        @include vw-convert-tb(right, 16);
                        @include vw-convert-tb(bottom, 16);
                    }
                }
            }
        }
        @media(max-width:$screen-sm){
            display: block;
            width: 100%;
            &-item{
                width: 100%;
                @include vw-convert-mo(margin-bottom, 30);
                &:nth-of-type(3n-1){
                    margin-top: 0;
                    @include vw-convert-mo(margin-bottom, 30);
                }
                &:hover .shadow::before{
                    @include vw-convert-mo(top, 8);
                    @include vw-convert-mo(left, 8);
                }
                &:hover .shadow::after{
                    @include vw-convert-mo(top, 16);
                    @include vw-convert-mo(left, 16);
                }
                .content{
                    @include vw-convert-mo(padding, 5 16 10 16);
                    height: auto;
                    .year{
                        @include em-font-size-mo(16);
                        @include vw-convert-mo(padding-bottom, 16);
                    }
                    .title{
                        @include em-font-size-mo(18);
                        @include vw-convert-mo(min-height, 82);
                        @include vw-convert-mo(margin-bottom, 20);
                    }
                    .category{
                        @include em-font-size-mo(14);
                        @include vw-convert-mo(margin-right, 100);
                    }
                    .circle{
                        @include vw-convert-mo(right, 16);
                        @include vw-convert-mo(bottom, 16);
                    }
                }
                &:nth-child(1){
                    opacity: 1;
                    transition-duration: 0s;
                    transform: translateY(0);
                }
                &:nth-child(2),
                &:nth-child(3){
                    opacity: 0;
                    transform: translateY(50px);
                    transition-duration: .3s;
                }
                &.on{
                    opacity: 1;
                    transform: translateY(0);
                }
            }    
        }
    }
}

.pagination{
    display: flex;
    justify-content: center;
    align-items: center;
    @include vw-convert-pc(margin-bottom, 100);
    li{
        @include vw-convert-pc(margin-right, 16);
        a{
            @include vw-convert-pc(width, 26);
            @include vw-convert-pc(height, 26);
            color: #000;
            @include em-font-size-pc(16);
            line-height: 1;
            background: rgba(0, 0, 0, 0);
            border-radius: 50%;
            font-weight: 400;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: $primary-font;
        }
        &.icon{
            @include vw-convert-pc(width, 20);
            @include vw-convert-pc(height, 20);
        }
        &.prev{
            background: url(../images/works_arrow_left.svg)no-repeat center/100% auto;
        }
        &.next{
            background: url(../images/works_arrow_right.svg)no-repeat center/100% auto;
        }
        &.active{
            z-index: 1;
            border-radius:50%;
            a{
                background-color: #000;
                color: #fff;
            }
        }
    }
    @media(max-width:$screen-md){
        @include vw-convert-tb(margin-bottom, 80);
        li{
            @include vw-convert-tb(margin-right, 16);
            a{
                @include vw-convert-tb(width, 26);
                @include vw-convert-tb(height, 26);
                display: flex;
                justify-content: center;
                align-items: center;
            }
            &.icon{
                @include vw-convert-tb(width, 20);
                @include vw-convert-tb(height, 20);
            }
            &.active{
                z-index: 1;
                border-radius:50%;
                a{
                    background-color: #000;
                    color: #fff;
                }
            }
        }
    }
    @media(max-width:$screen-sm){
        @include vw-convert-mo(padding, 20 0 80);
        li{
            @include vw-convert-mo(margin-right, 16);
            &:last-child{
                margin-right: 0;
            }
            a{
                @include em-font-size-mo(16);
                @include vw-convert-mo(width, 26);
                @include vw-convert-mo(height, 26);
            }
            &.icon{
                @include vw-convert-mo(width, 20);
                @include vw-convert-mo(height, 20);
            }
            &.active{
                a {
                    @include em-font-size-mo(16);
                }
            }
        }
    }
}
