@charset "utf-8";
.contact__wrap{
  .fancy__wrap{
    @include vw-convert-pc(padding-left, 131);
    @include vw-convert-pc(padding-right, 131);
  }
  .subpage_title{
    @include vw-convert-pc(padding-top, 77);
    text-align: center;
  }
  .subpage_sub_title{
    @include em-font-size-pc(28);
    @include vw-convert-pc(padding-bottom, 20);
    text-align: center;
    font-weight: bold;
  }
  .subpage_desc{
    text-align: center;
  }
  .info{
    border-top:1px solid #555;
    @include vw-convert-pc(margin-bottom, 50);
    li{
      border-bottom:1px solid #555;
      @include vw-convert-pc(padding, 27 10);
      display: flex;
      @include em-font-size-pc(28);
    }
    span{
      @include vw-convert-pc(width, 185);
      font-weight: bold;
    }
  }
  .button_wrap{
    text-align: center;
    @include vw-convert-pc(padding-bottom, 100);
    .download{
      @include vw-convert-pc(margin, 0 25);
    }
  }
  .form{
    border:1px solid #000;
    border-radius: 3px;
    @include vw-convert-pc(margin-bottom, 100);
    position: relative;
    .hidden{
      display: none;
    }
    &_title{
      @include vw-convert-pc(padding, 20 30);
      border-bottom:1px solid #000;
      font-weight: bold;
    }
    &_wrap{
      @include vw-convert-pc(padding, 30 30 0 30);

      li{
        @include vw-convert-pc(margin-bottom, 30);
        .input{
          width:100%;
          border:1px solid #aaa;
          border-radius: 3px;
          &::placeholder{
            color:#AAA;
          }
        }
        &.fill{
          position: relative;
          .input{
            border:1px solid #000;
            &::placeholder{
              color:#fff;
            }
          }
          .label{
            display: block;
            position: absolute;
            top: 0;
            transform: translateY(-50%);
            background: #fff;
            @include vw-convert-pc(left, 9);
            @include vw-convert-pc(padding, 4);
            @include em-font-size-pc(12);
          }
        }
        .email{
          @include vw-convert-pc(padding, 22 12);
          @include em-font-size-pc(18);
        }
        .message{
          @include vw-convert-pc(padding, 12);
          @include vw-convert-pc(height, 300);
          @include em-font-size-pc(16);
          resize:none;
          &::-webkit-scrollbar {
            width: 5px;
          }
          &::-webkit-scrollbar-track {
              background-color: transparent;
          }
          &::-webkit-scrollbar-thumb {
              border-radius: 8px;
              background-color: rgba(0, 0, 0, 0.1);
          }
        }
      }
      .checkbox label{
        @include vw-convert-pc(width, 300);
        @include em-font-size-pc(14);
        .term_open_btn{
          @include em-font-size-pc(14);
          text-decoration: underline;
        }
      }
    }
    .button.normal{
      @include vw-convert-pc(padding, 14 40);
      position:absolute;
      @include vw-convert-pc(right, 30);
      @include vw-convert-pc(bottom, 30);
      @include em-font-size-pc(18);
      font-family: $primary-font;
      &:after{
        display: none;
      }
    }
  }
  @media (max-width:$screen-md){
    .fancy__wrap{
      @include vw-convert-tb(padding-left, 175);
      @include vw-convert-tb(padding-right, 175);
    }
    .subpage_title{
      @include vw-convert-tb(padding-top, 73);
    }
    .subpage_sub_title{
      @include em-font-size-tb(16);
      @include vw-convert-tb(padding-bottom, 14);
    }
    .info{
      @include vw-convert-tb(margin-bottom, 40);
      li{
        @include vw-convert-tb(padding, 18 10);
        @include em-font-size-tb(18);
      }
      span{
        @include vw-convert-tb(width, 105);
      }
    }
    .button_wrap{
      @include vw-convert-tb(padding-bottom, 40);
      .download{
        @include vw-convert-tb(margin, 0 20);
      }
    }
    .form{
      @include vw-convert-tb(margin-bottom, 80);
      &_title{
        @include vw-convert-tb(padding, 20 30);
      }
      &_wrap{
        @include vw-convert-tb(padding, 30 30 0 30);
        li{
          @include vw-convert-tb(margin-bottom, 20);
          &.fill{
            .label{
              @include vw-convert-tb(left, 9);
              @include vw-convert-tb(padding, 4);
              @include em-font-size-tb(11);
            }
          }
          .email{
            @include vw-convert-tb(padding, 14 12);
            @include em-font-size-tb(16);
          }
          .message{
            @include vw-convert-tb(padding, 12);
            @include vw-convert-tb(height, 250);
            @include em-font-size-tb(14);
          }
        }
        .checkbox label{
          @include vw-convert-tb(width, 277);
        }
      }
      .button.normal{
        @include vw-convert-tb(padding, 12 30);
        @include vw-convert-tb(right, 30);
        @include vw-convert-tb(bottom, 30);
        @include em-font-size-tb(14);
      }
    }
  }
  @media (max-width: $screen-sm){
    .fancy__wrap{
      @include vw-convert-mo(padding-left, 20);
      @include vw-convert-mo(padding-right, 20);
    }
    .subpage_title{
      @include vw-convert-mo(padding-top, 30);
    }
    .subpage_sub_title{
      @include em-font-size-mo(14);
      @include vw-convert-mo(padding-bottom, 20);
    }
    .subpage_desc{
      .break_mo{
        display: block;
      }
    }
    .info{
      @include vw-convert-mo(margin-bottom, 40);
      li{
        @include vw-convert-mo(padding, 12 5);
        @include em-font-size-mo(12);
      }
      span{
        @include vw-convert-mo(width, 60);
      }
      p{
        font-weight: normal;
      }
    }
    .button_wrap{
      @include vw-convert-mo(padding-bottom, 25);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .download{
        @include vw-convert-mo(margin, 0 0 25 0);
      }
    }
    .form{
      border:none;
      @include vw-convert-mo(margin-bottom, 144);
      &_title{
        display: none;
      }
      &_wrap{
        @include vw-convert-mo(padding, 0);
        li{
          @include vw-convert-mo(margin-bottom, 20);
          &.fill{
            .label{
              @include vw-convert-mo(left, 9);
              @include vw-convert-mo(padding, 4);
              @include em-font-size-mo(11);
            }
          }
          .email{
            @include vw-convert-mo(padding, 14 12);
            @include vw-convert-mo(margin-bottom, 10);
            @include em-font-size-mo(16);
          }
          .message{
            @include vw-convert-mo(padding, 12);
            @include vw-convert-mo(height, 250);
            @include em-font-size-mo(16);
          }
        }
        .checkbox label{
          @include vw-convert-mo(width, 277);
        }
      }
      .button.normal{
        width:100%;
        @include vw-convert-mo(padding, 12 30);
        @include vw-convert-mo(left, 0);
        @include vw-convert-mo(right, 0);
        @include vw-convert-mo(bottom, -94);
        @include em-font-size-mo(14);
      }
    }
  }
  &.vr_layer{
    .layer_inner{
      display: none;

      &.is-active{
        display: block;
      }
    }
    .contents{
      display: flex;
      @include vw-convert-pc(padding-left, 30);
      @include vw-convert-pc(padding-right, 50);
      @include vw-convert-pc(padding-bottom, 25);
      margin: initial;
    }
    .left_area{
      width:36.7%;
      @include vw-convert-pc(margin-right, 70);
      height:100%;
    }
    .subpage_title{
      @include em-font-size-pc(58);
      @include vw-convert-pc(padding-top, 15);
      @include vw-convert-pc(padding-bottom, 15);
      text-align: left;
    }
    .subpage_sub_title{
      @include em-font-size-pc(18);
      @include vw-convert-pc(padding-bottom, 16);
      text-align: left;
    }
    .subpage_desc{
      text-align: left;
      @include em-font-size-pc(16);
    }
    .info{
      @include vw-convert-pc(margin-bottom, 25);
      li{
        @include vw-convert-pc(padding, 12 5);
        @include em-font-size-pc(16);
      }
      span{
        @include vw-convert-pc(width, 70);
      }
    }
    .button_wrap{
      display:flex;
      flex-direction: column;
      align-items: flex-start;
      padding-bottom:0;
      .download{
        @include vw-convert-pc(margin, 15 0);
      }
    }
    .form{
      flex:1;
      border:none;
      @include vw-convert-pc(padding-top, 127);
      @include vw-convert-pc(margin-bottom, 0);
      height:100%;
      form{
        position: relative;
        &.invisible{
          border:1px solid #aaa;
          border-radius: 5px;
          @include vw-convert-pc(height, 489);
          display: flex;
          justify-content: center;
          align-items: center;
          ul{
            display: none;
          }
          #contact_submit{
            display: none;
          }
          .layer_confirm{
            text-align: center;
          }
          .button_wrap{
            flex-direction: row;
            justify-content: center;
          }
        }
      }
      &_title{
        display:none;
      }
      &_wrap{
        @include vw-convert-pc(padding, 0);
        li{
          @include vw-convert-pc(margin-bottom, 20);
          .email{
            @include vw-convert-pc(padding, 17 12);
            @include em-font-size-pc(18);
          }
          .message{
            @include vw-convert-pc(height, 280);
            @include em-font-size-pc(16);
          }
        }
      }
      .button.normal{
        @include vw-convert-pc(padding, 10 26);
        @include vw-convert-pc(right, 0);
        @include vw-convert-pc(bottom, 0);
        @include em-font-size-pc(16);
      }

    }
    .inner_privacy{
      @include vw-convert-pc(border-radius, 5);
      @include vw-convert-pc(width, 834);
      .top{
        @include vw-convert-pc(padding, 29 30 25 30);
        @include em-font-size-pc(20);
        border-bottom:none;
        .title{
          @include vw-convert-pc(padding-left, 10);
        }
        .prev_btn{
          font-size: 0;
          @include vw-convert-pc(width, 40);
          @include vw-convert-pc(height, 40);
          background: url('../images/arrow_prev.svg') no-repeat center/100% auto;
        }
      }
      .contents{
          @include vw-convert-pc(padding-top, 84);
          display: block;
          @include em-font-size-pc(16);
      }
      .button_wrap{
        display: flex;
        align-items: center;
          @include vw-convert-pc(padding, 50 0 15);
          .agree_btn{
              font-weight: bold;
              @include vw-convert-pc(width, 311);
              @include vw-convert-pc(padding, 12);
              text-align: center;
              @include em-font-size-pc(16);
              &:after{
                  display: none;
              }
          }
      }
    }
    @media (max-width:$screen-md){
      .contents{
        @include vw-convert-tb(padding, 76 20 37 20);
      }
      .left_area{
        @include vw-convert-tb(margin-right, 36);
      }
      .subpage_title{
        @include em-font-size-tb(42);
        @include vw-convert-tb(padding-top, 30);
        @include vw-convert-tb(padding-bottom, 24);
      }
      .subpage_sub_title{
        @include em-font-size-tb(14);
        @include vw-convert-tb(padding-bottom, 20);
      }
      .subpage_desc{
        @include em-font-size-tb(12);
      }
      .info{
        @include vw-convert-tb(margin-bottom, 28);
        li{
          @include vw-convert-tb(padding, 12 5);
          @include em-font-size-tb(12);
        }
        span{
          @include vw-convert-tb(width, 60);
        }
      }
      .button_wrap{
        .download{
          @include vw-convert-tb(margin, 12 0);
        }
      }
      .form{
        @include vw-convert-tb(padding-top, 94);
        &_wrap{
          li{
            @include vw-convert-tb(margin-bottom, 20);
            .email{
              @include vw-convert-tb(padding, 18 12);
              @include em-font-size-tb(16);
            }
            .message{
              @include vw-convert-tb(height, 180);
              @include em-font-size-tb(16);
            }
          }
        }
        .button.normal{
          @include vw-convert-tb(padding, 12 30);
          @include em-font-size-tb(14);
          bottom:0;
        }
        form{
          &.invisible{
            @include vw-convert-tb(height, 373);
          }
        }
      }
      .inner_privacy{
        @include vw-convert-tb(width, 674);
        @include vw-convert-tb(border-radius, 3);
        .top{
          @include vw-convert-tb(padding, 24 18 20 18);
          @include em-font-size-tb(20);
          .title{
            @include vw-convert-tb(padding-left, 10);
          }
          .prev_btn{
            @include vw-convert-tb(width, 40);
            @include vw-convert-tb(height, 40);
          }
        }
        .contents{
            @include vw-convert-tb(padding-top, 73);
            padding-bottom:0;
            @include em-font-size-tb(16);
        }
        .button_wrap{
            @include vw-convert-tb(padding, 30 0);
            .agree_btn{
                @include vw-convert-tb(width, 252);
                @include vw-convert-tb(padding, 12);
                @include em-font-size-tb(14);
            }
        }
      }
    }
    @media (max-width: $screen-sm){
      .contents{
        display: block;
        @include vw-convert-mo(padding, 66 20 50 20);
      }
      .left_area{
        margin-right : 0;
        width:100%;
      }
      .subpage_title{
        @include em-font-size-mo(34);
        @include vw-convert-mo(padding-top, 20);
        @include vw-convert-mo(padding-bottom, 10);
      }
      .subpage_sub_title{
        @include em-font-size-mo(14);
        @include vw-convert-mo(padding-bottom, 20);
      }
      .subpage_desc{
        @include em-font-size-mo(12);
      }
      .info{
        @include vw-convert-mo(margin-bottom, 28);
        li{
          @include vw-convert-mo(padding, 12 5);
          @include em-font-size-mo(12);
        }
        span{
          @include vw-convert-mo(width, 60);
        }
      }
      .button_wrap{
        .download{
          @include vw-convert-mo(margin, 12 0);
        }
      }
      .form{
        @include vw-convert-mo(padding-top, 32);
        form{
          @include vw-convert-mo(padding-bottom, 44);
          &.invisible{
            @include vw-convert-mo(height, 555);
          }
        }
        &_wrap{
          li{
            @include vw-convert-mo(margin-bottom, 20);
            .email{
              @include vw-convert-mo(padding, 14 12);
              @include em-font-size-mo(16);
              @include vw-convert-mo(margin-bottom, 10);
            }
            .message{
              @include vw-convert-mo(height, 250);
              @include em-font-size-mo(14);
            }
          }
        }
        .button.normal{
          width:100%;
          @include vw-convert-mo(padding, 12);
          @include em-font-size-mo(14);
        }
      }
      .inner_privacy{
        border-radius: none;
        width:100%;
        .top{
          @include vw-convert-mo(padding, 21 10 25 10);
          @include em-font-size-mo(18);
          .title{
            @include vw-convert-mo(padding-left, 8);
          }
          .prev_btn{
            @include vw-convert-mo(width, 36);
            @include vw-convert-mo(height, 36);
          }
        }
        .contents{
          // max-height: initial;
          padding-bottom:0;
            @include em-font-size-mo(14);
        }
        .button_wrap{
            @include vw-convert-mo(padding, 40 0);
            .agree_btn{
                width:100%;
                @include vw-convert-mo(padding, 12);
                @include em-font-size-mo(14);
            }
        }
      }
    }
  }
}


