.entrance_content {
    height: 99.95%;
    // overflow: hidden;
    body {
        width: 100%;
        height: 100%;
        // overflow: hidden;
    }

    #wrapper {
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        position: relative;
        @include vw-convert-pc(min-height, 920);
    }

    @media (min-width: $screen-sm-min) {
        body {
            min-height: 100vh;
        }
    }
    @media (max-width: $screen-sm) {
        #wrapper {
            @include vw-convert-mo(min-height, 760);
        }
    }

}

.entrance__wrap {
    background-color: #FAF6F3;
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    text-align: center;

    .enter {
        width: 50%;
        position: relative;

        &_textbox {
            display: inline-block;
            position: relative;
            @include vw-convert-pc(width, 337);
            @include vw-convert-pc(height, 100);
            @include vw-convert-pc(margin-top, 41);

            span {
                @include em-font-size-pc(36);
                font-family: $primary-font;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: bold;
                @include vw-convert-pc(border-radius, 60);
                border: 1px solid #000;
                position: absolute;
                color: #000;
                width: 100%;
                height: 100%;
                background: #fff;
            }

            &:before {
                position: absolute;
                width: 100%;
                height: 100%;
                background: #AAA;
                content: '';
                @include vw-convert-pc(border-radius, 60);
                @include vw-convert-pc(top, 9);
                @include vw-convert-pc(left, 6);
                border: 1px solid #000;

            }
        }

        &__vr {
            .vr_bg {
                position: absolute;
                width: 100%;
                height: 100%;
            }

            #pano {
                width: 100%;
                height: 100%;
                position: absolute;
            }

        }

        &__fancy {
            position: relative;

            .deco {
                &_item {
                    position: absolute;

                    span {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 0;
                        background: no-repeat center/100% auto;
                    }
                }
            }

            .deco1 {
                width: 16%;
                left: 36%;
                top: 24.1%;

                span {
                    padding-top: 104.5%;
                    background-image: url(../images/index_deco1.svg);
                    animation: deco1 5s linear infinite;
                }

                @keyframes deco1 {
                    0% {
                        transform: rotate(0deg);
                    }
                    25% {
                        transform: rotate(65deg);
                    }
                    50% {
                        transform: rotate(0deg);
                    }
                    75% {
                        transform: rotate(-65deg);
                    }
                }
            }

            .deco2 {
                width: 8.12%;
                left: 26.66%;
                top: 37.42%;

                .deco2_1 {
                    stroke-dashoffset: 106;
                    stroke-dasharray: 106;
                    animation: deco2-line 0.9s forwards;
                }

                .deco2_2 {
                    stroke-dashoffset: 45;
                    stroke-dasharray: 45;
                    animation: deco2-line 0.5s 0.8s forwards;
                }

                @keyframes deco2-line {
                    100% {
                        stroke-dashoffset: 0;
                    }
                }
            }

            .deco3 {
                width: 12%;
                left: 71%;
                top: 53%;

                span {
                    padding-top: 103.17%;
                    background-image: url(../images/index_deco3.svg);
                    animation: deco3 2.5s ease-in infinite;
                }

                @keyframes deco3 {
                    0% {
                        transform: scale(1);
                    }
                    50% {
                        transform: scale(.2);
                    }
                }
            }

            .deco4 {
                width: 17.4%;
                left: 58.12%;
                top: 74.79%;
                height: 18%;

                z-index: 5;

                span {
                    padding-top: 100%;
                    background-image: url(../images/index_deco4.svg);
                    animation: deco4 5s linear infinite;
                }

                @keyframes deco4 {
                    0% {
                        transform: rotate(0deg);
                    }
                    100% {
                        transform: rotate(360deg);
                    }
                }
            }

            .deco5 {
                width: 17.2%;
                left: 63.75%;
                top: 27.62%;

                span {
                    padding-top: 125.4%;
                    background-image: url(../images/index_deco_1.svg);
                    animation: deco5 1s ease-in-out forwards;
                    opacity: 0;
                }

                @keyframes deco5 {
                    0% {
                        opacity: 0;
                    }
                    100% {
                        opacity: 1;
                    }
                }
            }

            .deco6 {
                width: 34.4%;
                left: 27.3%;
                top: 52.47%;

                span {
                    padding-top: 100%;
                    background-image: url(../images/index_deco_2.svg);
                    animation: deco5 1.5s 0.5s ease-in-out forwards;
                    opacity: 0;
                }

                @keyframes deco5 {
                    0% {
                        opacity: 0;
                    }
                    100% {
                        opacity: 1;
                    }
                }
            }
        }
    }

    .betree_logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @include vw-convert-pc(width, 242);
        @include vw-convert-pc(height, 242);

        img {
            width: 100%;
            height: 100%;
        }

    }

    @media (max-width: $screen-md) {
        .enter {
            &_textbox {
                display: inline-block;
                position: relative;
                @include vw-convert-tb(width, 251);
                @include vw-convert-tb(height, 74);
                @include vw-convert-tb(margin-top, 60);

                span {
                    @include em-font-size-tb(24);
                    @include vw-convert-tb(border-radius, 60);
                }

                &:before {
                    @include vw-convert-tb(border-radius, 60);
                    @include vw-convert-tb(top, 7);
                    @include vw-convert-tb(left, 5);
                }
            }

            &__fancy {
                .deco1 {
                    width: 16%;
                    left: 34.8%;
                    top: 34.59%;
                }

                .deco2 {
                    width: 8.3%;
                    left: 25%;
                    top: 43.61%;
                }

                .deco3 {
                    width: 12.1%;
                    left: 69.74%;
                    top: 54.68%;
                }

                .deco4 {
                    width: 17.57%;
                    left: 56.64%;
                    top: 69%;
                    height: 11%;
                }

                .deco5 {
                    width: 17.38%;
                    left: 62.42%;
                    top: 37%;
                }

                .deco6 {
                    width: 34.76%;
                    left: 25.58%;
                    top: 53.9%;
                }
            }
        }
        .betree_logo {
            @include vw-convert-tb(width, 178);
            @include vw-convert-tb(height, 178);
        }
    }

    @media (max-width: $screen-sm) {
        display: block;
        .enter {
            width: 100%;
            height: 50%;

            &_textbox {
                @include vw-convert-mo(width, 151);
                @include vw-convert-mo(height, 47);
                @include vw-convert-mo(margin-top, 82);

                span {
                    @include em-font-size-mo(15);
                    @include vw-convert-mo(border-radius, 60);
                }

                &:before {
                    @include vw-convert-mo(border-radius, 60);
                    @include vw-convert-mo(top, 3);
                    @include vw-convert-mo(left, 3);
                }

                &.vr_text {
                    @include vw-convert-mo(margin-top, 42);
                }
            }

            &__fancy {
                .deco1 {
                    width: 13.3%;
                    left: 34.72%;
                    top: 40.5%;
                }

                .deco2 {
                    width: 6.66%;
                    left: 26.38%;
                    top: 51%;
                }

                .deco3 {
                    width: 10%;
                    left: 63.44%;
                    top: 64.21%;
                }

                .deco4 {
                    width: 14.44%;
                    left: 52.69%;
                    top: 80.92%;
                    @include vw-convert-mo(height, 60);
                }

                .deco5 {
                    width: 14.16%;
                    left: 57.22%;
                    top: 43.42%;
                }

                .deco6 {
                    width: 28.33%;
                    left: 27%;
                    top: 63.15%;
                }
            }
        }
        .betree_logo {
            @include vw-convert-mo(width, 120);
            @include vw-convert-mo(height, 120);
        }
    }
}
