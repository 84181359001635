//@mixin font-logo() {
//	font-family: $logo-font;
//	font-weight: 900;
//	font-style: normal;
//}

// Font-Size Convertor
@mixin font-size($pixel) {
	font-size: $pixel*1px;
}
@mixin vw-font-size-pc($pc-pixel) {
	//font-size: $pc-pixel*1px;
	font-size: $pc-pixel*100/1680*1vw;
	@media (min-width:$screen-lg) {
		font-size: $pc-pixel*1px;
	}
}
@mixin vw-font-size-tb($tb-pixel) {
	//font-size: $tb-pixel*1px;
	font-size: $tb-pixel*100/1024*1vw;
}
@mixin vw-font-size-mo($mo-pixel) {
	//font-size: $mo-pixel/360*768*1px;
	font-size: $mo-pixel*100/360*1vw;
	//@media (max-width:360px) {
	//	font-size: $mo-pixel*1px;
	//	font-size: $mo-pixel*100/360*1vw;
	//}
}

// line-height Convertor
@mixin line-height-pc($num,$font-size: 0) {
	line-height: $num;
}
@mixin line-height-tb($num,$font-size: 0) {
	line-height: $num;
}
@mixin line-height-mo($num,$font-size: 0) {
	line-height: $num;
}