@charset "utf-8";

// 공통
.main{
    &__page{
        background-color: #FAF6F3;
        @include font-oscine();
    }

    &_section-title {
        @include font-oscine();
        @include em-font-size-pc(72);
        line-height: 1.3;
        display: flex;
        span{
            opacity: 0;
            transition: 0.3s ease-in-out;
            transform:translateY(50px);
        }
        &.on{
            span{
                opacity: 1;
                transform:translateY(0px);
            }
        }
        @media (max-width: $screen-md) {
            @include em-font-size-tb(46);
        }
        @media (max-width: $screen-sm) {
            @include em-font-size-mo(36);
        }
    }

    &__visual{
        @include vw-convert-pc(padding-top, 30);
        @include vw-convert-pc(padding-bottom, 50);
        @media (max-width: $screen-md){
            @include vw-convert-tb(padding-top, 50);
            @include vw-convert-tb(padding-bottom, 70);
        }
        @media (max-width: $screen-sm){
            @include vw-convert-mo(padding-top, 20);
            @include vw-convert-mo(padding-bottom, 30);
        }
        .main__inner{
            display: flex;
            @media (max-width: $screen-sm){
                display: block;
            }
        }
        .slider{
            width: 100%;
            &_wrap{
                @include vw-convert-pc(max-width, 974);
                width: 57.9%;
            }
            &-item{
                position: relative;
                width: 100%;
                background-color: #fff;
                display: block !important;
                a{
                    display: block;
                    @include vw-convert-pc(padding, 14);
                    color: #000;
                }
                .images{
                    img{
                        width: 100%;
                    }
                }
                .info{
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    @include vw-convert-pc(padding, 50);
                    .year{
                        @include em-font-size-pc(34);
                    }
                    .title{
                        @include em-font-size-pc(58);
                        @include vw-convert-pc(padding, 20 60 55 0);
                        line-height: 1.3;
                    }
                    .cont{
                        width: 42%;
                        font-weight: normal;
                        font-family: $primary-font;
                        line-height: 1.7;
                        @media (max-width: 1780px){
                            width: 40%;
                        }
                    }
                }
            }
            &-item{
                &[data-index="1"] .images{
                    background-color: $kv_color_1;
                }
                &[data-index="2"] .images{
                    background-color: $kv_color_2;
                }
                &[data-index="3"] .images{
                    background-color: $kv_color_3;
                }
                &[data-index="4"] .images{
                    background-color: $kv_color_4;
                }
                &[data-index="5"] .images{
                    background-color: $kv_color_5;
                }
                &[data-index="6"] .images{
                    background-color: $kv_color_6;
                }
                &[data-index="7"] .images{
                    background-color: $kv_color_7;
                }
                &[data-index="8"] .images{
                    background-color: $kv_color_8;
                }
            }
            &-progress{
                width: 100%;
                height: 4px;
                border: solid #555;
                border-width: 0 1px 1px;
                background-color: #fff;
                .bar{
                    display: block;
                    width: 0;
                    height: 100%;
                    background-color: #555;
                    &.on{
                        animation: progress 5s linear;
                    }
                }
                @keyframes progress {
                    100%{
                        width: 100%;
                    }
                }
            }
            .slick-list{
                border: 1px solid #000;
            }
            .slick-arrow{
                position: absolute;
                @include vw-convert-pc(bottom, 50);
                z-index: 1001;
                &.slick-prev{
                    @include vw-convert-pc(left, 50);
                }
                &.slick-next{
                    @include vw-convert-pc(left, 140);
                }
            }
            .slick-dots{
                position: absolute;
                @include vw-convert-pc(right, 50);
                @include vw-convert-pc(top, 50);
                z-index: 1001;
                width: auto;
                display: block;
                li{
                    margin: 0;
                    position: absolute;
                    right: 0;
                    top: 0;
                    display: none;
                    &.slick-active{
                        display: block;
                    }
                }
            }
            @media (max-width: $screen-md){
                &_wrap{
                    @include vw-convert-tb(width, 559);
                }
                &-item{
                    @include vw-convert-tb(padding, 9);
                    .info{
                        @include vw-convert-tb(padding, 28);
                        .year{
                            @include em-font-size-tb(26);
                        }
                        .title{
                            @include em-font-size-tb(42);
                            @include vw-convert-tb(padding, 15 0 25);
                        }
                        .cont{
                            width: 100%;
                        }
                    }
                    .images{
                        @media (min-width: $screen-sm-min){
                            position: relative;
                            width: 100%;
                            height: 0;
                            padding-top: 100%;
                            overflow: hidden;
                            img{
                                position: absolute;
                                left: 0;
                                bottom: 0;
                            }
                        }
                    }
                }
                .slick-arrow{
                    @include vw-convert-tb(bottom, 28);
                    &.slick-prev{
                        @include vw-convert-tb(left, 28);
                    }
                    &.slick-next{
                        @include vw-convert-tb(left, 98);
                    }
                }
                .slick-dots{
                    @include vw-convert-tb(right, 28);
                    @include vw-convert-tb(top, 28);
                }
            }
            @media (max-width: $screen-sm){
                &_wrap{
                    width: 100%;
                    max-width: 100%;
                }
                &-item{
                    @include vw-convert-mo(padding, 9);
                    .images{
                        @include vw-convert-mo(min-height, 620);
                    }
                    .info{
                        @include vw-convert-mo(padding, 25);
                        .year{
                            @include em-font-size-mo(20);
                        }
                        .title{
                            @include em-font-size-mo(32);
                            @include vw-convert-mo(padding, 15 0 25);
                        }
                        .cont{
                            width: 100%;
                        }
                    }
                }
                .slick-arrow{
                    @include vw-convert-mo(bottom, 30);
                    &.slick-prev{
                        @include vw-convert-mo(left, 105);
                    }
                    &.slick-next{
                        @include vw-convert-mo(left, 175);
                    }
                }
                .slick-dots{
                    @include vw-convert-mo(right, 25);
                    @include vw-convert-mo(top, 25);
                }
            }
        }
        .links{
            position: relative;
            padding-top: 117.56%;
            width: 100%;
            height: 0;
            //@media (max-width: 1680px){
            //    padding-top: 111.75%;
            //}
            &_wrap{
                @include vw-convert-pc(max-width, 672);
                width: 42%;
                @include vw-convert-pc(margin-left, 34);
            }
            li{
                position: absolute;
            }
            .item{
                position: relative;
                display: block;
                width: 100%;
                height: 0;
                &:hover{
                    .shadow{
                        .box,
                        &:before,
                        &:after{
                            opacity: 1;
                            transition: left .15s ease, top .15s ease, opacity 0s ease 0s;
                        }
                        &:before{
                            @include vw-convert-pc(left, 7);
                            @include vw-convert-pc(top, 7);
                        }
                        .box{
                            @include vw-convert-pc(left, 14);
                            @include vw-convert-pc(top, 14);
                        }
                        &:after{
                            @include vw-convert-pc(left, 21);
                            @include vw-convert-pc(top, 21);
                        }
                    }
                }
            }
            .text,
            .shadow{
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
            }
            .shadow{
                z-index: 8;
                .box,
                &:before,
                &:after{
                    content: "";
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    @include vw-convert-pc(left, 0);
                    @include vw-convert-pc(top, 0);
                    transition: left .15s ease, top .15s ease, opacity 0s ease .15s;
                    background: no-repeat center/100% auto;
                    opacity: 0;
                }
                &:before{
                    z-index: 5;
                }
                .box{
                    z-index: 4;
                }
                &:after{
                    z-index: 3;
                }
            }
            .text{
                @include em-font-size-pc(42);
                color: #000;
                text-transform: uppercase;
                z-index: 10;
                @include flex-center();
                background: no-repeat center/100% auto;
            }
            .about{
                left: 17.8%;
                top: 3%;
                width: 56.99%;
                .item{
                    padding-top: 32.114%;
                }
                .text{
                    background-image: url(../images/kv_box1_1.svg);
                }
                .shadow{
                    &:before{
                        background-image: url(../images/kv_box1_2.svg);
                    }
                    .box{
                        background-image: url(../images/kv_box1_3.svg);
                    }
                    &:after{
                        background-image: url(../images/kv_box1_4.svg);
                    }
                }
            }
            .strength{
                left: 37.2%;
                top: 28.86%;
                width: 59.52%;
                .item{
                    padding-top: 30%;
                }
                .text{
                    background-image: url(../images/kv_box2_1.svg);
                }
                .shadow{
                    &:before{
                        background-image: url(../images/kv_box2_2.svg);
                    }
                    .box{
                        background-image: url(../images/kv_box2_3.svg);
                    }
                    &:after{
                        background-image: url(../images/kv_box2_4.svg);
                    }
                }
            }
            .solutions{
                left: 17.85%;
                top: 52.15%;
                width: 71.13%;
                .item{
                    padding-top: 33.69%;
                }
                .text{
                    background-image: url(../images/kv_box3_1.svg);
                }
                .shadow{
                    &:before{
                        background-image: url(../images/kv_box3_2.svg);
                    }
                    .box{
                        background-image: url(../images/kv_box3_3.svg);
                    }
                    &:after{
                        background-image: url(../images/kv_box3_4.svg);
                    }
                }
            }
            .clients{
                left: 29.2%;
                top: 79.74%;
                width: 68.45%;
                .item{
                    padding-top: 36.08%;
                }
                .text{
                    background-image: url(../images/kv_box4_1.svg);
                }
                .shadow{
                    &:before{
                        background-image: url(../images/kv_box4_2.svg);
                    }
                    .box{
                        background-image: url(../images/kv_box4_3.svg);
                    }
                    &:after{
                        background-image: url(../images/kv_box4_4.svg);
                    }
                }
            }
            .deco{
                &_item{
                    position: absolute;
                    span{
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 0;
                        background: no-repeat center/100% auto;
                    }
                }
                .deco1{
                    width: 10.4%;
                    left: 83.63%;
                    top: 15.3%;
                    span{
                        padding-top: 104.2%;
                        background-image: url(../images/kv_deco1.svg);
                        animation: deco1 5s linear infinite;
                    }

                    @keyframes deco1 {
                        0%{
                            transform: rotate(0deg);
                        }
                        25%{
                            transform: rotate(65deg);
                        }
                        50%{
                            transform: rotate(0deg);
                        }
                        75%{
                            transform: rotate(-65deg);
                        }
                    }
                }
                .deco2{
                    width: 12.64%;
                    left: 21.27%;
                    top: 25.56%;
                    .deco2_1{
                        stroke-dashoffset: 106;
                        stroke-dasharray: 106;
                        animation: deco2-line 0.9s forwards;
                    }
                    .deco2_2{
                        stroke-dashoffset: 45;
                        stroke-dasharray: 45;
                        animation: deco2-line 0.5s 0.8s forwards;
                    }
                    @keyframes deco2-line{
                        100% {
                            stroke-dashoffset: 0;
                        }
                    }
                }
                .deco3{
                    width: 9.375%;
                    left: 89.56%;
                    top: 69%;
                    span{
                        padding-top: 103.17%;
                        background-image: url(../images/kv_deco3.svg);
                        animation: deco3 2.5s ease-in infinite;
                    }
                    @keyframes deco3 {
                        0%{
                            transform: scale(1);
                        }
                        50%{
                            transform: scale(.2);
                        }
                    }
                }
                .deco4{
                    width: 11.3%;
                    left: 12.79%;
                    top: 77.97%;
                    span{
                        padding-top: 100%;
                        background-image: url(../images/kv_deco4.svg);
                        animation: deco4 5s linear infinite;
                    }
                    @keyframes deco4 {
                        0%{
                            transform: rotate(0deg);
                        }
                        100%{
                            transform: rotate(360deg);
                        }
                    }
                }
            }
            @media (max-width: $screen-md){
                padding-top: 161.73%;
                &_wrap{
                    @include vw-convert-tb(width, 345);
                }
                .text{
                    @include em-font-size-tb(24);
                }
                .about{
                    left: 15.1%;
                    top: 5%;
                    width: 67.53%;
                }
                .strength {
                    left: 29.56%;
                    top: 28.80%;
                    width: 70.43%;
                }
                .solutions{
                    left: 10.72%;
                    top: 51.16%;
                    width: 80.57%;
                }
                .clients{
                    left: 19.71%;
                    top: 79.24%;
                    width: 79.13%;
                }
                .deco{
                    .deco1{
                        width: 10.4%;
                        left: 86.9%;
                        top: 16.45%;
                    }
                    .deco2{
                        width: 15.07%;
                        left: 8.69%;
                        top: 24.5%;
                    }
                    .deco3{
                        width: 11.3%;
                        left: 88.69%;
                        top: 68%;
                    }
                    .deco4{
                        width: 13.33%;
                        left: 9.27%;
                        top: 75.13%;
                    }
                }
            }
            @media (max-width: $screen-sm){
                padding-top: 156.25%;
                &_wrap{
                    width: 100%;
                    max-width: 100%;
                    margin-left: 0;
                }
                .text{
                    @include em-font-size-mo(22);
                }
                .about{
                    left: 5.625%;
                    top: 9.2%;
                    width: 65%;
                }
                .strength {
                    left: 29.375%;
                    top: 30.2%;
                    width: 67.18%;
                }
                .solutions{
                    left: 5.625%;
                    top: 49.6%;
                    width: 77.5%;
                }
                .clients{
                    left: 19.375%;
                    top: 73.4%;
                    width: 77.18%;
                }
                .deco{
                    .deco1{
                        width: 11.875%;
                        left: 80.625%;
                        top: 18.8%;
                    }
                    .deco2{
                        width: 14.375%;
                        left: 9.6875%;
                        top: 27.2%;
                    }
                    .deco3{
                        width: 10.625%;
                        left: 83.125%;
                        top: 64%;
                    }
                    .deco4{
                        width: 12.81%;
                        left: 0;
                        top: 72.8%;
                    }
                }
            }
        }
    }

    &__works {
        border-top: 1px solid #555;
        border-bottom: 1px solid #555;
        //.main_section-title{
        //    @include vw-convert-pc(padding-top, 20);
        //}
        .top {
            @include vw-convert-pc(padding, 35 0);
            border-bottom: 1px solid #555;
            .main__inner{
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            @media (max-width: $screen-md) {
                @include vw-convert-tb(padding, 30 0);
            }
            @media (max-width: $screen-sm) {
                @include vw-convert-mo(padding, 25 0);
            }
        }

        .contents {
            // @include vw-convert-pc(padding-left, 70);
            border-bottom: 1px solid #555;

            .slick-slide {
                border-right: 1px solid #555;
            }

            .item {
                width: 27.2vw !important;
                height: 0;
                padding-top: 123%;
                position: relative;
                @include vw-convert-pc(max-width, 520);
            }

            @media (max-width: $screen-md) {
                @include vw-convert-tb(padding-left, 30);
                .item {
                    width: 35.1vw !important;
                    max-width:initial;
                    // 32.1
                }
            }
            @media (max-width: $screen-sm) {
                padding-left: 0 !important;
                .item {
                    width: 80vw !important;
                    max-width:initial;
                    // 75
                }
            }

            .project {
                position: absolute;
                @include vw-convert-pc(left, 50);
                @include vw-convert-pc(right, 50);
                @include vw-convert-pc(bottom, 50);
                @include vw-convert-pc(top, 50);
                @include vw-convert-pc(padding, 30);
                @include font-oscine();
                border: 1px solid #000;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                background-color: #fff;
                cursor: url(../images/cursor_work-list.svg), auto;

                &-year {
                    @include em-font-size-pc(20);
                    @include vw-convert-pc(padding-bottom, 21);
                }

                &-title {
                    @include em-font-size-pc(28);
                    line-height: 1.5;
                    @include ellipsis(3);
                    max-height: 4.5em;
                }
                &-desc{
                    @include em-font-size-pc(14);
                    @include vw-convert-pc(padding-top, 43);
                    font-weight: normal;
                    line-height: 1.7;
                    font-family: $primary-font;
                    @include ellipsis(5);
                    max-height: 13em;
                }

                &-detail {
                    @include em-font-size-pc(16);
                    display: flex;
                    line-height: 1.7;
                    justify-content: space-between;

                    .technique {
                        width: 76%;
                        @include ellipsis(2);
                        max-height: 3.4em;
                    }
                }

                &:before, &:after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;

                    transition: 0.15s cubic-bezier(.17, .67, .83, .67);
                    border: 1px solid #000;
                    box-sizing: border-box;
                }

                &:before {
                    z-index: -1;
                    @include vw-convert-pc(left, 0);
                    @include vw-convert-pc(top, 0);
                    background-color: #F2B218;
                }

                &:after {
                    z-index: -2;
                    @include vw-convert-pc(left, 0);
                    @include vw-convert-pc(top, 0);
                    background-color: #E57C01;
                }

                &:hover {
                    &:before {
                        @include vw-convert-pc(left, 10);
                        @include vw-convert-pc(top, 10);
                    }

                    &:after {
                        @include vw-convert-pc(left, 20);
                        @include vw-convert-pc(top, 20);
                    }
                }
                
                @media (min-width: $screen-md-min) {
                    &-desc{
                        opacity: 0;
                        transition: 0.3s;
                    }
                    &:hover {
                        .project-desc{
                            opacity: 1;
                        }
                    }
                }
                @media (max-width: $screen-md) {
                    @include vw-convert-tb(left, 30);
                    @include vw-convert-tb(right, 30);
                    @include vw-convert-tb(bottom, 30);
                    @include vw-convert-tb(top, 30);
                    @include vw-convert-tb(padding, 20);
                    &-year {
                        @include em-font-size-tb(18);
                        @include vw-convert-tb(padding-bottom, 20);
                    }
                    &-title {
                        @include em-font-size-tb(26);
                        @include ellipsis(3);
                        max-height: 4.5em;
                    }
                    &-desc{
                        @include ellipsis(3);
                        max-height: 8em;
                    }
                    &-detail {
                        @include em-font-size-tb(16);
                    }
                    &:hover {
                        &:before {
                            @include vw-convert-tb(left, 6);
                            @include vw-convert-tb(top, 6);
                        }

                        &:after {
                            @include vw-convert-tb(left, 12);
                            @include vw-convert-tb(top, 12);
                        }
                    }
                }
                @media (max-width: $screen-sm) {
                    @include vw-convert-mo(left, 20);
                    @include vw-convert-mo(right, 20);
                    @include vw-convert-mo(bottom, 20);
                    @include vw-convert-mo(top, 20);
                    @include vw-convert-mo(padding, 15);
                    &-year {
                        @include em-font-size-mo(16);
                        @include vw-convert-mo(padding-bottom, 15);
                    }
                    &-title {
                        @include em-font-size-mo(22);
                    }
                    &-detail {
                        @include em-font-size-mo(14);
                    }
                    &:hover {
                        &:before {
                            @include vw-convert-mo(left, 4);
                            @include vw-convert-mo(top, 4);
                        }

                        &:after {
                            @include vw-convert-mo(left, 8);
                            @include vw-convert-mo(top, 8);
                        }
                    }
                }

            }
        }
        .flow{
            &-container{
                @include vw-convert-pc(padding, 50 0);
            }
            &-text{
                @include font-oscine();
                color: #9D9993;
                @include em-font-size-pc(45);
                text-transform: uppercase;
                display: flex;
                flex: 0 0 auto;
                white-space: nowrap;
                overflow: hidden;
                transition: 0.3s;
                &:hover{
                    color:#000;
                    .flow-wrap {
                        animation-play-state:paused;
                        cursor: pointer;
                    }
                }
            }
            &-wrap{
                line-height: 1;
                animation: textLoop 10s linear infinite;
                @include vw-convert-pc(padding-right, 25);
            }
            @media (max-width: $screen-md) {
                &-container{
                    @include vw-convert-tb(padding, 35 0);
                }
                &-text{
                    @include em-font-size-tb(35);
                }
                &-wrap {
                    @include vw-convert-tb(padding-right, 20);
                }
            }
            @media (max-width: $screen-sm) {
                &-container{
                    @include vw-convert-mo(padding, 34 0);
                }
                &-text{
                    @include em-font-size-mo(28);
                }
                &-wrap {
                    @include vw-convert-mo(padding-right, 15);
                }
            }
            @keyframes textLoop {
                0% {
                    -webkit-transform: translate3d(0, 0, 0);
                    transform: translate3d(0, 0, 0)
                }
                100% {
                    -webkit-transform: translate3d(-100%, 0, 0);
                    transform: translate3d(-100%, 0, 0)
                }
            }
        }
    }

    &__contact {
        position: relative;
        .main__inner{
            position: relative;
            @include vw-convert-pc(padding-top, 80);
            @include vw-convert-pc(padding-bottom, 180);
        }
        .info {
            @include vw-convert-pc(margin, 120 0 50);
            @include vw-convert-pc(width, 1115);
            border-top: 1px solid #555;
            li {
                line-height: 1;
                @include vw-convert-pc(padding, 30 0);
                display: flex;
                border-bottom: 1px solid #555;
                @include em-font-size-pc(34);
                @include font-oscine();
                span {
                    width: 25%;
                    @include vw-convert-pc(padding-right, 20);
                }
                p {
                    flex: 1;
                }
            }
        }
        .button_wrap {
            text-transform: uppercase;

            &.vertical {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .download {
                    @include vw-convert-pc(margin-bottom, 30);
                }
            }
        }
        @media (max-width: $screen-md) {
            .main__inner{
                @include vw-convert-tb(padding-top, 50);
                @include vw-convert-tb(padding-bottom, 110);
            }
            .info {
                @include vw-convert-tb(margin, 93 0 50);
                @include vw-convert-tb(width, 904);
                li {
                    @include vw-convert-tb(padding, 30 0);
                    @include em-font-size-tb(34);
                    justify-content: space-between;
                    span {
                        width: 31%;
                    }
                }
            }
            .button_wrap {
                &.vertical {
                    .download {
                        @include vw-convert-tb(margin-bottom, 30);
                    }
                }
            }
        }
        @media (max-width: $screen-sm) {
            .main__inner{
                @include vw-convert-mo(padding-top, 30);
                @include vw-convert-mo(padding-bottom, 20);
            }
            .info {
                @include vw-convert-mo(margin, 71 0 50);
                width: 100%;
                li {
                    line-height: 1.3;
                    @include vw-convert-mo(padding, 20 0);
                    @include em-font-size-mo(22);
                }
            }
            .button_wrap {
                &.vertical {
                    .download {
                        @include vw-convert-mo(margin-bottom, 30);
                    }
                }
            }
        }
        .contact_us {
            position: absolute;
            @include vw-convert-pc(right, 121);
            @include vw-convert-pc(bottom, 86);
            @include vw-convert-pc(width, 296);
            @include vw-convert-pc(height, 296);
            border-radius: 50%;
            border: 1px solid #000;

            .text {
                position: absolute;
                background: url('../images/contact_text.svg') no-repeat center;
                background-size: contain;
                //width: 88.17%;
                //height: 88.17%;
                //@include vw-convert-pc(left, 17);
                //@include vw-convert-pc(top, 17);
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                animation: rotate 30s linear infinite;

                &.hover-text {
                    background-image: url('../images/contact_text-hover.svg');
                    opacity: 0;
                }
            }
            .link{
                display: block;
                width:100%; height:100%;
                position:absolute;
                z-index:999;
                border-radius: 50%;
                span {
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    position: relative;
                    display: block;
                    @include vw-convert-pc(width, 198);
                    @include vw-convert-pc(height, 198);
                    font-size: 0;
                    border-radius: 50%;
                    border: 1px solid #000;
    
                    &:after {
                        content: '';
                        display: block;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        position: absolute;
                        background: url(../images/contact_us_arrow.svg) no-repeat;
                        @include vw-convert-pc(width, 89);
                        @include vw-convert-pc(height, 73);
                        background-size: contain;
                    }
                }
            }


            &:hover {
                .text {
                    opacity: 0;

                    &.hover-text {
                        opacity: 1;
                    }
                }
                .link{
                    span{
                        background: linear-gradient(#BDCCFA, #DEC1F9);
                    }
                }
            }

            @media (max-width: $screen-md) {
                @include vw-convert-tb(right, 60);
                @include vw-convert-tb(bottom, 50);
                @include vw-convert-tb(width, 214);
                @include vw-convert-tb(height, 214);
                .link span {
                    @include vw-convert-tb(width, 144);
                    @include vw-convert-tb(height, 144);

                    &:after {
                        @include vw-convert-tb(width, 64);
                        @include vw-convert-tb(height, 53);
                    }
                }
            }
            @media (max-width: $screen-sm) {
                @include vw-convert-mo(right, 20);
                @include vw-convert-mo(bottom, 50);
                @include vw-convert-mo(width, 106);
                @include vw-convert-mo(height, 106);
                .text {
                    background: url('../images/contact_text_mobile.svg') no-repeat center/100% auto;
                    &.hover-text {
                        background-image: url('../images/contact_text-hover_mobile.svg');
                    }
                }
                .link span {
                    @include vw-convert-mo(width, 60);
                    @include vw-convert-mo(height, 60);
                    &:after {
                        @include vw-convert-mo(width, 32);
                        @include vw-convert-mo(height, 27);
                    }
                }
            }
            @keyframes rotate {
                from {
                    -webkit-transform: rotate(0deg);
                    -o-transform: rotate(0deg);
                    transform: rotate(0deg);
                }
                to {
                    -webkit-transform: rotate(360deg);
                    -o-transform: rotate(360deg);
                    transform: rotate(360deg);
                }
            }
        }
    }
}

// Mac OS
.os_win{
    .main{
        &__visual{
            .links{
                .text{
                    @include vw-convert-pc(padding-top, 5);
                    @media (max-width: $screen-md){
                        @include vw-convert-tb(padding-top, 5);
                    }
                    @media (max-width: $screen-sm){
                        @include vw-convert-mo(padding-top, 3);
                    }
                }
            }
        }
        &__works{
            .main_section-title {
                line-height: 1;
                @include vw-convert-pc(padding-top, 10);
                @media (max-width: $screen-md) {
                    @include vw-convert-tb(padding-top, 10);
                }
                @media (max-width: $screen-sm) {
                    @include vw-convert-mo(padding-top, 10);
                }
            }
        }
        &__contact {
            .info {
                li {
                    @include vw-convert-pc(padding, 35 0 25);
                    @media (max-width: $screen-md) {
                        @include vw-convert-tb(padding, 35 0 25);
                    }
                    @media (max-width: $screen-sm) {
                        @include vw-convert-mo(padding, 20 0 15);
                    }
                }
            }
        }
    }
}

// vr hover effect
.effect{
    height: 100%;
    body{
        background-color: transparent;
        overflow:hidden;
        height: 100%;
    }
    &_box{
        //border: 5px solid #000;
        //background-color: #25ADFA;
        width: 100%;
        height: 100%;
        position: relative;
        cursor: pointer;
        transition: background-color .3s ease;
        span{
            position: absolute;
            background-color: #fff;
            transition: .3s ease-out 0s;
        }
        .line1,
        .line3{
            width: 0;
            height: 8px;
        }
        .line2,
        .line4{
            width: 8px;
            height: 0;
        }
        .line1{
            left: 0;
            top: 0;
        }
        .line2{
            right: 0;
            bottom: 0;
        }
        .line3{
            right: 0;
            bottom: 0;
        }
        .line4{
            top: 0;
            left: 0;
        }
        &.shown{
            //border-color: red;
            background-color: rgba(255,255,255,.1);
            .line1,
            .line3{
                width: 100%;
            }
            .line2,
            .line4{
                height: 100%;
            }
            //.line1{
            //    transition-delay: 0.15s;
            //}
            //.line2{
            //    transition-delay: 0.3s;
            //}
            //.line3{
            //    transition-delay: 0.45s;
            //}
            //.line4{
            //    transition-delay: 0.6s;
            //}

        }
    }

    .ani{
        width: 50%;
        height: 50%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}