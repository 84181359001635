@charset "utf-8";

.layer{
    position: fixed;
    left: 0;
    top: 0;

    &_inner{
        position: relative;
        overflow-y: auto;
        max-height: 80vh;
        background-color: #fff;
        width: 100%;
        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
        }
        &::-webkit-scrollbar-track {
            background-color:transparent;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            background-color: rgba(0,0,0,0.1);
        }
    }

    .close{
        position: absolute;
        @include vw-convert-pc(right, 10);
        @include vw-convert-pc(top, 10);
        @include vw-convert-pc(width, 40);
        @include vw-convert-pc(height, 40);
        font-size: 0;
        z-index: 9999;
        background: url(../images/icon_close-layer.svg) no-repeat center/100% auto;
        @media (max-width: $screen-md){
            @include vw-convert-tb(right, 10);
            @include vw-convert-tb(top, 10);
            @include vw-convert-tb(width, 40);
            @include vw-convert-tb(height, 40);
        }
        @media (max-width: $screen-sm){
            @include vw-convert-mo(right, 8);
            @include vw-convert-mo(top, 8);
            @include vw-convert-mo(width, 40);
            @include vw-convert-mo(height, 40);
        }
    }

    .top{

    }

    .content{

    }

    &.is-hidden {
        width: 0;
        height: 0;
        overflow: hidden;
    }

    &.is-open {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        z-index: 99999;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 30px;
    }

    &_confirm{
        .layer_inner{
            @include vw-convert-pc(width, 552);
            // @include vw-convert-pc(height, 311);
            @include vw-convert-pc(padding, 50 20);
            color:#000;
            text-align: center;
            border-radius: 5px;
        }
        .top_icon{
            background: url('../images/icon_layer_checked.svg') no-repeat center/100% auto;
            display: inline-block;
            @include vw-convert-pc(width, 46);
            @include vw-convert-pc(height, 46);
            @include vw-convert-pc(margin-bottom, 20);
        }
        .message{
            @include em-font-size-pc(18);
            line-height: 1.7;
            @include vw-convert-pc(padding-bottom, 40);
        }
        .button.js-confirm{
            @include vw-convert-pc(width, 270);
            @include vw-convert-pc(height, 48);
            @include vw-convert-pc(padding, 20);
            display: inline-flex;
            align-items: center;
            justify-content: center;
            &:after{
                display: none;
            }
        }
        @media (max-width:$screen-md){
            .layer_inner{
                @include vw-convert-tb(width, 444);
                @include vw-convert-tb(padding, 40 20);
            }
            .top_icon{
                @include vw-convert-tb(width, 46);
                @include vw-convert-tb(height, 46);
                @include vw-convert-tb(margin-bottom, 20);
            }
            .message{
                @include em-font-size-tb(16);
                @include vw-convert-tb(padding-bottom, 40);
            }
            .button.js-confirm{
                @include vw-convert-tb(width, 214);
                @include vw-convert-tb(height, 44);
                @include vw-convert-tb(padding, 12);
            }
        }
        @media (max-width:$screen-sm){
            .layer_inner{
                @include vw-convert-mo(width, 444);
                @include vw-convert-mo(padding, 40 20);
            }
            .top_icon{
                @include vw-convert-mo(width, 46);
                @include vw-convert-mo(height, 46);
                @include vw-convert-mo(margin-bottom, 20);
            }
            .message{
                @include em-font-size-mo(14);
                @include vw-convert-mo(padding-bottom, 40);
            }
            .button.js-confirm{
                @include vw-convert-mo(width, 214);
                @include vw-convert-mo(height, 44);
                @include vw-convert-mo(padding, 12);
            }
        }
    }
    &_terms{
        .layer_inner{
            @include vw-convert-pc(width, 834);
            @include vw-convert-pc(border-radius, 5);
            overflow-y: initial; 
            max-height: initial;
        }
        .top{
            @include vw-convert-pc(padding, 30 30 26 30);
            @include em-font-size-pc(18);
        }
        .content{
            @include vw-convert-pc(padding, 0 30);
            @include em-font-size-pc(16);
            max-height:47vh;
            overflow-y: auto;
            &::-webkit-scrollbar {
                width:5px;
            }
            &::-webkit-scrollbar-track {
                background-color:transparent;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 8px;
                background-color: rgba(0,0,0,0.1);
            }
        }
        .button_wrap{
            text-align: center;
            @include vw-convert-pc(padding-bottom, 30);
            @include vw-convert-pc(margin-top, 34);
            .agree_btn{
                font-weight: bold;
                @include vw-convert-pc(width, 311);
                @include vw-convert-pc(padding, 12);
                text-align: center;
                @include em-font-size-pc(16);
                &:after{
                    display: none;
                }
            }
        }
        @media (max-width: $screen-md){
            .layer_inner{
                @include vw-convert-tb(width, 674);
                @include vw-convert-tb(border-radius, 5);
            }
            .top{
                @include vw-convert-tb(padding, 30 30 20 30);
                @include em-font-size-tb(16);
            }
            .content{
                @include vw-convert-tb(padding, 0 30);
                @include em-font-size-tb(14);
                max-height:44vh;
                @include vw-convert-tb(max-height, 351);
            }
            .button_wrap{
                @include vw-convert-tb(margin-top,20);
                .agree_btn{
                    @include vw-convert-tb(width, 252);
                    @include vw-convert-tb(padding, 12);
                    @include em-font-size-tb(14);
                }
            }
        }
        @media (max-width: $screen-sm){
            .layer_inner{
                width:100%;
                @include vw-convert-mo(border-radius, 5);
            }
            .top{
                @include vw-convert-mo(padding, 20);
                @include em-font-size-mo(14);
            
            }
            .content{
                @include vw-convert-mo(padding, 0 20);
                max-height:44vh;
                @include em-font-size-mo(12);
            }
            .button_wrap{
                @include vw-convert-mo(margin, 20 0 20);
                .agree_btn{
                    @include vw-convert-mo(width, 212);
                    @include vw-convert-mo(padding, 12);
                    @include em-font-size-mo(14);
                }
            }
        }
    }
}


// vr layer 팝업
.vr_layer{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    color:#000;
    .layer_inner{
        @include vw-convert-pc(width, 1115);
        overflow: hidden;
        transform: translateY(50px);
        opacity: 0;
    }
    .top{
        background-color: #fff;
        position:absolute;
        width:100%;
        z-index:999;
        @include vw-convert-pc(padding, 0 30);
        border-bottom:1px solid #000;
        @include vw-convert-pc(height, 84);
        display: flex;
        align-items: center;
        p{
            @include em-font-size-pc(34);
            @include font-oscine();
            color: #000;
        }
        .close{
            @include vw-convert-pc(right, 32);
            @include vw-convert-pc(top, 22);
        }
    }
    .contents{
        overflow-y: auto;
        @include vw-convert-pc(padding-top, 84);
        max-height: 80vh;
        &::-webkit-scrollbar {
            width: 5px;
        }
        &::-webkit-scrollbar-track {
            background-color: transparent;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            background-color: rgba(0, 0, 0, 0.1);
        }
    }
    &.is-hidden{
        opacity: 0;
        .layer_inner{
            .close{
                opacity: 0;
            }
        }
    }
    &.is-open{
        opacity: 1;
        .layer_inner{
            //transition: opacity 0.6s ease 0.9s,  transform 0.6s ease 0.9s;
            transition: opacity 0.6s ease,  transform 0.6s ease;
            opacity: 1;
            transform: translateY(0);
            .close{
                opacity: 1;
            }
        }
    }
    .button{
        @include vw-convert-pc(padding, 15 44 15 20);
        @include em-font-size-pc(18);
        &.primary{
            &::after{
                @include vw-convert-pc(width, 16);
                @include vw-convert-pc(height, 18);
            }
        }
        &.website{
            &::after{
                @include vw-convert-pc(width, 20);
                @include vw-convert-pc(height, 20);
            }
        }
    }
    @media (max-width:$screen-md){
        .layer_inner{
            @include vw-convert-tb(width, 904);
        }
        .top{
            @include vw-convert-tb(padding, 0 20);
            @include vw-convert-tb(height, 76);
            p{
                @include em-font-size-tb(28);
            }
            .close{
                @include vw-convert-tb(right, 18);
                @include vw-convert-tb(top, 20);
                @include vw-convert-tb(width, 40);
                @include vw-convert-tb(height, 40);
            }
        }
        .contents{
            overflow-y: auto;
            @include vw-convert-tb(padding-top, 76);
            max-height: 80vh;
        }
        .button{
            @include vw-convert-tb(padding, 12 40 12 18);
            @include em-font-size-tb(14);
            &.primary{
                &::after{
                    @include vw-convert-tb(width, 14);
                    @include vw-convert-tb(height, 11);
                }
            }
            &.website{
                &::after{
                    @include vw-convert-tb(width, 18);
                    @include vw-convert-tb(height, 18);
                }
            }
        }
    }
    @media (max-width: $screen-sm){
        &.is-open{
            padding:0;
        }
        .layer_inner{
            width:100%;
            height:100%;
            max-height: initial;
        }
        .top{
            @include vw-convert-mo(padding, 0 20);
            @include vw-convert-mo(height, 68);
            p{
                @include em-font-size-mo(22);
            }
            .close{
                @include vw-convert-mo(right, 16);
                @include vw-convert-mo(top, 10);
                @include vw-convert-mo(width, 40);
                @include vw-convert-mo(height, 40);
            }
        }
        .contents{
            overflow-y: auto;
            @include vw-convert-mo(padding-top, 66);
            max-height: 100%;
        }
        .button{
            @include vw-convert-mo(padding, 12 40 12 18);
            @include em-font-size-mo(14);
            &.primary{
                &::after{
                    @include vw-convert-mo(width, 14);
                    @include vw-convert-mo(height, 11);
                }
            }
            &.website{
                &::after{
                    @include vw-convert-mo(width, 18);
                    @include vw-convert-mo(height, 18);
                }
            }
        }
    }
}
.os_win .vr_layer{
    .top{
        @include vw-convert-pc(padding-top, 8);
        @media (max-width:$screen-md){
            @include vw-convert-tb(padding-top, 6);
        }
        @media (max-width:$screen-sm){
            @include vw-convert-mo(padding-top, 5);
        }
    }
}


@media (max-width:$screen-sm){
    //모바일에서 개인정보 팝업 열렸을때 헤더 위로 올라오게
    .header_visible{
        header{
            z-index: 100000;
        }
    }
    .inner_privacy{
        @include vw-convert-mo(padding-top, 60);
    }
}
