@charset "utf-8";

#intro{
    width: 100%;
    height: 100vh;
    @include flex-center();
    flex-direction: column;
    .button{
        opacity: 0;
        &.on{
            transition: opacity .3s ease;
            opacity: 1;
        }
    }
}
.intro-player{
    width: 500px;
    height: 550px;
}