@charset "utf-8";

.about{
    &__title{
        @include font-oscine();
        @include vw-convert-pc(padding, 50 0 46);
        @include em-font-size-pc(58);
        line-height: 1;
        @media (max-width: $screen-md){
            @include em-font-size-tb(34);
            @include vw-convert-tb(padding, 40 0 30);
        }
        @media (max-width: $screen-sm){
            @include em-font-size-mo(34);
            @include vw-convert-mo(padding, 30 0);
        }
    }

    &__intro{
        @include vw-convert-pc(padding-top, 80);
        @include vw-convert-pc(padding-bottom, 100);
        .intro{
            position: relative;
            img{
                width: 100%;
            }
        }
        .text{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            flex-direction: column;
            .main,
            .sub{
                > div{
                    display: inline-block;
                }
            }
            .main{
                @include em-font-size-pc(58);
                @include font-oscine();
                > div{
                    @include vw-convert-pc(padding-right, 12);
                }
            }
            .sub{
                @include vw-convert-pc(padding-top, 30);
                @include em-font-size-pc(20);
                > div{
                    @include vw-convert-pc(padding-right, 5);
                }
            }
        }
        &.hidden{
            .intro .text{
                opacity: 0;
            }
        }
        @media (max-width: $screen-md){
            @include vw-convert-tb(padding-top, 50);
            @include vw-convert-tb(padding-bottom, 50);
            .text{
                .main{
                    @include em-font-size-tb(34);
                    > div{
                        display: inline-block;
                        @include vw-convert-tb(padding-right, 12);
                    }
                }
                .sub{
                    @include vw-convert-tb(padding-top, 20);
                    @include em-font-size-tb(16);
                    > div{
                        display: inline-block;
                        @include vw-convert-tb(padding-right, 5);
                    }
                }
            }
        }
        @media (max-width: $screen-sm){
            padding-top: 0;
            @include vw-convert-mo(padding-bottom, 50);
            .intro{
                @include vw-convert-mo(height, 216);
                img{
                    width: auto;
                    height: 100%;
                    position: absolute;
                    left: 50%;
                    top: 0;
                    transform: translateX(-50%);
                }
            }
            .text{
                .main{
                    @include em-font-size-mo(28);
                    text-align: center;
                    > div{
                        padding-right: 0;
                    }
                }
                .sub{
                    @include vw-convert-mo(padding-top, 20);
                    @include em-font-size-mo(12);
                    text-align: center;
                    > div{
                        padding-right: 0;
                    }
                }
            }
        }
    }

    &__info{
        border-bottom: 1px solid #555;
        @include vw-convert-pc(padding-bottom, 100);
        @media (max-width: $screen-md){
            @include vw-convert-tb(padding-bottom, 80);
        }
        @media (max-width: $screen-sm){
            padding-bottom: 0;
        }
        .information{
            display: flex;
            .images{
                border: 1px solid #000;
                @include vw-convert-pc(width, 554);
                @include vw-convert-pc(margin-right, 50);
                img{
                    width: 100%;
                }
            }
            .desc{
                @include vw-convert-pc(width, 665);
                .title{
                    @include font-oscine();
                    @include em-font-size-pc(42);
                }
                .strong{
                    @include em-font-size-pc(20);
                    @include vw-convert-pc(padding, 20 0 40);
                    font-weight: bold;
                }
                .text{
                    @include em-font-size-pc(18);
                }
            }
            @media (max-width: $screen-md){
                .images{
                    @include vw-convert-tb(width, 331);
                    @include vw-convert-tb(margin-right, 20);
                }
                .desc{
                    @include vw-convert-tb(width, 420);
                    .title{
                        @include em-font-size-tb(28);
                    }
                    .strong{
                        @include em-font-size-tb(16);
                        @include vw-convert-tb(padding, 15 0 30);
                    }
                    .text{
                        @include em-font-size-tb(14);
                    }
                }
            }
            @media (max-width: $screen-sm){
                display: block;
                .images{
                    @include vw-convert-mo(width, 242);
                    @include vw-convert-mo(margin-bottom, 20);
                    margin-right: 0;
                }
                .desc{
                    width: 100%;
                    .title{
                        @include em-font-size-mo(26);
                    }
                    .strong{
                        @include em-font-size-mo(14);
                        @include vw-convert-mo(padding, 15 0 20);
                    }
                    .text{
                        @include em-font-size-mo(12);
                    }
                }
            }
        }
        .box{
            @include vw-convert-pc(margin-top, 80);
            border: 1px solid #555;
            &-top{
                width: 100%;
                @include em-font-size-pc(20);
                @include vw-convert-pc(height, 70);
                border-bottom: 1px solid #555;
                display: flex;
                align-items: center;
                .icon{
                    @include vw-convert-pc(width, 70);
                    height: 100%;
                    display: block;
                    border-right: 1px solid #555;
                    position: relative;
                    &:before{
                        content:"";
                        @include vw-convert-pc(width, 48);
                        @include vw-convert-pc(height, 48);
                        @include center();
                        background: no-repeat center/100% auto;
                    }
                }
                .title{
                    @include vw-convert-pc(padding, 0 20);
                }
            }
            @media (max-width: $screen-md){
                @include vw-convert-tb(margin-top, 60);
                &-top{
                    @include em-font-size-tb(16);
                    @include vw-convert-tb(height, 64);
                    .icon{
                        @include vw-convert-tb(width, 64);
                        &:before{
                            @include vw-convert-tb(width, 48);
                            @include vw-convert-tb(height, 48);
                        }
                    }
                    .title{
                        @include vw-convert-tb(padding, 0 20);
                    }
                }
            }
            @media (max-width: $screen-sm){
                @include vw-convert-mo(margin-top, 50);
                @include vw-convert-mo(margin-left, -21);
                @include vw-convert-mo(margin-right, -21);
                border: 0;
                &-top{
                    @include em-font-size-mo(14);
                    @include vw-convert-mo(height, 62);
                    @include vw-convert-mo(padding-right, 20);
                    border-top: 1px solid #555;
                    .icon{
                        @include vw-convert-mo(width, 52);
                        flex: 0 0 auto;
                        &:before{
                            @include vw-convert-mo(width, 48);
                            @include vw-convert-mo(height, 48);
                        }
                    }
                    .title{
                        @include vw-convert-mo(padding, 0 20);
                    }
                }
            }
        }
        .history{
            .box-top{
                .icon:before{
                    background-image: url(../images/about_info_history.svg);
                }
            }
            .graph{
                position: relative;
                overflow: hidden;
                @include vw-convert-pc(height, 260);
                @include vw-convert-pc(padding-left, 90);
                @include vw-convert-pc(padding-top, 80);
                ul{
                    display: flex;
                    position: relative;
                    width: 100%;
                    @include vw-convert-pc(padding-right, 112);
                    &:before{
                        content: "";
                        //width: 100%;
                        width: 0;
                        height: 1px;
                        background-color: #888;
                        position: absolute;
                        left: 0;
                        top: 0;
                        transition: width .8s ease;
                    }
                }
                li{
                    position: relative;
                    opacity: 0;
                    transition: opacity .5s ease;
                    &:nth-child(1){
                        @include vw-convert-pc(width, 192);
                        &:before{
                            background-color: #EB9248;
                            z-index: 2;
                        }
                        &:after{
                            content: "";
                            position: absolute;
                            @include vw-convert-pc(left, -5);
                            @include vw-convert-pc(top, -9);
                            @include vw-convert-pc(width, 16);
                            @include vw-convert-pc(height, 16);
                            border-radius: 50%;
                            border: 1px solid #EB9248;
                            background-color: #fff;
                            z-index: 1;
                        }
                    }
                    &:nth-child(2){
                        @include vw-convert-pc(width, 416);
                        transition-delay: 0.25s;
                    }
                    &:nth-child(3){
                        @include vw-convert-pc(width, 416);
                        transition-delay: 0.5s;
                    }
                    &:nth-child(4){
                        transition-delay: 0.75s;
                    }
                    &:before{
                        content: "";
                        position: absolute;
                        left: 0;
                        @include vw-convert-pc(top, -4);
                        @include vw-convert-pc(width, 8);
                        @include vw-convert-pc(height, 8);
                        background-color: #888;
                        border-radius: 50%;
                    }
                    .date{
                        @include vw-convert-pc(padding, 18 0 5);
                        @include em-font-size-pc(14);
                        font-weight: bold;
                    }
                    .text{
                        @include em-font-size-pc(16);
                    }
                }
                &.on{
                    ul{
                        &:before{
                            width: 100%;
                        }
                    }
                    li{
                        opacity: 1;
                    }
                }
            }
            @media (max-width: $screen-md){
                .graph{
                    @include vw-convert-tb(height, 194);
                    @include vw-convert-tb(padding-left, 80);
                    @include vw-convert-tb(padding-top, 58);
                    ul{
                        @include vw-convert-tb(padding-right, 56);
                    }
                    li{
                        &:nth-child(1){
                            @include vw-convert-tb(width, 144);
                            &:after{
                                @include vw-convert-tb(left, -5);
                                @include vw-convert-tb(top, -9);
                                @include vw-convert-tb(width, 16);
                                @include vw-convert-tb(height, 16);
                            }
                        }
                        &:nth-child(2),
                        &:nth-child(3){
                            @include vw-convert-tb(width, 230);
                        }
                        &:nth-child(4){
                            @include vw-convert-tb(width, 150);
                        }
                        &:before{
                            @include vw-convert-tb(top, -4);
                            @include vw-convert-tb(width, 8);
                            @include vw-convert-tb(height, 8);
                        }
                        .date{
                            @include vw-convert-tb(padding, 18 0 5);
                            @include em-font-size-tb(12);
                        }
                        .text{
                            @include em-font-size-tb(14);
                        }
                    }
                }
            }
            @media (max-width: $screen-sm){
                .graph {
                    height: auto;
                    @include vw-convert-mo(padding-left, 40);
                    @include vw-convert-mo(padding-top, 50);
                    ul{
                        flex-wrap: wrap;
                        padding-right: 0;
                        &:before{
                            transition: width .4s ease;
                        }
                    }
                    li{
                        @include vw-convert-mo(height, 92);
                        &:nth-child(1){
                            width: 42%;
                            &:after{
                                @include vw-convert-mo(left, -5);
                                @include vw-convert-mo(top, -9);
                                @include vw-convert-mo(width, 16);
                                @include vw-convert-mo(height, 16);
                            }
                        }
                        &:nth-child(2){
                            width: 58%;
                        }
                        &:nth-child(3){
                            width: 42%;
                            &:after{
                                content: "";
                                //width: 100vw;
                                width: 0;
                                position: absolute;
                                top: 0;
                                @include vw-convert-mo(left, -40);
                                background-color: #888;
                                height: 1px;
                                transition: width .4s ease .4s;
                            }
                        }
                        &:nth-child(4){
                            width: 58%;
                        }
                        &:before{
                            @include vw-convert-mo(top, -4);
                            @include vw-convert-mo(width, 8);
                            @include vw-convert-mo(height, 8);
                        }
                        .date{
                            @include vw-convert-mo(padding, 18 0 5);
                            @include em-font-size-mo(12);
                        }
                        .text{
                            @include em-font-size-mo(14);
                        }
                    }
                    &.on{
                        li{
                            &:nth-child(3){
                                &:after{
                                    width: 100vw;
                                }
                            }
                        }
                    }
                }
            }
        }
        .patent{
            .box-top{
                .icon:before{
                    background-image: url(../images/about_info_patent.svg);
                }
            }
            .content{
                display: flex;
                align-items: center;
                justify-content: center;
                @include vw-convert-pc(padding, 70 0 90);
                .item{
                    @include vw-convert-pc(width, 270);
                    @include vw-convert-pc(height, 200);
                    @include vw-convert-pc(margin, 0 24);
                    position: relative;
                    //border: 1px solid #555;
                    //background-color: #fff;
                    transform: translateY(30px);
                    opacity: 0;
                    transition: transform .5s ease, opacity .3s ease;
                    &:before{
                        content: "";
                        z-index: -1;
                        background-color: #fff;
                        border: 1px solid #555;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                    &:after{
                        content: "";
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        @include vw-convert-pc(left, 6);
                        @include vw-convert-pc(top, 6);
                        z-index: -2;
                        border: 1px solid #555;
                        background-color: #aaa;
                    }
                    .number{
                        @include vw-convert-pc(padding, 0 20);
                        @include vw-convert-pc(height, 37);
                        @include em-font-size-pc(12);
                        border-bottom: 1px solid #555;
                        display: flex;
                        align-items: center;
                    }
                    .name{
                        @include vw-convert-pc(padding, 20);
                        @include vw-convert-pc(min-height, 124);
                    }
                    .date{
                        color: #888;
                        @include em-font-size-pc(12);
                        @include vw-convert-pc(padding, 0 20 20);
                    }
                    &:nth-child(2){
                        transition-delay: .2s;
                    }
                    &:nth-child(3){
                        transition-delay: .4s;
                    }
                    &:nth-child(4){
                        transition-delay: .6s;
                    }
                    &.on{
                        transform: translateY(0);
                        opacity: 1;
                    }
                }
            }
            @media (max-width: $screen-md){
                .content{
                    @include vw-convert-tb(padding, 50 0 66);
                    .item{
                        @include vw-convert-tb(width, 180);
                        @include vw-convert-tb(height, 145);
                        @include vw-convert-tb(margin, 0 16);
                        &:after{
                            @include vw-convert-tb(left, 6);
                            @include vw-convert-tb(top, 6);
                        }
                        .number{
                            @include vw-convert-tb(padding, 0 10);
                            @include vw-convert-tb(height, 32);
                            @include em-font-size-tb(12);
                        }
                        .name{
                            @include vw-convert-tb(padding, 10 10 0);
                            @include vw-convert-tb(min-height, 85);
                            @include em-font-size-tb(14);
                        }
                        .date{
                            @include em-font-size-tb(12);
                            @include vw-convert-tb(padding, 0 10 10);
                        }
                    }
                }
            }
            @media (max-width: $screen-sm){
                .content{
                    display: block;
                    @include vw-convert-mo(padding, 30 30 14);
                    .item{
                        width: 100%;
                        @include vw-convert-mo(height, 145);
                        @include vw-convert-mo(margin, 0 0 36);
                        &:after{
                            @include vw-convert-mo(left, 6);
                            @include vw-convert-mo(top, 6);
                        }
                        .number{
                            @include vw-convert-mo(padding, 0 10);
                            @include vw-convert-mo(height, 32);
                            @include em-font-size-mo(12);
                        }
                        .name{
                            @include vw-convert-mo(padding, 10 10 0);
                            @include vw-convert-mo(min-height, 82);
                            @include em-font-size-mo(14);
                        }
                        .date{
                            @include em-font-size-mo(12);
                            @include vw-convert-mo(padding, 0 10 10);
                        }
                        &:nth-child(2),
                        &:nth-child(3){
                            transition-delay: 0s;
                        }
                    }
                }
            }
        }
        &.hidden{
            .fancy__wrap{
                opacity: 0;
            }
        }
    }

    &__strength{
        border-bottom: 1px solid #555;
        @include vw-convert-pc(padding-bottom, 100);
        .info{
            @include vw-convert-pc(padding-bottom, 60);
        }
        @media (max-width: $screen-md){
            @include vw-convert-tb(padding-bottom, 80);
            .info{
                @include vw-convert-tb(padding-bottom, 50);
            }
        }
        @media (max-width: $screen-sm){
            padding-bottom: 0;
            border-bottom: 0;
            .info{
                @include vw-convert-mo(padding-bottom, 50);
            }
        }
        &-detail{
            .item{
                border-top: 1px solid #555;
                .title{
                    position: relative;
                    width: 100%;
                    @include vw-convert-pc(height, 96);
                    display: flex;
                    align-items: center;
                    @include font-oscine();
                    @include em-font-size-pc(28);
                    color: #000;
                    &:before,
                    &:after{
                        content: "";
                        background-color: #555;
                        @include vw-convert-pc(width, 30);
                        height: 1px;
                        transform-origin: 50% 50%;
                        position: absolute;
                        top: 50%;
                        right: 0;
                    }
                    &:after{
                        transition: transform .2s ease;
                        transform: rotate(90deg);
                    }
                }
                .point{
                    display: inline-flex;
                    > span{
                        position: relative;
                        z-index: 1;
                        @include vw-convert-pc(min-width, 8);
                    }
                    .line{
                        content: "";
                        //width: 100%;
                        width: 0;
                        @include vw-convert-pc(height, 10);
                        position: absolute;
                        left: 0;
                        top: 55%;
                        z-index: -1;
                        transition: width .03s ease;
                    }
                }
                &.is-hidden{
                    .content{
                        width: 0;
                        height: 0;
                        overflow: hidden;
                    }
                }
                &.is-active{
                    .title{
                        &:after{
                            transform: rotate(0);
                        }
                    }
                    .content{
                        position: relative;
                        border: solid #555;
                        border-width: 1px 1px 0;
                        @include em-font-size-pc(20);
                        @include vw-convert-pc(padding, 30);
                        &:before{
                            content: "";
                            width: 100%;
                            height: 100%;
                            background-color: #f8f8f8;
                            position: absolute;
                            left: 0;
                            top: 0;
                            z-index: -1;
                        }
                    }
                    .point{
                        .line{
                            width: 100%;
                            transition-duration: 0s;
                        }
                    }
                }
                @media (max-width: $screen-md){
                    .title{
                        @include vw-convert-tb(height, 72);
                        @include em-font-size-tb(24);
                        &:before,
                        &:after{
                            @include vw-convert-tb(width, 26);
                        }
                    }
                    .point{
                        > span{
                            @include vw-convert-tb(min-width, 4);
                        }
                        .line{
                            @include vw-convert-tb(height, 6);
                        }
                    }
                    &.is-active{
                        .content{
                            @include em-font-size-tb(16);
                            @include vw-convert-tb(padding, 20);
                        }
                    }
                }
                @media (max-width: $screen-sm){
                    @include vw-convert-mo(margin-left, -20);
                    @include vw-convert-mo(margin-right, -20);
                    .title{
                        @include vw-convert-mo(padding, 0 20);
                        @include vw-convert-mo(height, 58);
                        @include em-font-size-mo(20);
                        &:before,
                        &:after{
                            @include vw-convert-mo(width, 20);
                            @include vw-convert-mo(right, 20);
                        }
                    }
                    .point{
                        > span{
                            @include vw-convert-mo(min-width, 3);
                        }
                        .line{
                            @include vw-convert-mo(height, 6);
                        }
                    }
                    &.is-active{
                        .content{
                            @include em-font-size-mo(14);
                            @include vw-convert-mo(padding, 20);
                            border-left: 0;
                            border-right: 0;
                        }
                    }
                }
            }
            //.maintenance .point .line{
            //    background-color: #B9E2DA;
            //}
            //.website .point .line{
            //    background-color: #E9F29E;
            //}
            //.consult .point .line{
            //    background-color: #FACE87;
            //}
            //.design{
            //    border-bottom: 1px solid #555;
            //    .point .line{
            //        background-color: #C9B5E3;
            //    }
            //}
            .item:nth-child(1) .point .line{
                background-color: #B9E2DA;
            }
            .item:nth-child(2) .point .line{
                background-color: #E9F29E;
            }
            .item:nth-child(3) .point .line{
                background-color: #FACE87;
            }
            .item:nth-child(4){
                border-bottom: 1px solid #555;
                .point .line{
                    background-color: #C9B5E3;
                }
            }
        }
    }

    &__solutions{
        border-bottom: 1px solid #555;
        @include vw-convert-pc(padding-bottom, 100);
        .sub_title{
            @include vw-convert-pc(padding-bottom, 10);
        }
        @media (max-width: $screen-md){
            @include vw-convert-tb(padding-bottom, 80);
            .sub_title{
                padding-bottom: 0;
            }
        }
        @media (max-width: $screen-sm){
            @include vw-convert-mo(padding-bottom, 50);
        }
        &-detail{
            .item{
                @include vw-convert-pc(margin-top, 50);
                @include vw-convert-pc(width, 975);
                border: 1px solid #555;
                display: flex;
                opacity: 0;
                transform: translateX(30%);
                transition: transform .5s ease, opacity .3s ease;
                &:nth-child(2n){
                    margin-left: auto;
                    transform: translateX(-30%);
                }
                .images{
                    @include vw-convert-pc(padding, 10);
                    @include vw-convert-pc(width, 330);
                    flex: 0 0 auto;
                    img{
                        width: 100%;
                    }
                }
                .content{
                    border-left: 1px solid #555;
                    .title{
                        @include vw-convert-pc(padding, 0 25);
                        @include vw-convert-pc(height, 84);
                        display: flex;
                        align-items: center;
                        border-bottom: 1px solid #555;
                        @include font-oscine();
                        @include em-font-size-pc(34);
                    }
                    .desc{
                        @include vw-convert-pc(padding, 20 25);
                        @include em-font-size-pc(16);
                    }
                }
                &.on{
                    transform: translateX(0);
                    opacity: 1;
                    &:nth-child(2n){
                        transform: translateX(0);
                    }
                }
                @media (max-width: $screen-md){
                    @include vw-convert-tb(margin-top, 50);
                    @include vw-convert-tb(width, 674);
                    &:first-child{
                        @include vw-convert-tb(margin-top, 26);
                    }
                    .images{
                        @include vw-convert-tb(padding, 8);
                        @include vw-convert-tb(width, 236);
                    }
                    .content{
                        .title{
                            @include vw-convert-tb(padding, 0 20);
                            @include vw-convert-tb(height, 55);
                            @include em-font-size-tb(24);
                        }
                        .desc{
                            @include vw-convert-tb(padding, 20 20);
                            @include em-font-size-tb(14);
                        }
                    }
                }
                @media (max-width: $screen-sm){
                    @include vw-convert-mo(margin-top, 30);
                    @include vw-convert-mo(width, 266);
                    display: block;
                    transform: translateY(50px);
                    &:first-child{
                        @include vw-convert-mo(margin-top, 18);
                    }
                    &:nth-child(2n){
                        transform: translateY(50px);
                    }
                    .images{
                        @include vw-convert-mo(padding, 8);
                        width: 100%;
                    }
                    .content{
                        border-left: 0;
                        border-top: 1px solid #555;
                        .title{
                            @include vw-convert-mo(padding, 8 15);
                            height: auto;
                            @include em-font-size-mo(20);
                        }
                        .desc{
                            @include vw-convert-mo(padding, 15);
                            @include em-font-size-mo(14);
                        }
                    }
                    &.on{
                        transform: translateY(0);
                        &:nth-child(2n){
                            transform: translateY(0);
                        }
                    }
                }
            }
        }
    }

    &__clients{
        @include vw-convert-pc(padding-bottom, 80);
        .sub_title{
            @include vw-convert-pc(padding-bottom, 60);
        }
        @media (max-width: $screen-md){
            @include vw-convert-tb(padding-bottom, 50);
            .sub_title{
                @include vw-convert-tb(padding-bottom, 50);
            }
        }
        @media (max-width: $screen-sm){
            @include vw-convert-mo(padding-bottom, 50);
            .sub_title{
                @include vw-convert-mo(padding-bottom, 30);
            }
        }
        &-detail{
            border: 1px solid #555;
            @include vw-convert-pc(padding, 50 70 0);
            .slider-item{
                display: flex!important;
                flex-wrap: wrap;
            }
            .item{
                @include vw-convert-pc(margin, 0 70 50);
                @include vw-convert-pc(width, 163);
                opacity: 0;
                transition: opacity 0.9s ease-out;
                img{
                    width: 100%;
                }
            }
            &.on{
                .item{
                    opacity: 1;
                }
            }
            @media (max-width: $screen-md){
                @include vw-convert-tb(padding, 30 60 0);
                .item{
                    @include vw-convert-tb(margin, 0 30 30);
                    @include vw-convert-tb(width, 120);
                }
            }
            @media (max-width: $screen-sm){
                @include vw-convert-mo(padding, 30 0 0);
                @include vw-convert-mo(margin-bottom, 30);
                .item{
                    @include vw-convert-mo(margin, 0 15 30);
                    @include vw-convert-mo(width, 120);
                }
                .slick-dots{
                    position: absolute;
                    left: 0;
                    top: calc(100% + 5.5556vw);
                }
            }
        }
    }

    &__brief{
        @include vw-convert-pc(padding-bottom, 80);
        .brief{
            position: relative;
            img{
                width: 100%;
                display: block;
            }
            .mobile{
                display: none;
            }
            .text{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                color: #fff;
                .title{
                    @include font-oscine();
                    @include em-font-size-pc(42);
                    line-height: 1;
                    @include vw-convert-pc(padding-bottom, 50);
                }
            }
        }
        @media (max-width: $screen-md){
            @include vw-convert-tb(padding-bottom, 50);
            .brief{
                .text{
                    .title{
                        @include em-font-size-tb(28);
                        @include vw-convert-tb(padding-bottom, 30);
                    }
                }
                @media (min-width: 769px){
                    .download{
                        @include em-font-size-tb(14);
                        @include vw-convert-tb(padding, 11 36 11 18);
                        &:after{
                            @include vw-convert-tb(right, 14);
                            @include vw-convert-tb(width, 14);
                            @include vw-convert-tb(height, 11);
                        }
                    }
                }
            }
        }
        @media (max-width: $screen-sm){
            padding-bottom: 0;
            .main__inner{
                padding: 0;
            }
            .brief{
                width: 100%;
                //@include vw-convert-mo(height, 180);
                .pc{
                    display: none;
                }
                .mobile{
                    display: block;
                }
                .text{
                    .title{
                        @include em-font-size-mo(24);
                        @include vw-convert-mo(padding-bottom, 30);
                    }
                }
            }
        }
    }
}

.vr_layer .about{
    &__nav{
        @include vw-convert-pc(padding, 10 30 30);
        display: flex;
        button{
            position: relative;
            @include em-font-size-pc(18);
            @include font-oscine();
            display: flex;
            align-items: center;
            &:hover{
                text-decoration: underline;
            }
            &:before,
            &:after{
                content: "";
                @include vw-convert-pc(width, 16);
                @include vw-convert-pc(height, 12);
                @include vw-convert-pc(margin-right, 8);
                background: url(../images/arrow_page_move.svg) no-repeat center/100% auto;
                display: block;
            }
            &:after{
                transform: scaleX(-1);
                margin-right: 0;
                @include vw-convert-pc(margin-left, 8);
            }
            &.prev{
                &:after{
                    display: none;
                }
            }
            &.next{
                margin-left: auto;
                &:before{
                    display: none;
                }
            }
        }
        @media (max-width: $screen-md){
            @include vw-convert-tb(padding, 10 30 30);
            button{
                @include em-font-size-tb(18);
                &:before,
                &:after{
                    @include vw-convert-tb(width, 16);
                    @include vw-convert-tb(height, 12);
                    @include vw-convert-tb(margin-right, 8);
                }
                &:after{
                    margin-right: 0;
                    @include vw-convert-tb(margin-left, 8);
                }
            }
        }
        @media (max-width: $screen-sm){
            @include vw-convert-mo(padding, 45 20);
            button{
                @include em-font-size-mo(18);
                &:before,
                &:after{
                    @include vw-convert-mo(width, 16);
                    @include vw-convert-mo(height, 12);
                    @include vw-convert-mo(margin-right, 8);
                }
                &:after{
                    margin-right: 0;
                    @include vw-convert-mo(margin-left, 8);
                }
            }
        }
    }

    &__info{
        padding-bottom: 0;
        .information{
            @include vw-convert-pc(padding, 50 30);
        }
        .box{
            margin-top: 0;
            border: 0;
            border-top: 1px solid #555;
        }
        @media (max-width: $screen-md){
            .information{
                @include vw-convert-tb(padding, 40 20);
            }
        }
        @media (max-width: $screen-sm){
            .information{
                @include vw-convert-mo(padding, 30 20);
            }
            .box{
                margin: 0;
                border: 0;
            }
            .history{
                @include vw-convert-mo(padding-bottom, 60);
            }
        }
    }

    &__strength{
        border: 0;
        @include vw-convert-pc(padding, 0 30);
        .info{
            @include vw-convert-pc(padding-bottom, 50);
        }
        @media (max-width: $screen-md) {
            @include vw-convert-tb(padding, 0 20);
        }
        @media (max-width: $screen-sm) {
            @include vw-convert-mo(padding, 0 20);
            .info{
                @include vw-convert-mo(padding-bottom, 50);
            }
        }
        &-detail {
            @include vw-convert-pc(padding, 0 20);
            //display: flex;
            //justify-content: space-between;
            &:after{
                content: "";
                display: block;
                clear: both;
            }
            .item {
                position: relative;
                border: 1px solid #000;
                @include vw-convert-pc(width, 468);
                @include vw-convert-pc(padding, 0 10 10);
                @include vw-convert-pc(margin-bottom, 80);
                float: left;
                &:nth-child(odd){
                    @include vw-convert-pc(margin-right, 67);
                }
                &:before{
                    content: "";
                    position: absolute;
                    @include vw-convert-pc(left, 6);
                    @include vw-convert-pc(top, 6);
                    z-index: -1;
                    width: 100%;
                    height: 100%;
                    border: 1px solid #000;
                }
                .title {
                    @include vw-convert-pc(height, 60);
                    @include em-font-size-pc(28);
                    display: flex;
                    align-items: center;
                    &:after {
                        display: none;
                    }
                    &:before{
                        @include vw-convert-pc(width, 10);
                        @include vw-convert-pc(height, 10);
                        @include vw-convert-pc(margin-right, 8);
                        position: static;
                        border-radius: 50%;
                        border: 1px solid #555;
                    }
                }
                .content{
                    position: relative;
                    border: 1px solid #000;
                    letter-spacing: -0.02em;
                    @include vw-convert-pc(padding, 20 20 50);
                    background-color: #fff;
                }
                .point{
                    .line{
                        width: 100%;
                        transition-duration: 0s;
                        opacity: 1;
                    }
                }
            }
            .item:nth-child(1){
                background-color: #B9E2DA;
                &:before{
                    background-color: #63C6C6;
                }
                .title:before{
                    background-color: #63C6C6;
                }
            }
            .item:nth-child(2){
                @include vw-convert-pc(margin-top, 30);
                background-color: #E9F29E;
                &:before{
                    background-color: #93D040;
                }
                .title:before{
                    background-color: #93D040;
                }
            }
            .item:nth-child(3){
                @include vw-convert-pc(margin-top, -215);
                background-color: #FACE87;
                &:before{
                    background-color: #EB9248;
                }
                .title:before{
                    background-color: #EB9248;
                }
            }
            .item:nth-child(4){
                background-color: #C9B5E3;
                &:before{
                    background-color: #876CDE;
                }
                .title:before{
                    background-color: #876CDE;
                }
            }
            //.maintenance{
            //    background-color: #B9E2DA;
            //    &:before{
            //        background-color: #63C6C6;
            //    }
            //    .title:before{
            //        background-color: #63C6C6;
            //    }
            //}
            //.website{
            //    @include vw-convert-pc(margin-top, 30);
            //    background-color: #E9F29E;
            //    &:before{
            //        background-color: #93D040;
            //    }
            //    .title:before{
            //        background-color: #93D040;
            //    }
            //}
            //.consult{
            //    @include vw-convert-pc(margin-top, -215);
            //    background-color: #FACE87;
            //    &:before{
            //        background-color: #EB9248;
            //    }
            //    .title:before{
            //        background-color: #EB9248;
            //    }
            //}
            //.design{
            //    background-color: #C9B5E3;
            //    &:before{
            //        background-color: #876CDE;
            //    }
            //    .title:before{
            //        background-color: #876CDE;
            //    }
            //}
            @media (max-width: $screen-md) {
                @include vw-convert-tb(padding, 0 20);
                .item{
                    @include vw-convert-tb(width, 390);
                    @include vw-convert-tb(padding, 0 10 10);
                    @include vw-convert-tb(margin-bottom, 56);
                    &:nth-child(odd){
                        @include vw-convert-tb(margin-right, 37);
                    }
                    &:before {
                        @include vw-convert-tb(left, 6);
                        @include vw-convert-tb(top, 6);
                    }
                    .title {
                        @include vw-convert-tb(height, 55);
                        &:before{
                            @include vw-convert-tb(width, 10);
                            @include vw-convert-tb(height, 10);
                            @include vw-convert-tb(margin-right, 8);
                        }
                    }
                    .content{
                        @include vw-convert-tb(padding, 20 20 50);
                    }
                }
                .item:nth-child(2){
                    @include vw-convert-tb(margin-top, 30);
                }
                .item:nth-child(3){
                    @include vw-convert-tb(margin-top, -210);
                }
                //.website{
                //    @include vw-convert-tb(margin-top, 30);
                //}
                //.consult{
                //    @include vw-convert-tb(margin-top, -210);
                //}
            }
            @media (max-width: $screen-sm) {
                display: block;
                padding: 0;
                .item{
                    width: 100%;
                    margin: 0;
                    @include vw-convert-mo(padding, 0 10 10);
                    @include vw-convert-mo(margin-bottom, 36);
                    &:before {
                        @include vw-convert-mo(left, 6);
                        @include vw-convert-mo(top, 6);
                    }
                    &:last-child{
                        margin-bottom: 0;
                    }
                    .title {
                        @include vw-convert-mo(height, 50);
                        padding: 0;
                        &:before{
                            @include vw-convert-mo(width, 8);
                            @include vw-convert-mo(height, 8);
                            @include vw-convert-mo(margin-right, 8);
                        }
                    }
                }
                //.website,
                //.consult{
                //    margin-top: 0;
                //}
                .item:nth-child(2),
                .item:nth-child(3){
                    margin-top: 0;
                }
            }
        }
    }

    &__solutions{
        @include vw-convert-pc(padding, 0 30);
        border-bottom: 0;
        @media (max-width: $screen-sm){
            @include vw-convert-mo(padding, 0 20);
        }
        &-detail{
            @include vw-convert-pc(padding, 0 20 50);
            @media (max-width: $screen-sm){
                padding: 0;
            }
            .item{
                @include vw-convert-pc(width, 783);
                transform: translateX(50px);
                &:nth-child(2n){
                    transform: translateX(-50px);
                }
                .images {
                    @include vw-convert-pc(width, 291);
                }
                &.on{
                    transform: translateX(0);
                    &:nth-child(2n){
                        transform: translateX(0);
                    }
                }
                @media (max-width: $screen-md){
                    @include vw-convert-tb(width, 634);
                    .images {
                        @include vw-convert-tb(width, 240);
                    }
                }
                @media (max-width: $screen-sm){
                    transform: translateY(50px);
                    &:nth-child(2n){
                        transform: translateY(50px);
                    }
                    @include vw-convert-mo(width, 266);
                    .images {
                        width: 100%;
                    }
                }
            }
        }
    }

    &__clients{
        @include vw-convert-pc(padding, 0 30 30);
        .sub_title{
            @include vw-convert-pc(padding-bottom, 30);
        }
        &-detail{
            .item{
                @include vw-convert-pc(margin, 0 30 50);
            }
        }
        @media (max-width: $screen-md){
            @include vw-convert-tb(padding, 0 20 30);
            &-detail{
                .item{
                    @include vw-convert-tb(margin, 0 30 50);
                }
            }
        }
        @media (max-width: $screen-sm){
            @include vw-convert-mo(padding, 0 20);
            &-detail{
                .item{
                    @include vw-convert-mo(margin, 0 15 30);
                }
            }
        }
    }
}

.os_win .about{
    &__solutions{
        .item{
            .content{
                .title{
                    @include vw-convert-pc(padding-top, 5);
                    @media (max-width: $screen-sm){
                        @include vw-convert-mo(padding, 8 15 6);
                    }
                }
            }
        }
    }
    &__brief{
        @media (max-width: $screen-md){
            .brief{
                @media (min-width: 769px){
                    .download{
                        @include vw-convert-tb(padding, 13 36 10 18);
                    }
                }
            }
        }
    }
    &__strength{
        &-detail {
            .item{
                .text{
                    @include vw-convert-pc(padding-top, 3);
                    @media (max-width: $screen-md){
                        @include vw-convert-tb(padding-top, 3);
                    }
                    @media (max-width: $screen-sm){
                        @include vw-convert-mo(padding-top, 3);
                    }
                }
            }
        }
    }
}

html[lang=en]{
    .vr_layer .about{
        &__info{
            .patent .content .item{
                @include vw-convert-pc(height, 230);
                @media (max-width: $screen-md){
                    @include vw-convert-tb(height, 145);
                }
                @media (max-width: $screen-sm){
                    @include vw-convert-mo(height, 145);
                }
            }
        }
    }
    .about__info{
        .patent .content .item{
            height: auto;
            .name {
                @include vw-convert-pc(min-height, 184);
                @media (max-width: $screen-md){
                    @include vw-convert-tb(min-height, 120);
                    @include em-font-size-tb(13);
                }
                @media (max-width: $screen-sm){
                    @include vw-convert-mo(padding, 10);
                    @include em-font-size-mo(14);
                }
            }
        }
    }
}